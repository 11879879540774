export const palette = {
  error: {
    main: '#e53e3e',
  },
  border: {
    main: '#a0aec0',
  },
  white: {
    main: '#ffffff',
  },
  black400: {
    main: '#343A40',
    background:
      'linear-gradient(95.89deg, rgba(0, 0, 0, 1) -13.16%, rgba(0, 0, 0, 0.2) 115.4%)',
    second: 'rgba(54, 55, 64, 1)',
  },
  black500: {
    main: '#363740',
    second: 'rgba(79, 79, 79, 1)',
  },
  text: {
    primary: '#000000',
    secondary: '#ffffff',
  },
  inputLabel: {
    main: '#343a40',
  },
  green700: {
    main: 'linear-gradient(223.48deg, #009193 37.28%, #D4F9F2 96.94%)',
    secondary:
      'linear-gradient(95.89deg, rgba(71, 172, 152, 0.6) -13.16%, rgba(136, 218, 204, 0.6) 115.4%)',
  },
  green650: {
    main: 'linear-gradient(180deg, rgba(208, 243, 230, 0.5) 0%, rgba(255, 255, 255, 0.5) 99.64%)',
  },
  green600: {
    main: 'linear-gradient(108.32deg, #47AC98 37.93%, #D4F9F2 120.76%)',
  },
  green500: {
    main: '#009193',
  },
  green450: {
    main: '#88DACC',
  },
  green400: {
    main: 'linear-gradient(180deg, #009193 0%, #40B3AE 57.92%, #88DACC 100%)',
    second: 'rgba(0, 145, 147, 1)',
    background: 'rgba(212, 249, 242, 0.5)',
  },
  green300: {
    main: 'linear-gradient(73.2deg, #009193 23.19%, #D4F9F2 118.63%)',
    background: 'rgba(212, 249, 242, 1)',
  },
  green200: {
    main: 'linear-gradient(180deg, #88DACC 0%, rgba(212, 249, 242, 0.5) 100%)',
  },
  green150: {
    main: '#88DACC',
  },
  green125: {
    main: '#28C76F',
  },
  green110: {
    main: 'rgba(212, 249, 242, 0.5)',
  },
  green100: {
    main: 'rgba(212, 249, 242, 0.2)',
  },
  grey600: {
    main: '#939FB5',
  },
  grey500: {
    main: '#788CA5',
    second: '#676767',
  },
  grey400: {
    main: '#4F4F4F',
    background:
      'linear-gradient(95.89deg, rgba(0, 0, 0, 0.2) -13.16%, rgba(160, 174, 192, 0.2) 115.4%)',
    second: 'rgba(160, 174, 192, 1)',
  },
  grey300: {
    main: 'rgba(120, 140, 165, 0.5)',
    second: 'rgba(120, 140, 165, 0.06)',
  },
  grey200: {
    main: 'rgba(120, 140, 165, 0.1)',
    second: 'rgba(160, 174, 192, 0.7)',
  },
  grey150: {
    main: 'rgba(120, 140, 165, 0.2)',
  },
  grey100: {
    main: 'rgba(196, 196, 196, 0.1)',
  },
  background: {
    main: '#F8F9FA',
  },
  shadow: {
    main: 'rgba(112, 144, 176, 0.12)',
  },
  shadow100: {
    main: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
  },
  red400: {
    main: '#FF0000',
  },
};
