export const button = {
  MuiButton: {
    defaultProps: {
      size: 'large',
    },
    styleOverrides: {
      root: {},
    },
    variants: [
      {
        props: {
          variant: 'contained',
        },
        style: {
          width: '100%',
          maxWidth: '27.625rem',
          color: '#ffffff',
          borderRadius: '0.625rem',
          padding: '0.75rem 0',
          height: '3rem',
          background: '#009193',
          border: 'none',
          '&:hover': {
            backgroundColor: '#009193',
            opacity: 0.9,
          },
        },
      },
      {
        props: {
          variant: 'contained',
          color: 'secondary',
        },
        style: {
          width: '100%',
          maxWidth: '27.625rem',
          color: '#009193',
          borderRadius: '0.625rem',
          padding: '0.75rem 0',
          height: '3rem',
          background: '#ffffff',
          border: '0.063rem solid #009193',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#ffffff',
            opacity: 0.9,
          },
        },
      },
    ],
  },
};
