import { ACC_CHANGE_ACTION } from 'store/actions/SendMoneyRecipientAction/SendMoneyRecipientAction';

const initialState = {
  accountData: null,
};

const sendMoneyRecipientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACC_CHANGE_ACTION:
      return { accountData: action.payload };

    default:
      return state;
  }
};

export default sendMoneyRecipientReducer;
