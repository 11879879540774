import { button } from './button';
import { select } from './select';
import { textField } from './textField';
import { checkbox } from './checkbox';
import { link } from './link';
import { table } from './table';

export const components = {
  ...textField,
  ...button,
  ...select,
  ...checkbox,
  ...link,
  ...table,
};
