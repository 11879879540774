import React, { Fragment } from 'react';
import { Spinner, Button as BootstrapButton } from 'react-bootstrap';

const Button = ({
  className,
  isLoading,
  type,
  children,
  disabled,
  onClick,
  size,
  variant,
  loadingText,
  ...args
}) => {
  return (
    <BootstrapButton
      {...args}
      type={type ?? 'button'}
      variant={variant ?? 'primary'}
      size={size}
      disabled={isLoading ? true : disabled}
      onClick={isLoading ? null : onClick}
      className={className ?? 'text-center'}
    >
      {isLoading && loadingText ? (
        <Fragment>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          {loadingText}
        </Fragment>
      ) : (
        children
      )}
    </BootstrapButton>
  );
};

export default Button;
