import { CircularProgress } from '@mui/material';
import React from 'react';

function CircularLoader({ size }) {
  return (
    <CircularProgress sx={{ color: 'var(--primary)' }} size={size ?? 30} />
  );
}

export default CircularLoader;
