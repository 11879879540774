export const TYPE_SELECT_ACTION = 'TYPE_SELECT_ACTION';
export const DATE_SELECT_ACTION = 'DATE_SELECT_ACTION';
export const STATUS_AMOUNT_ACTION = 'STATUS_AMOUNT_ACTION';

export function selectInvestmentTypesChangeAction(payload) {
  return {
    type: TYPE_SELECT_ACTION,
    payload,
  };
}

export function selectInvestmentDateChangeAction(payload) {
  return {
    type: DATE_SELECT_ACTION,
    payload,
  };
}

export function selectInvestmentStatusChangeAction(payload) {
  return {
    type: STATUS_AMOUNT_ACTION,
    payload,
  };
}
