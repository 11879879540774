import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { palette } from 'theme/foundations';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '38.438rem',
  backgroundColor: palette.white.main,
  width: '100%',
}));
