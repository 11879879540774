export const checkbox = {
  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: '#D9D9D9',
        padding: '0',
        '&.Mui-checked': {
          color: '#009193',
        },
      },
    },
  },
};
