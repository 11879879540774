import { Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
  pinResetSchema,
  transferSMSVerificationSchema,
} from 'schemas/transferVerificationSchema.schema';
import { TextFieldFormik } from '../TextFieldFormik/TextFieldFormik';
import { useDispatch } from 'react-redux';
import { resetPin } from './PinReset';
import {
  Actions,
  MinWidth,
  ReceiveCode,
  RecendCode,
  TransferVerificationContainer,
  TransferVerificationContent,
  VerificationContent,
} from '../BasicModal/styles';
import { ModalCloseAction } from '../../../store/actions/ModalActions/ModalCloseAction';
import { ModalOpenAction } from '../../../store/actions/ModalActions/ModalOpenAction';
import { PINRestTSuccessMODALName } from '../../../store/actions/ModalActions/ModalActionTypes';

const TransferPinReset = (content) => {
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState(1);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [requestBody, setRequestBody] = useState({});
  const [responseDetails, setResponseDetails] = useState({});

  useEffect(() => {
    setIsLoading(true);
    setError({});
    setRequestBody({
      ephemeral_token: content.content.token,
      phone_challenge_factor: 'sms',
    });
    resetPin({
      ephemeral_token: content.content.token,
      phone_challenge_factor: 'sms',
    })
      .then((res) => {
        setResponseDetails(res.data);
        setSteps(1);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleCancel = () => {
    dispatch(ModalCloseAction());
  };
  const handlePinResetSubmit = async (values) => {
    setIsLoading(true);
    setError({});
    if (steps === 1) {
      const requestData = {
        ...requestBody,
        email_challenge: {
          two_fa: {
            challenge_id: responseDetails.challenge?.two_fa?.challenge_id,
            nonce: values.sms,
          },
        },
      };
      setRequestBody(requestData);
      await resetPin(requestData)
        .then((res) => {
          setResponseDetails(res.data);
          values.sms = '';
          setSteps((prevState) => prevState + 1);
        })
        .catch((err) => {
          console.log(err);
          setError(err.response?.data?.errors[0]);
        });
    } else if (steps === 2) {
      const requestData = {
        ...requestBody,
        phone_challenge: {
          two_fa: {
            challenge_id: responseDetails.challenge?.two_fa?.challenge_id,
            nonce: values.sms,
          },
        },
      };
      setRequestBody(requestData);
      await resetPin(requestData)
        .then((res) => {
          setResponseDetails(res.data);
          setSteps((prevState) => prevState + 1);
          values.sms = '';
        })
        .catch((err) => {
          console.log(err);
          setError(err.response?.data?.errors[0]);
        });
    } else {
      await resetPin({
        ...requestBody,
        passcode: values.passcode,
      })
        .then((res) => {
          setResponseDetails({});
          setRequestBody({});
          setSteps(1);
          values.passcode = '';
          dispatch(
            ModalOpenAction({
              modalName: PINRestTSuccessMODALName,
              content: { ...content },
            })
          );
        })
        .catch((err) => {
          console.log(err);
          setError(err.response?.data?.errors[0]);
        });
    }
    setIsLoading(false);
  };
  return (
    <Formik
      initialValues={{
        sms: '',
        passcode: '',
        confirmPasscode: '',
      }}
      validationSchema={
        steps < 3 ? transferSMSVerificationSchema : pinResetSchema
      }
      onSubmit={handlePinResetSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit} onChange={() => setError({})}>
            <TransferVerificationContainer>
              {steps === 3 && (
                <VerificationContent>
                  <Typography variant="selectOptionText">
                    Reset Passcode
                  </Typography>
                  <TransferVerificationContent />
                  <MinWidth />
                  <Typography variant="phoneVerifyTitle">
                    New Passcode
                  </Typography>
                  {error && (
                    <div className="text-center">
                      <h7 className="text-danger">{error.message}</h7>
                    </div>
                  )}
                  <TextFieldFormik
                    type="password"
                    name="passcode"
                    placeholder="Please enter a 6-digit code"
                    fullWidth
                  />
                  <Typography>&nbsp;</Typography>
                  <Typography variant="phoneVerifyTitle">
                    Confirm Passcode
                  </Typography>
                  <TextFieldFormik
                    type="password"
                    name="confirmPasscode"
                    placeholder="Confirm passcode"
                    fullWidth
                  />
                  <Typography>&nbsp;</Typography>
                </VerificationContent>
              )}
              {steps < 3 && (
                <VerificationContent>
                  <Typography variant="selectOptionText">
                    {steps === 1 ? 'Email' : 'Mobile number'} verification
                  </Typography>
                  <TransferVerificationContent />
                  <Typography variant="phoneVerifyTitle">
                    We've sent an {steps === 1 ? 'email' : 'sms'} with a
                    verification code to your{' '}
                    {steps === 1
                      ? `email ${responseDetails.challenge?.two_fa.truncated_email_address}`
                      : `mobile number ${responseDetails.challenge?.two_fa.truncated_phone_number}`}
                  </Typography>
                  <MinWidth />
                  <Typography>&nbsp;</Typography>
                  {error && (
                    <div className="text-center">
                      <h7 className="text-danger">{error.message}</h7>
                    </div>
                  )}
                  <TextFieldFormik
                    type="text"
                    name="sms"
                    validateOnBlur={false}
                    placeholder="Please enter the verification code"
                    fullWidth
                  />
                  <Typography variant="verifyTimeLeft">
                    Your code is: {responseDetails.challenge?.two_fa.nonce}{' '}
                    (Code are sent on production environments only)
                  </Typography>
                  <ReceiveCode>
                    <Typography variant="didntReceiveSms">
                      Did not receive {steps === 1 ? 'email' : 'sms'}
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <RecendCode>Resend a code</RecendCode>
                  </ReceiveCode>
                </VerificationContent>
              )}
              <Actions>
                <LoadingButton
                  variant="contained"
                  sx={{
                    maxWidth: '9.375rem',
                  }}
                  type="submit"
                  loading={isLoading}
                >
                  Confirm
                </LoadingButton>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    maxWidth: '9.375rem',
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Actions>
            </TransferVerificationContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TransferPinReset;
