import { Typography, Box } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { CustomSelect, SelectContainer } from './styled';
import { SelectArrow } from 'images/SelectArrow';
import { renderItem } from './MenuItems/RenderItem';

export const SelectFormik = ({
  name,
  label,
  options,
  placeholder,
  itemType,
  enablePortal = false,
}) => {
  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <SelectContainer>
      {label && (
        <Typography variant="selectLabel" sx={{ alignSelf: 'start' }}>
          {label}
        </Typography>
      )}
      <CustomSelect
        selected={field.value !== ''}
        value={field.value}
        displayEmpty
        id={name}
        name={name}
        onChange={handleChange}
        error={hasError}
        helpertext={hasError ? meta.error : ''}
        renderValue={
          field.value !== ''
            ? undefined
            : () => (
                <Typography variant="selectPlaceholder">
                  {placeholder}
                </Typography>
              )
        }
        IconComponent={({ ...rest }) => {
          return (
            <Box {...rest}>
              <SelectArrow />
            </Box>
          );
        }}
        MenuProps={{
          disablePortal: !enablePortal,
        }}
      >
        {renderItem({ options, itemType })}
      </CustomSelect>
      {hasError && (
        <Typography variant="errorMessage" sx={{ alignSelf: 'start' }}>
          {meta.error}
        </Typography>
      )}
    </SelectContainer>
  );
};
