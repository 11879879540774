import React, { useEffect, useState, useRef, useContext } from 'react';
import ArrowDown from '../../../../images/arrowdown.png';
import { ThemeContext } from '../../../../context/ThemeContext';
import { accountList } from '../../phoneCountryList/countryList';

const AccountDropdown = ({ selected, lists, handleSelect }) => {
  const { accountData } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef(null);
  const [filterValue, setFilter] = useState('');

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      if (isOpen) setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  });

  return (
    <div className="dropdown-account-wrap" ref={headerRef}>
      <button
        type="button"
        id="menu-account-button"
        onClick={() => {
          console.log('clicked', isOpen);
          setIsOpen(!isOpen);
        }}
      >
        {(selected || selected === 0) && !accountData.includes(selected) ? (
          <div className="account-selected-item">
            <img src={accountList[selected].flag} alt="" />
            {accountList[selected].title}
          </div>
        ) : (
          'Choose currency...'
        )}
        <img
          className={`menu-account-arrow  ${
            isOpen ? 'toggle-up' : 'toggle-down'
          }`}
          src={ArrowDown}
          alt="arrowdown"
        />
      </button>

      {isOpen && (
        <div
          className="custom-account-dropdown-menu"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1 d-flex flex-column" role="none">
            <div className="account-input-group">
              <span className="account-input-group-addon">
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  focusable="false"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.35 12.484a7 7 0 111.133-1.133l3.517 3.508L14.86 16l-3.51-3.515zM12.4 7A5.4 5.4 0 111.6 7a5.4 5.4 0 0110.8 0z"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                className="tw-select-filter form-control"
                placeholder="Search..."
                spellCheck="false"
                value={filterValue}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            <p className="dropdown-header">
              Currency accounts with account details
            </p>
            {lists &&
              lists.map(
                (list, index) =>
                  !accountData.includes(index) &&
                  (filterValue === '' ||
                    list.title
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())) && (
                    <>
                      {index === 8 && (
                        <p className="dropdown-header">
                          Currency accounts without account details
                        </p>
                      )}
                      <a
                        href="#"
                        className="menu-account-item"
                        role="menuitem"
                        tabIndex={-1}
                        id={`menu-item-${index}`}
                        key={`menu-item-${index}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSelect(index);
                          setIsOpen(false);
                        }}
                      >
                        <img src={list.flag} alt="" />
                        {list.title}
                      </a>
                    </>
                  )
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDropdown;
