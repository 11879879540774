import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const RecipientsNameItem = ({ option }) => {
  const { person } = option;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          // maxWidth: 'calc(50% - 1rem)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {person.name && person.name}
      </Typography>
    </Box>
  );
};
