export const RECIPIENT_TYPE_MY_OWN_ACC = 'my-own-acc';
export const RECIPIENT_TYPE_SOMEONE_ELSE = 'someone-else';
export const RECIPIENT_TYPE_MY_CONTACT = 'my-contact';
export const RECIPIENT_TYPE_NEPAL = 'nepal';
export const RECIPIENT_TYPE_INDIA = 'india';

const initialState = {
  recipientType: null,
};

const sendMoneyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECIPIENT_TYPE_MY_OWN_ACC:
      return { recipientType: action.payload };
    case RECIPIENT_TYPE_SOMEONE_ELSE:
      return { recipientType: action.payload };
    case RECIPIENT_TYPE_MY_CONTACT:
      return { recipientType: action.payload };
    case RECIPIENT_TYPE_NEPAL:
      return { recipientType: action.payload };
    case RECIPIENT_TYPE_INDIA:
      return { recipientType: action.payload };
    default:
      return state;
  }
};

export default sendMoneyTypeReducer;
