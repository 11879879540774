import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  transferPasscodeVerificationSchema,
  transferSMSVerificationSchema,
} from 'schemas/transferVerificationSchema.schema';
import {
  TransferPaymentSentModalName,
  TransferPinResetModalName,
} from 'store/actions/ModalActions/ModalActionTypes';
import { ModalCloseAction } from 'store/actions/ModalActions/ModalCloseAction';
import { ModalOpenAction } from 'store/actions/ModalActions/ModalOpenAction';
import { TextFieldFormik } from '../TextFieldFormik/TextFieldFormik';

import {
  Actions,
  MinWidth,
  ReceiveCode,
  RecendCode,
  TransferVerificationContainer,
  TransferVerificationContent,
  VerificationContent,
} from './styles';
import {
  useTransferConsentConfirmation2Mutation,
  useTransferConsentConfirmationMutation,
  useTransferFinalApiForConfirmationMutation,
  useTransferSmsVerficationMutation,
} from 'services/Transfer';
import expiresVerificationTransderTime from 'jsx/hooks/expiresVerificationTransderTime';
import axios from 'axios';
import { ThemeContext } from 'context/ThemeContext';
import { API } from '../../../services/Api';
import swal from 'sweetalert';

const InvestmentTransferVerificationModal = ({ content }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { setCardDetails } = useContext(ThemeContext);
  const [steps, setSteps] = useState(1);
  const [responseDetails, setResponseDetails] = useState({});
  const auth = useSelector((state) => state.auth);
  const [passcode, setPasscode] = useState('');

  function b64_to_utf8(str) {
    return JSON.parse(decodeURIComponent(escape(window.atob(str))));
  }

  const { mutateAsync: transferConsentConfirmation } =
    useTransferConsentConfirmationMutation();

  const { mutateAsync: transferSmsVerfication } =
    useTransferSmsVerficationMutation();

  const { mutateAsync: transferConsentConfirmation2 } =
    useTransferConsentConfirmation2Mutation();

  const { mutateAsync: finalApiForConfirmation } =
    useTransferFinalApiForConfirmationMutation();

  const dataEncode = b64_to_utf8(content?.token);

  const { expiresTransferTime } = expiresVerificationTransderTime(
    content?.time
  );

  const resendOtp = async () => {
    setIsLoading(true);
    try {
      const transferConsentConfirmationData = await transferConsentConfirmation(
        {
          ephemeral_token: content?.token,
          factor: 'sms',
          passcode: passcode,
        }
      );
      setResponseDetails(transferConsentConfirmationData?.data);
    } catch (error) {
      swal({
        title: 'Error',
        icon: 'error',
        text: error.response?.data?.errors[0]?.message,
        button: 'Try Again',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleTransferVerificationSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (content?.token) {
        if (steps === 1) {
          const transferConsentConfirmationData =
            await transferConsentConfirmation({
              ephemeral_token: content?.token,
              factor: 'sms',
              passcode: values.passcode,
            });
          setPasscode(values.passcode);
          setResponseDetails(transferConsentConfirmationData?.data);
          setSteps((prevState) => prevState + 1);
        } else {
          const transferSmsVerficationData = await transferSmsVerfication({
            ephemeral_token: content?.token,
            passcode: values.passcode,
            factor: 'sms',
            challenge: {
              two_fa: {
                challenge_id: responseDetails?.challenge.two_fa?.challenge_id,
                nonce: values.sms,
              },
            },
          });
          const transferConsentConfirmation2Data =
            await transferConsentConfirmation2({
              token: transferSmsVerficationData.data?.access_token,
              data: {
                amount: dataEncode?.payload?.amount,
                currency: dataEncode?.payload?.currency,
                recipient: dataEncode?.payload?.recipient,
                recipients_account_number:
                  dataEncode?.payload?.recipientsAccountNumber,
              },
            });

          const finalApiForConfirmationData = await finalApiForConfirmation({
            data: {
              externalTransactionId: transferConsentConfirmation2Data.data?.id,
              status: 'Approved',
              requestId: transferConsentConfirmation2Data.data?.request_id,
            },
          });

          if (finalApiForConfirmationData.data === 'SuccessFully confirmed') {
            dispatch(ModalCloseAction());
            content.openModal(true);
            content.transactionId(
              transferConsentConfirmation2Data.data?.request_id
            );
            setSteps(1);
          }
        }
      }
    } catch (error) {
      console.log(error);
      swal({
        title: 'Error',
        icon: 'error',
        text: error.response?.data?.errors[0]?.message,
        button: 'Try Again',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(ModalCloseAction());
    content.closeAllModal();
  };
  function handleResetPasscode() {
    dispatch(
      ModalOpenAction({
        modalName: TransferPinResetModalName,
        content: { ...content },
      })
    );
  }
  return (
    <Formik
      initialValues={{
        sms: '',
        passcode: '',
      }}
      validationSchema={
        steps === 1
          ? transferPasscodeVerificationSchema
          : transferSMSVerificationSchema
      }
      onSubmit={handleTransferVerificationSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <TransferVerificationContainer>
              {steps === 1 ? (
                <VerificationContent>
                  <Typography variant="selectOptionText">
                    Passcode Verification
                  </Typography>
                  <TransferVerificationContent />
                  <MinWidth />
                  <Typography variant="phoneVerifyTitle" fontSize={12}>
                    Enter Your Payment Authorization Code (Passcode)
                  </Typography>
                  <Box mb={1} />
                  <TextFieldFormik
                    type="password"
                    name="passcode"
                    placeholder="(Please Enter your 6 Digit Passcode)"
                    fullWidth
                  />
                  <Typography fontWeight="bold" fontSize={12} mt={2}>
                    <InfoIcon fontSize="14px" /> What is the Passcode ?
                  </Typography>
                  <Typography fontSize={12}>
                    It's a 6 Digit Numeric Code you created for financial
                    operations & authorization.
                  </Typography>
                  <ReceiveCode>
                    <Typography fontSize={12}>
                      Have you forgotten your Passcode ? &nbsp;
                    </Typography>
                    <RecendCode>
                      <a onClick={handleResetPasscode}>Reset Your Passcode</a>
                    </RecendCode>
                  </ReceiveCode>
                </VerificationContent>
              ) : (
                <VerificationContent>
                  <Typography variant="selectOptionText">
                    Enter your verification code
                  </Typography>
                  <TransferVerificationContent />
                  <Typography variant="phoneVerifyTitle">
                    We've sent an SMS with a verification code to your phone{' '}
                    {responseDetails.challenge?.two_fa.truncated_phone_number}
                  </Typography>
                  <MinWidth />
                  <Typography
                    variant="verifyTimeLeft"
                    mb="0.625rem"
                    mt="0.44rem"
                  >
                    {expiresTransferTime?.minutes == 0 &&
                    expiresTransferTime?.seconds == 0
                      ? 'Your code will not be valid'
                      : `Your code will not be valid in ${expiresTransferTime?.minutes} minutes ${expiresTransferTime?.seconds} seconds`}
                  </Typography>
                  <TextFieldFormik
                    type="text"
                    name="sms"
                    validateOnBlur={false}
                    placeholder="Please enter the code"
                    fullWidth
                  />
                  {responseDetails.challenge?.two_fa.nonce && (
                    <Typography variant="verifyTimeLeft">
                      Your code is: {responseDetails.challenge?.two_fa.nonce}{' '}
                      (SMS are sent on production environments only)
                    </Typography>
                  )}
                  <ReceiveCode>
                    <Typography variant="didntReceiveSms">
                      Did not receive SMS?
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <RecendCode>
                      <a onClick={resendOtp}>Resend a code</a>
                    </RecendCode>
                  </ReceiveCode>
                </VerificationContent>
              )}
              <Actions>
                <LoadingButton
                  variant="contained"
                  sx={{
                    maxWidth: '9.375rem',
                  }}
                  type="submit"
                  loading={isLoading}
                >
                  Confirm
                </LoadingButton>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    maxWidth: '9.375rem',
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Actions>
            </TransferVerificationContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InvestmentTransferVerificationModal;
