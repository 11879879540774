import Select from 'react-select';

const customStyles = {
  container: (provided) => ({
    ...provided,
    maxWidth: '27.625rem',
    // background: 'transparent',
    // border: '#a0aec0',
    // ':hover': { background: 'transparent' },
  }),
  control: (
    provided,
    { isDisabled, isFocused, selectProps: { isRoundedCorner } }
  ) => ({
    ...provided,
    display: 'flex',
    fontFamily: 'inherit',
    fontSize: 18,
    fontWeight: 400,
    color: 'var(--gray-dark)',
    height: 'inherit',
    border: isRoundedCorner ? '1px solid #a0aec0' : 'none',
    backgroundColor: isRoundedCorner ? '#ffffff' : 'transparent',
    borderRadius: isRoundedCorner ? '0.5rem' : 0,
    ':focus': {
      borderColor: '#a0aec0',
      boxShadow: '0 0 0 0px #a0aec0',
    },
    ':hover': {
      borderColor: '#a0aec0',
      boxShadow: '0 0 0 0px #a0aec0',
    },
  }),
  valueContainer: (provided, { selectProps: { isRoundedCorner } }) => ({
    ...provided,
    fontSize: 18,
    padding: '9px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    visibility: 'hidden',
  }),
  input: ({ margin, paddingTop, paddingBottom, ...provided }, state) => ({
    ...provided,
  }),
  menu: ({ marginTop, ...provided }, state) => ({
    ...provided,
    fontWeight: 400,
    color: 'var(--gray-dark)',
    marginTop: 5,
  }),
  menuList: ({ ...provided }, state) => ({
    ...provided,
    maxHeight: 200,
  }),
};

function SelectMenu({
  options,
  value,
  onChange,
  inputRef,
  name,
  isRounded,
  isDisabled,
  menuPosition = 'bottom',
  className,
}) {
  return (
    <Select
      inputRef={inputRef}
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      styles={customStyles}
      isDisabled={isDisabled}
      menuPlacement={menuPosition}
      isRoundedCorner={isRounded}
      className={className}
    />
  );
}

export default SelectMenu;
