import { styled } from '@mui/system';
import OtpInput from 'react-otp-input';

export const OTPInput = styled(OtpInput)(({ theme }) => ({
  marginRight: '0.812rem',
  marginBottom: '0.625rem',
  '&:last-of-type': {
    marginRight: '1.44rem',
  },
  [theme.breakpoints.down('tabletS')]: {
    marginBottom: '1.625rem',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
}));
