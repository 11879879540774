import { useFetchUserLedgerQuery } from 'services/Ledger';

const { ThemeContext } = require('context/ThemeContext');
const { useContext } = require('react');

const getLedgerSort = () => {
  const { data: userLedgers = [] } = useFetchUserLedgerQuery();
  const { user } = useContext(ThemeContext);
  const userLedgersSort = userLedgers.sort(
    (a, b) =>
      (a.active ?? 0 - b.active ?? 0 ? -1 : 1) ||
      (a.last_modified_at > b.last_modified_at ? 1 : -1).slice(0, 5)
  );
  const userLedgerType =
    user?.accountType === 'personal' ? [userLedgersSort[0]] : userLedgersSort;

  return { userLedgerType };
};

export default getLedgerSort;
