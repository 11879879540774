import { Container } from './styles';

export const Stepper = ({ components, state, step, setStep, onBack }) => {
  const onClickNext = () => {
    setStep((prev) => prev + 1);
  };

  const onClickBack = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <Container>
      {components?.map((Component, index) => {
        return index === step ? (
          <Component
            key={index}
            {...state}
            nextStep={onClickNext}
            prevStep={onBack ?? onClickBack}
          />
        ) : null;
      })}
    </Container>
  );
};
