export const table = {
  MuiTableHead: {
    styleOverrides: {
      root: {
        border: 'none',
        userSelect: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid #F0F0F0',
      },
    },
  },
};
