const SendMoneySuccsess = () => {
  return (
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="52" fill="#009193" fill-opacity="0.2" />
      <circle cx="52" cy="52" r="39" fill="#009193" fill-opacity="0.5" />
      <circle cx="51.5" cy="52.5" r="24.5" fill="#009193" />
      <path
        d="M40.3921 51.047C39.8701 50.873 39.8651 50.592 40.4021 50.413L59.4891 44.051C60.0181 43.875 60.3211 44.171 60.1731 44.689L54.7191 63.775C54.5691 64.304 54.2641 64.322 54.0401 63.82L50.4461 55.732L56.4461 47.732L48.4461 53.732L40.3921 51.047V51.047Z"
        fill="white"
      />
    </svg>
  );
};

export default SendMoneySuccsess;
