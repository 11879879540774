import { Chip, Typography } from '@mui/material';
import React from 'react';

export const txnType = {
  INTER_LEDGER: 'INTER_LEDGER',
  SEND_MONEY: 'SEND_MONEY',
  LOAD_MONEY: 'LOAD_MONEY',
  SCHEDULE_PAYMENT: 'SCHEDULE_PAYMENT',
  BUY_CRYPTO: 'BUY_CRYPTO_TRANSACTION',
  BULK_PAYMENT: 'BULK_PAYMENT',
  SELL_CRYPTO: 'SELL_CRYPTO_TRANSACTION',
  TXN_INTERNATIONAL: 'TXN_INTERNATIONAL',
  FEES: 'FEES',
  CARD_PAYMENT: 'CARD_PAYMENT',
};

const Label = ({ label }) => {
  return (
    <Typography fontSize={12} fontWeight={500}>
      {label}
    </Typography>
  );
};

function TransactionLabel({ type, isReceiver, isRefund }) {
  if (isRefund) {
    return <Chip size="small" label={<Label label="CRYPTO : Refund" />} />;
  }

  switch (type) {
    case txnType.BUY_CRYPTO:
      return (
        <Chip size="small" label={<Label label="CRYPTO : Investment" />} />
      );
    case txnType.SELL_CRYPTO:
      return (
        <Chip size="small" label={<Label label="CRYPTO : Withdrawal" />} />
      );
    case txnType.LOAD_MONEY:
      return <Chip size="small" label={<Label label="TOPUP - Issuing" />} />;
    case txnType.TXN_INTERNATIONAL:
      return <Chip size="small" label={<Label label="SEPA - Outgoing" />} />;
    case txnType.FEES:
      return <Chip size="small" label={<Label label="FEES" />} />;
    case txnType.CARD_PAYMENT:
      return (
        <Chip size="small" label={<Label label="CARD : CARD - Payment" />} />
      );
    default: {
      if (isReceiver)
        return <Chip size="small" label={<Label label="SEPA - Incoming" />} />;
      return <Chip size="small" label={<Label label="SEPA - Outgoing" />} />;
    }
  }
}

export default TransactionLabel;
