import { useQuery, useInfiniteQuery } from 'react-query';
import { API } from './Api';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../context/ThemeContext';

const useFetchNotificationCount = (state) => {
  return useQuery(
    API.v1.notification.count,
    () => {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_API}${API.v1.notification.count}`,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
    },
    {
      keepPreviousData: false,
      select: ({ data }) => data || {},
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const useFetchNotificationPaginatedList = (state, limit) => {
  const [notification, setNotification] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [hashMore, setHashMore] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    setIsLoadingData(true);
    paginatedApiCall(pageNo, limit, state)
      .then((res) => {
        setNotification((prevState) => {
          return [...prevState, ...res.data?.notifications];
        });
        setHashMore(res.data?.remainingPages > 1);
        setIsLoadingData(false);
        setIsLoadingPage(false);
      })
      .catch((error) => console.log(error));
  }, [pageNo]);

  return {
    notification,
    pageNo,
    hashMore,
    isLoadingData,
    setPageNo,
    isLoadingPage,
    setNotification,
  };
};

const useFetchNotificationForMarkAllAsRead = (
  state,
  pageNo,
  limit,
  setNotification
) => {
  paginatedApiCall(pageNo, limit, state)
    .then((res) => {
      setNotification(res.data?.notifications);
    })
    .catch((error) => console.log(error));
};

const paginatedApiCall = (pageNo, limit, state) => {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_API}${API.v1.notification.paginatedList}`,
    {
      pageNo: pageNo,
      limit: limit,
    },
    {
      headers: {
        Authorization: `Bearer ${state.auth.accessToken}`,
        'x-refresh': `${state.auth.refreshToken}`,
      },
    }
  );
};

const useFetchNotificationList = () => {
  const { state } = useContext(ThemeContext);

  return useQuery(
    API.v1.notification.getList,
    () => {
      return axios.get(
        `${process.env.REACT_APP_BACKEND_API}${API.v1.notification.getList}`,
        {
          headers: {
            Authorization: `Bearer ${state.auth.accessToken}`,
            'x-refresh': `${state.auth.refreshToken}`,
          },
        }
      );
    },
    {
      keepPreviousData: true,
      select: ({ data }) => data || {},
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export {
  useFetchNotificationList,
  useFetchNotificationCount,
  useFetchNotificationPaginatedList,
  useFetchNotificationForMarkAllAsRead,
};
