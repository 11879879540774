import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactComponent as Sport } from 'images/employee/sport.svg';
import { ReactComponent as Car } from 'images/employee/car.svg';
import { ReactComponent as Medicine } from 'images/employee/medicine.svg';
import { ReactComponent as Restaurant } from 'images/employee/restaurant.svg';
import { ReactComponent as Grocery } from 'images/employee/grocery.svg';
import { ReactComponent as Travel } from 'images/employee/travel.svg';
import { ReactComponent as Shopping } from 'images/employee/shopping.svg';
import { ReactComponent as Entertainment } from 'images/employee/entertainment.svg';

export const categoryOptions = [
  { label: 'Sport', value: 'Sport', icon: <Sport /> },
  { label: 'Car & Service', value: 'Car & Service', icon: <Car /> },
  { label: 'Medicine', value: 'Medicine', icon: <Medicine /> },
  {
    label: 'Restuarant & Bars',
    value: 'Restuarant & Bars',
    icon: <Restaurant />,
  },
  { label: 'Grocery', value: 'Grocery', icon: <Grocery /> },
  { label: 'Travel', value: 'Travel', icon: <Travel /> },
  { label: 'Shopping', value: 'Shopping', icon: <Shopping /> },
  { label: 'Entertainment', value: 'Entertainment', icon: <Entertainment /> },
];

export const CategoryItem = ({ option }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {option.icon}
      <Typography ml={1.5}>{option.label}</Typography>
    </Box>
  );
};
