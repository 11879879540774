export const EEACountries = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LU',
  'LT',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SK',
  'SI',
];
export const CountriesNationality = [
  {
    code: 'AF',
    label: 'Afghan',
    value: 'Afghan',
  },
  {
    code: 'AX',
    label: '\u00c5land Island',
    value: '\u00c5land Island',
  },
  {
    code: 'AL',
    label: 'Albanian',
    value: 'Albanian',
  },
  {
    code: 'DZ',
    label: 'Algerian',
    value: 'Algerian',
  },
  {
    code: 'AS',
    label: 'American Samoan',
    value: 'American Samoan',
  },
  {
    code: 'AD',
    label: 'Andorran',
    value: 'Andorran',
  },
  {
    code: 'AO',
    label: 'Angolan',
    value: 'Angolan',
  },
  {
    code: 'AI',
    label: 'Anguillan',
    value: 'Anguillan',
  },
  {
    code: 'AQ',
    label: 'Antarctic',
    value: 'Antarctic',
  },
  {
    code: 'AG',
    label: 'Antiguan or Barbudan',
    value: 'Antiguan or Barbudan',
  },
  {
    code: 'AR',
    label: 'Argentine',
    value: 'Argentine',
  },
  {
    code: 'AM',
    label: 'Armenian',
    value: 'Armenian',
  },
  {
    code: 'AW',
    label: 'Aruban',
    value: 'Aruban',
  },
  {
    code: 'AU',
    label: 'Australian',
    value: 'Australian',
  },
  {
    code: 'AT',
    label: 'Austrian',
    value: 'Austrian',
  },
  {
    code: 'AZ',
    label: 'Azerbaijani, Azeri',
    value: 'Azerbaijani, Azeri',
  },
  {
    code: 'BS',
    label: 'Bahamian',
    value: 'Bahamian',
  },
  {
    code: 'BH',
    label: 'Bahraini',
    value: 'Bahraini',
  },
  {
    code: 'BD',
    label: 'Bangladeshi',
    value: 'Bangladeshi',
  },
  {
    code: 'BB',
    label: 'Barbadian',
    value: 'Barbadian',
  },
  {
    code: 'BY',
    label: 'Belarusian',
    value: 'Belarusian',
  },
  {
    code: 'BE',
    label: 'Belgian',
    value: 'Belgian',
  },
  {
    code: 'BZ',
    label: 'Belizean',
    value: 'Belizean',
  },
  {
    code: 'BJ',
    label: 'Beninese, Beninois',
    value: 'Beninese, Beninois',
  },
  {
    code: 'BM',
    label: 'Bermudian, Bermudan',
    value: 'Bermudian, Bermudan',
  },
  {
    code: 'BT',
    label: 'Bhutanese',
    value: 'Bhutanese',
  },
  {
    code: 'BO',
    label: 'Bolivian',
    value: 'Bolivian',
  },
  {
    code: 'BQ',
    label: 'Bonaire',
    value: 'Bonaire',
  },
  {
    code: 'BA',
    label: 'Bosnian or Herzegovinian',
    value: 'Bosnian or Herzegovinian',
  },
  {
    code: 'BW',
    label: 'Motswana, Botswanan',
    value: 'Motswana, Botswanan',
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    code: 'BR',
    label: 'Brazilian',
    value: 'Brazilian',
  },
  {
    code: 'IO',
    label: 'BIOT',
    value: 'BIOT',
  },
  {
    code: 'BN',
    label: 'Bruneian',
    value: 'Bruneian',
  },
  {
    code: 'BG',
    label: 'Bulgarian',
    value: 'Bulgarian',
  },
  {
    code: 'BF',
    label: 'Burkinab\u00e9',
    value: 'Burkinab\u00e9',
  },
  {
    code: 'BI',
    label: 'Burundian',
    value: 'Burundian',
  },
  {
    code: 'CV',
    label: 'Cabo Verdean',
    value: 'Cabo Verdean',
  },
  {
    code: 'KH',
    label: 'Cambodian',
    value: 'Cambodian',
  },
  {
    code: 'CM',
    label: 'Cameroonian',
    value: 'Cameroonian',
  },
  {
    code: 'CA',
    label: 'Canadian',
    value: 'Canadian',
  },
  {
    code: 'KY',
    label: 'Caymanian',
    value: 'Caymanian',
  },
  {
    code: 'CF',
    label: 'Central African',
    value: 'Central African',
  },
  {
    code: 'TD',
    label: 'Chadian',
    value: 'Chadian',
  },
  {
    code: 'CL',
    label: 'Chilean',
    value: 'Chilean',
  },
  {
    code: 'CN',
    label: 'Chinese',
    value: 'Chinese',
  },
  {
    code: 'CX',
    label: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    code: 'CC',
    label: 'Cocos Island',
    value: 'Cocos Island',
  },
  {
    code: 'CO',
    label: 'Colombian',
    value: 'Colombian',
  },
  {
    code: 'KM',
    label: 'Comoran, Comorian',
    value: 'Comoran, Comorian',
  },
  {
    code: 'CG',
    label: 'Congolese',
    value: 'Congolese',
  },
  {
    code: 'CD',
    label: 'Congolese',
    value: 'Congolese',
  },
  {
    code: 'CK',
    label: 'Cook Island',
    value: 'Cook Island',
  },
  {
    code: 'CR',
    label: 'Costa Rican',
    value: 'Costa Rican',
  },
  {
    code: 'CI',
    label: 'Ivorian',
    value: 'Ivorian',
  },
  {
    code: 'HR',
    label: 'Croatian',
    value: 'Croatian',
  },
  {
    code: 'CU',
    label: 'Cuban',
    value: 'Cuban',
  },
  {
    code: 'CW',
    label: 'Cura\u00e7aoan',
    value: 'Cura\u00e7aoan',
  },
  {
    code: 'CY',
    label: 'Cypriot',
    value: 'Cypriot',
  },
  {
    code: 'CZ',
    label: 'Czech',
    value: 'Czech',
  },
  {
    code: 'DK',
    label: 'Danish',
    value: 'Danish',
  },
  {
    code: 'DJ',
    label: 'Djiboutian',
    value: 'Djiboutian',
  },
  {
    code: 'DM',
    label: 'Dominican',
    value: 'Dominican',
  },
  {
    code: 'DO',
    label: 'Dominican',
    value: 'Dominican',
  },
  {
    code: 'EC',
    label: 'Ecuadorian',
    value: 'Ecuadorian',
  },
  {
    code: 'EG',
    label: 'Egyptian',
    value: 'Egyptian',
  },
  {
    code: 'SV',
    label: 'Salvadoran',
    value: 'Salvadoran',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinean, Equatoguinean',
    value: 'Equatorial Guinean, Equatoguinean',
  },
  {
    code: 'ER',
    label: 'Eritrean',
    value: 'Eritrean',
  },
  {
    code: 'EE',
    label: 'Estonian',
    value: 'Estonian',
  },
  {
    code: 'ET',
    label: 'Ethiopian',
    value: 'Ethiopian',
  },
  {
    code: 'FK',
    label: 'Falkland Island',
    value: 'Falkland Island',
  },
  {
    code: 'FO',
    label: 'Faroese',
    value: 'Faroese',
  },
  {
    code: 'FJ',
    label: 'Fijian',
    value: 'Fijian',
  },
  {
    code: 'FI',
    label: 'Finnish',
    value: 'Finnish',
  },
  {
    code: 'FR',
    label: 'French',
    value: 'French',
  },
  {
    code: 'GF',
    label: 'French Guianese',
    value: 'French Guianese',
  },
  {
    code: 'PF',
    label: 'French Polynesian',
    value: 'French Polynesian',
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    value: 'French Southern Territories',
  },
  {
    code: 'GA',
    label: 'Gabonese',
    value: 'Gabonese',
  },
  {
    code: 'GM',
    label: 'Gambian',
    value: 'Gambian',
  },
  {
    code: 'GE',
    label: 'Georgian',
    value: 'Georgian',
  },
  {
    code: 'DE',
    label: 'German',
    value: 'German',
  },
  {
    code: 'GH',
    label: 'Ghanaian',
    value: 'Ghanaian',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    code: 'GR',
    label: 'Greek, Hellenic',
    value: 'Greek, Hellenic',
  },
  {
    code: 'GL',
    label: 'Greenlandic',
    value: 'Greenlandic',
  },
  {
    code: 'GD',
    label: 'Grenadian',
    value: 'Grenadian',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    code: 'GU',
    label: 'Guamanian, Guambat',
    value: 'Guamanian, Guambat',
  },
  {
    code: 'GT',
    label: 'Guatemalan',
    value: 'Guatemalan',
  },
  {
    code: 'GG',
    label: 'Channel Island',
    value: 'Channel Island',
  },
  {
    code: 'GN',
    label: 'Guinean',
    value: 'Guinean',
  },
  {
    code: 'GW',
    label: 'Bissau-Guinean',
    value: 'Bissau-Guinean',
  },
  {
    code: 'GY',
    label: 'Guyanese',
    value: 'Guyanese',
  },
  {
    code: 'HT',
    label: 'Haitian',
    value: 'Haitian',
  },
  {
    code: 'HM',
    label: 'Heard Island or McDonald Islands',
    value: 'Heard Island or McDonald Islands',
  },
  {
    code: 'VA',
    label: 'Vatican',
    value: 'Vatican',
  },
  {
    code: 'HN',
    label: 'Honduran',
    value: 'Honduran',
  },
  {
    code: 'HK',
    label: 'Hong Kong, Hong Kongese',
    value: 'Hong Kong, Hong Kongese',
  },
  {
    code: 'HU',
    label: 'Hungarian, Magyar',
    value: 'Hungarian, Magyar',
  },
  {
    code: 'IS',
    label: 'Icelandic',
    value: 'Icelandic',
  },
  {
    code: 'IN',
    label: 'Indian',
    value: 'Indian',
  },
  {
    code: 'ID',
    label: 'Indonesian',
    value: 'Indonesian',
  },
  {
    code: 'IR',
    label: 'Iranian, Persian',
    value: 'Iranian, Persian',
  },
  {
    code: 'IQ',
    label: 'Iraqi',
    value: 'Iraqi',
  },
  {
    code: 'IE',
    label: 'Irish',
    value: 'Irish',
  },
  {
    code: 'IM',
    label: 'Manx',
    value: 'Manx',
  },
  {
    code: 'IL',
    label: 'Israeli',
    value: 'Israeli',
  },
  {
    code: 'IT',
    label: 'Italian',
    value: 'Italian',
  },
  {
    code: 'JM',
    label: 'Jamaican',
    value: 'Jamaican',
  },
  {
    code: 'JP',
    label: 'Japanese',
    value: 'Japanese',
  },
  {
    code: 'JE',
    label: 'Channel Island',
    value: 'Channel Island',
  },
  {
    code: 'JO',
    label: 'Jordanian',
    value: 'Jordanian',
  },
  {
    code: 'KZ',
    label: 'Kazakhstani, Kazakh',
    value: 'Kazakhstani, Kazakh',
  },
  {
    code: 'KE',
    label: 'Kenyan',
    value: 'Kenyan',
  },
  {
    code: 'KI',
    label: 'I-Kiribati',
    value: 'I-Kiribati',
  },
  {
    code: 'KP',
    label: 'North Korean',
    value: 'North Korean',
  },
  {
    code: 'KR',
    label: 'South Korean',
    value: 'South Korean',
  },
  {
    code: 'KW',
    label: 'Kuwaiti',
    value: 'Kuwaiti',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
    value: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
  },
  {
    code: 'LA',
    label: 'Lao, Laotian',
    value: 'Lao, Laotian',
  },
  {
    code: 'LV',
    label: 'Latvian',
    value: 'Latvian',
  },
  {
    code: 'LB',
    label: 'Lebanese',
    value: 'Lebanese',
  },
  {
    code: 'LS',
    label: 'Basotho',
    value: 'Basotho',
  },
  {
    code: 'LR',
    label: 'Liberian',
    value: 'Liberian',
  },
  {
    code: 'LY',
    label: 'Libyan',
    value: 'Libyan',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    code: 'LT',
    label: 'Lithuanian',
    value: 'Lithuanian',
  },
  {
    code: 'LU',
    label: 'Luxembourg, Luxembourgish',
    value: 'Luxembourg, Luxembourgish',
  },
  {
    code: 'MO',
    label: 'Macanese, Chinese',
    value: 'Macanese, Chinese',
  },
  {
    code: 'MK',
    label: 'Macedonian',
    value: 'Macedonian',
  },
  {
    code: 'MG',
    label: 'Malagasy',
    value: 'Malagasy',
  },
  {
    code: 'MW',
    label: 'Malawian',
    value: 'Malawian',
  },
  {
    code: 'MY',
    label: 'Malaysian',
    value: 'Malaysian',
  },
  {
    code: 'MV',
    label: 'Maldivian',
    value: 'Maldivian',
  },
  {
    code: 'ML',
    label: 'Malian, Malinese',
    value: 'Malian, Malinese',
  },
  {
    code: 'MT',
    label: 'Maltese',
    value: 'Maltese',
  },
  {
    code: 'MH',
    label: 'Marshallese',
    value: 'Marshallese',
  },
  {
    code: 'MQ',
    label: 'Martiniquais, Martinican',
    value: 'Martiniquais, Martinican',
  },
  {
    code: 'MR',
    label: 'Mauritanian',
    value: 'Mauritanian',
  },
  {
    code: 'MU',
    label: 'Mauritian',
    value: 'Mauritian',
  },
  {
    code: 'YT',
    label: 'Mahoran',
    value: 'Mahoran',
  },
  {
    code: 'MX',
    label: 'Mexican',
    value: 'Mexican',
  },
  {
    code: 'FM',
    label: 'Micronesian',
    value: 'Micronesian',
  },
  {
    code: 'MD',
    label: 'Moldovan',
    value: 'Moldovan',
  },
  {
    code: 'MC',
    label: 'Mon\u00e9gasque, Monacan',
    value: 'Mon\u00e9gasque, Monacan',
  },
  {
    code: 'MN',
    label: 'Mongolian',
    value: 'Mongolian',
  },
  {
    code: 'ME',
    label: 'Montenegrin',
    value: 'Montenegrin',
  },
  {
    code: 'MS',
    label: 'Montserratian',
    value: 'Montserratian',
  },
  {
    code: 'MA',
    label: 'Moroccan',
    value: 'Moroccan',
  },
  {
    code: 'MZ',
    label: 'Mozambican',
    value: 'Mozambican',
  },
  {
    code: 'MM',
    label: 'Burmese',
    value: 'Burmese',
  },
  {
    code: 'NA',
    label: 'Namibian',
    value: 'Namibian',
  },
  {
    code: 'NR',
    label: 'Nauruan',
    value: 'Nauruan',
  },
  {
    code: 'NP',
    label: 'Nepali, Nepalese',
    value: 'Nepali, Nepalese',
  },
  {
    code: 'NL',
    label: 'Dutch, Netherlandic',
    value: 'Dutch, Netherlandic',
  },
  {
    code: 'NC',
    label: 'New Caledonian',
    value: 'New Caledonian',
  },
  {
    code: 'NZ',
    label: 'New Zealand, NZ',
    value: 'New Zealand, NZ',
  },
  {
    code: 'NI',
    label: 'Nicaraguan',
    value: 'Nicaraguan',
  },
  {
    code: 'NE',
    label: 'Nigerien',
    value: 'Nigerien',
  },
  {
    code: 'NG',
    label: 'Nigerian',
    value: 'Nigerian',
  },
  {
    code: 'NU',
    label: 'Niuean',
    value: 'Niuean',
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    code: 'MP',
    label: 'Northern Marianan',
    value: 'Northern Marianan',
  },
  {
    code: 'NO',
    label: 'Norwegian',
    value: 'Norwegian',
  },
  {
    code: 'OM',
    label: 'Omani',
    value: 'Omani',
  },
  {
    code: 'PK',
    label: 'Pakistani',
    value: 'Pakistani',
  },
  {
    code: 'PW',
    label: 'Palauan',
    value: 'Palauan',
  },
  {
    code: 'PS',
    label: 'Palestinian',
    value: 'Palestinian',
  },
  {
    code: 'PA',
    label: 'Panamanian',
    value: 'Panamanian',
  },
  {
    code: 'PG',
    label: 'Papua New Guinean, Papuan',
    value: 'Papua New Guinean, Papuan',
  },
  {
    code: 'PY',
    label: 'Paraguayan',
    value: 'Paraguayan',
  },
  {
    code: 'PE',
    label: 'Peruvian',
    value: 'Peruvian',
  },
  {
    code: 'PH',
    label: 'Philippine, Filipino',
    value: 'Philippine, Filipino',
  },
  {
    code: 'PN',
    label: 'Pitcairn Island',
    value: 'Pitcairn Island',
  },
  {
    code: 'PL',
    label: 'Polish',
    value: 'Polish',
  },
  {
    code: 'PT',
    label: 'Portuguese',
    value: 'Portuguese',
  },
  {
    code: 'PR',
    label: 'Puerto Rican',
    value: 'Puerto Rican',
  },
  {
    code: 'QA',
    label: 'Qatari',
    value: 'Qatari',
  },
  {
    code: 'RE',
    label: 'R\u00e9unionese, R\u00e9unionnais',
    value: 'R\u00e9unionese, R\u00e9unionnais',
  },
  {
    code: 'RO',
    label: 'Romanian',
    value: 'Romanian',
  },
  {
    code: 'RU',
    label: 'Russian',
    value: 'Russian',
  },
  {
    code: 'RW',
    label: 'Rwandan',
    value: 'Rwandan',
  },
  {
    code: 'BL',
    label: 'Barth\u00e9lemois',
    value: 'Barth\u00e9lemois',
  },
  {
    code: 'SH',
    label: 'Saint Helenian',
    value: 'Saint Helenian',
  },
  {
    code: 'KN',
    label: 'Kittitian or Nevisian',
    value: 'Kittitian or Nevisian',
  },
  {
    code: 'LC',
    label: 'Saint Lucian',
    value: 'Saint Lucian',
  },
  {
    code: 'MF',
    label: 'Saint-Martinoise',
    value: 'Saint-Martinoise',
  },
  {
    code: 'PM',
    label: 'Saint-Pierrais or Miquelonnais',
    value: 'Saint-Pierrais or Miquelonnais',
  },
  {
    code: 'VC',
    label: 'Saint Vincentian, Vincentian',
    value: 'Saint Vincentian, Vincentian',
  },
  {
    code: 'WS',
    label: 'Samoan',
    value: 'Samoan',
  },
  {
    code: 'SM',
    label: 'Sammarinese',
    value: 'Sammarinese',
  },
  {
    code: 'ST',
    label: 'S\u00e3o Tom\u00e9an',
    value: 'S\u00e3o Tom\u00e9an',
  },
  {
    code: 'SA',
    label: 'Saudi, Saudi Arabian',
    value: 'Saudi, Saudi Arabian',
  },
  {
    code: 'SN',
    label: 'Senegalese',
    value: 'Senegalese',
  },
  {
    code: 'RS',
    label: 'Serbian',
    value: 'Serbian',
  },
  {
    code: 'SC',
    label: 'Seychellois',
    value: 'Seychellois',
  },
  {
    code: 'SL',
    label: 'Sierra Leonean',
    value: 'Sierra Leonean',
  },
  {
    code: 'SG',
    label: 'Singaporean',
    value: 'Singaporean',
  },
  {
    code: 'SX',
    label: 'Sint Maarten',
    value: 'Sint Maarten',
  },
  {
    code: 'SK',
    label: 'Slovak',
    value: 'Slovak',
  },
  {
    code: 'SI',
    label: 'Slovenian, Slovene',
    value: 'Slovenian, Slovene',
  },
  {
    code: 'SB',
    label: 'Solomon Island',
    value: 'Solomon Island',
  },
  {
    code: 'SO',
    label: 'Somali, Somalian',
    value: 'Somali, Somalian',
  },
  {
    code: 'ZA',
    label: 'South African',
    value: 'South African',
  },
  {
    code: 'GS',
    label: 'South Georgia or South Sandwich Islands',
    value: 'South Georgia or South Sandwich Islands',
  },
  {
    code: 'SS',
    label: 'South Sudanese',
    value: 'South Sudanese',
  },
  {
    code: 'ES',
    label: 'Spanish',
    value: 'Spanish',
  },
  {
    code: 'LK',
    label: 'Sri Lankan',
    value: 'Sri Lankan',
  },
  {
    code: 'SD',
    label: 'Sudanese',
    value: 'Sudanese',
  },
  {
    code: 'SR',
    label: 'Surinamese',
    value: 'Surinamese',
  },
  {
    code: 'SJ',
    label: 'Svalbard',
    value: 'Svalbard',
  },
  {
    code: 'SZ',
    label: 'Swazi',
    value: 'Swazi',
  },
  {
    code: 'SE',
    label: 'Swedish',
    value: 'Swedish',
  },
  {
    code: 'CH',
    label: 'Swiss',
    value: 'Swiss',
  },
  {
    code: 'SY',
    label: 'Syrian',
    value: 'Syrian',
  },
  {
    code: 'TW',
    label: 'Chinese, Taiwanese',
    value: 'Chinese, Taiwanese',
  },
  {
    code: 'TJ',
    label: 'Tajikistani',
    value: 'Tajikistani',
  },
  {
    code: 'TZ',
    label: 'Tanzanian',
    value: 'Tanzanian',
  },
  {
    code: 'TH',
    label: 'Thai',
    value: 'Thai',
  },
  {
    code: 'TL',
    label: 'Timorese',
    value: 'Timorese',
  },
  {
    code: 'TG',
    label: 'Togolese',
    value: 'Togolese',
  },
  {
    code: 'TK',
    label: 'Tokelauan',
    value: 'Tokelauan',
  },
  {
    code: 'TO',
    label: 'Tongan',
    value: 'Tongan',
  },
  {
    code: 'TT',
    label: 'Trinidadian or Tobagonian',
    value: 'Trinidadian or Tobagonian',
  },
  {
    code: 'TN',
    label: 'Tunisian',
    value: 'Tunisian',
  },
  {
    code: 'TR',
    label: 'Turkish',
    value: 'Turkish',
  },
  {
    code: 'TM',
    label: 'Turkmen',
    value: 'Turkmen',
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Island',
    value: 'Turks and Caicos Island',
  },
  {
    code: 'TV',
    label: 'Tuvaluan',
    value: 'Tuvaluan',
  },
  {
    code: 'UG',
    label: 'Ugandan',
    value: 'Ugandan',
  },
  {
    code: 'UA',
    label: 'Ukrainian',
    value: 'Ukrainian',
  },
  {
    code: 'AE',
    label: 'Emirati, Emirian, Emiri',
    value: 'Emirati, Emirian, Emiri',
  },
  {
    code: 'GB',
    label: 'British, UK',
    value: 'British, UK',
  },
  {
    code: 'UM',
    label: 'American',
    value: 'American',
  },
  {
    code: 'US',
    label: 'American',
    value: 'American',
  },
  {
    code: 'UY',
    label: 'Uruguayan',
    value: 'Uruguayan',
  },
  {
    code: 'UZ',
    label: 'Uzbekistani, Uzbek',
    value: 'Uzbekistani, Uzbek',
  },
  {
    code: 'VU',
    label: 'Ni-Vanuatu, Vanuatuan',
    value: 'Ni-Vanuatu, Vanuatuan',
  },
  {
    code: 'VE',
    label: 'Venezuelan',
    value: 'Venezuelan',
  },
  {
    code: 'VN',
    label: 'Vietnamese',
    value: 'Vietnamese',
  },
  {
    code: 'VG',
    label: 'British Virgin Island',
    value: 'British Virgin Island',
  },
  {
    code: 'VI',
    label: 'U.S. Virgin Island',
    value: 'U.S. Virgin Island',
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna, Wallisian or Futunan',
    value: 'Wallis and Futuna, Wallisian or Futunan',
  },
  {
    code: 'EH',
    label: 'Sahrawi, Sahrawian, Sahraouian',
    value: 'Sahrawi, Sahrawian, Sahraouian',
  },
  {
    code: 'YE',
    label: 'Yemeni',
    value: 'Yemeni',
  },
  {
    code: 'ZM',
    label: 'Zambian',
    value: 'Zambian',
  },
  {
    code: 'ZW',
    label: 'Zimbabwean',
    value: 'Zimbabwean',
  },
];

export const ISOCountry = [
  {
    name: 'Afghanistan',
    alpha_2: 'AF',
    'country-code': '004',
  },
  {
    name: 'Åland Islands',
    alpha_2: 'AX',
    'country-code': '248',
  },
  {
    name: 'Albania',
    alpha_2: 'AL',
    'country-code': '008',
  },
  {
    name: 'Algeria',
    alpha_2: 'DZ',
    'country-code': '012',
  },
  {
    name: 'American Samoa',
    alpha_2: 'AS',
    'country-code': '016',
  },
  {
    name: 'Andorra',
    alpha_2: 'AD',
    'country-code': '020',
  },
  {
    name: 'Angola',
    alpha_2: 'AO',
    'country-code': '024',
  },
  {
    name: 'Anguilla',
    alpha_2: 'AI',
    'country-code': '660',
  },
  {
    name: 'Antarctica',
    alpha_2: 'AQ',
    'country-code': '010',
  },
  {
    name: 'Antigua and Barbuda',
    alpha_2: 'AG',
    'country-code': '028',
  },
  {
    name: 'Argentina',
    alpha_2: 'AR',
    'country-code': '032',
  },
  {
    name: 'Armenia',
    alpha_2: 'AM',
    'country-code': '051',
  },
  {
    name: 'Aruba',
    alpha_2: 'AW',
    'country-code': '533',
  },
  {
    name: 'Australia',
    alpha_2: 'AU',
    'country-code': '036',
  },
  {
    name: 'Austria',
    alpha_2: 'AT',
    'country-code': '040',
  },
  {
    name: 'Azerbaijan',
    alpha_2: 'AZ',
    'country-code': '031',
  },
  {
    name: 'Bahamas',
    alpha_2: 'BS',
    'country-code': '044',
  },
  {
    name: 'Bahrain',
    alpha_2: 'BH',
    'country-code': '048',
  },
  {
    name: 'Bangladesh',
    alpha_2: 'BD',
    'country-code': '050',
  },
  {
    name: 'Barbados',
    alpha_2: 'BB',
    'country-code': '052',
  },
  {
    name: 'Belarus',
    alpha_2: 'BY',
    'country-code': '112',
  },
  {
    name: 'Belgium',
    alpha_2: 'BE',
    'country-code': '056',
  },
  {
    name: 'Belize',
    alpha_2: 'BZ',
    'country-code': '084',
  },
  {
    name: 'Benin',
    alpha_2: 'BJ',
    'country-code': '204',
  },
  {
    name: 'Bermuda',
    alpha_2: 'BM',
    'country-code': '060',
  },
  {
    name: 'Bhutan',
    alpha_2: 'BT',
    'country-code': '064',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha_2: 'BO',
    'country-code': '068',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha_2: 'BQ',
    'country-code': '535',
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha_2: 'BA',
    'country-code': '070',
  },
  {
    name: 'Botswana',
    alpha_2: 'BW',
    'country-code': '072',
  },
  {
    name: 'Bouvet Island',
    alpha_2: 'BV',
    'country-code': '074',
  },
  {
    name: 'Brazil',
    alpha_2: 'BR',
    'country-code': '076',
  },
  {
    name: 'British Indian Ocean Territory',
    alpha_2: 'IO',
    'country-code': '086',
  },
  {
    name: 'Brunei Darussalam',
    alpha_2: 'BN',
    'country-code': '096',
  },
  {
    name: 'Bulgaria',
    alpha_2: 'BG',
    'country-code': '100',
  },
  {
    name: 'Burkina Faso',
    alpha_2: 'BF',
    'country-code': '854',
  },
  {
    name: 'Burundi',
    alpha_2: 'BI',
    'country-code': '108',
  },
  {
    name: 'Cabo Verde',
    alpha_2: 'CV',
    'country-code': '132',
  },
  {
    name: 'Cambodia',
    alpha_2: 'KH',
    'country-code': '116',
  },
  {
    name: 'Cameroon',
    alpha_2: 'CM',
    'country-code': '120',
  },
  {
    name: 'Canada',
    alpha_2: 'CA',
    'country-code': '124',
  },
  {
    name: 'Cayman Islands',
    alpha_2: 'KY',
    'country-code': '136',
  },
  {
    name: 'Central African Republic',
    alpha_2: 'CF',
    'country-code': '140',
  },
  {
    name: 'Chad',
    alpha_2: 'TD',
    'country-code': '148',
  },
  {
    name: 'Chile',
    alpha_2: 'CL',
    'country-code': '152',
  },
  {
    name: 'China',
    alpha_2: 'CN',
    'country-code': '156',
  },
  {
    name: 'Christmas Island',
    alpha_2: 'CX',
    'country-code': '162',
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha_2: 'CC',
    'country-code': '166',
  },
  {
    name: 'Colombia',
    alpha_2: 'CO',
    'country-code': '170',
  },
  {
    name: 'Comoros',
    alpha_2: 'KM',
    'country-code': '174',
  },
  {
    name: 'Congo',
    alpha_2: 'CG',
    'country-code': '178',
  },
  {
    name: 'Congo, Democratic Republic of the',
    alpha_2: 'CD',
    'country-code': '180',
  },
  {
    name: 'Cook Islands',
    alpha_2: 'CK',
    'country-code': '184',
  },
  {
    name: 'Costa Rica',
    alpha_2: 'CR',
    'country-code': '188',
  },
  {
    name: "Côte d'Ivoire",
    alpha_2: 'CI',
    'country-code': '384',
  },
  {
    name: 'Croatia',
    alpha_2: 'HR',
    'country-code': '191',
  },
  {
    name: 'Cuba',
    alpha_2: 'CU',
    'country-code': '192',
  },
  {
    name: 'Curaçao',
    alpha_2: 'CW',
    'country-code': '531',
  },
  {
    name: 'Cyprus',
    alpha_2: 'CY',
    'country-code': '196',
  },
  {
    name: 'Czechia',
    alpha_2: 'CZ',
    'country-code': '203',
  },
  {
    name: 'Denmark',
    alpha_2: 'DK',
    'country-code': '208',
  },
  {
    name: 'Djibouti',
    alpha_2: 'DJ',
    'country-code': '262',
  },
  {
    name: 'Dominica',
    alpha_2: 'DM',
    'country-code': '212',
  },
  {
    name: 'Dominican Republic',
    alpha_2: 'DO',
    'country-code': '214',
  },
  {
    name: 'Ecuador',
    alpha_2: 'EC',
    'country-code': '218',
  },
  {
    name: 'Egypt',
    alpha_2: 'EG',
    'country-code': '818',
  },
  {
    name: 'El Salvador',
    alpha_2: 'SV',
    'country-code': '222',
  },
  {
    name: 'Equatorial Guinea',
    alpha_2: 'GQ',
    'country-code': '226',
  },
  {
    name: 'Eritrea',
    alpha_2: 'ER',
    'country-code': '232',
  },
  {
    name: 'Estonia',
    alpha_2: 'EE',
    'country-code': '233',
  },
  {
    name: 'Eswatini',
    alpha_2: 'SZ',
    'country-code': '748',
  },
  {
    name: 'Ethiopia',
    alpha_2: 'ET',
    'country-code': '231',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha_2: 'FK',
    'country-code': '238',
  },
  {
    name: 'Faroe Islands',
    alpha_2: 'FO',
    'country-code': '234',
  },
  {
    name: 'Fiji',
    alpha_2: 'FJ',
    'country-code': '242',
  },
  {
    name: 'Finland',
    alpha_2: 'FI',
    'country-code': '246',
  },
  {
    name: 'France',
    alpha_2: 'FR',
    'country-code': '250',
  },
  {
    name: 'French Guiana',
    alpha_2: 'GF',
    'country-code': '254',
  },
  {
    name: 'French Polynesia',
    alpha_2: 'PF',
    'country-code': '258',
  },
  {
    name: 'French Southern Territories',
    alpha_2: 'TF',
    'country-code': '260',
  },
  {
    name: 'Gabon',
    alpha_2: 'GA',
    'country-code': '266',
  },
  {
    name: 'Gambia',
    alpha_2: 'GM',
    'country-code': '270',
  },
  {
    name: 'Georgia',
    alpha_2: 'GE',
    'country-code': '268',
  },
  {
    name: 'Germany',
    alpha_2: 'DE',
    'country-code': '276',
  },
  {
    name: 'Ghana',
    alpha_2: 'GH',
    'country-code': '288',
  },
  {
    name: 'Gibraltar',
    alpha_2: 'GI',
    'country-code': '292',
  },
  {
    name: 'Greece',
    alpha_2: 'GR',
    'country-code': '300',
  },
  {
    name: 'Greenland',
    alpha_2: 'GL',
    'country-code': '304',
  },
  {
    name: 'Grenada',
    alpha_2: 'GD',
    'country-code': '308',
  },
  {
    name: 'Guadeloupe',
    alpha_2: 'GP',
    'country-code': '312',
  },
  {
    name: 'Guam',
    alpha_2: 'GU',
    'country-code': '316',
  },
  {
    name: 'Guatemala',
    alpha_2: 'GT',
    'country-code': '320',
  },
  {
    name: 'Guernsey',
    alpha_2: 'GG',
    'country-code': '831',
  },
  {
    name: 'Guinea',
    alpha_2: 'GN',
    'country-code': '324',
  },
  {
    name: 'Guinea-Bissau',
    alpha_2: 'GW',
    'country-code': '624',
  },
  {
    name: 'Guyana',
    alpha_2: 'GY',
    'country-code': '328',
  },
  {
    name: 'Haiti',
    alpha_2: 'HT',
    'country-code': '332',
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha_2: 'HM',
    'country-code': '334',
  },
  {
    name: 'Holy See',
    alpha_2: 'VA',
    'country-code': '336',
  },
  {
    name: 'Honduras',
    alpha_2: 'HN',
    'country-code': '340',
  },
  {
    name: 'Hong Kong',
    alpha_2: 'HK',
    'country-code': '344',
  },
  {
    name: 'Hungary',
    alpha_2: 'HU',
    'country-code': '348',
  },
  {
    name: 'Iceland',
    alpha_2: 'IS',
    'country-code': '352',
  },
  {
    name: 'India',
    alpha_2: 'IN',
    'country-code': '356',
  },
  {
    name: 'Indonesia',
    alpha_2: 'ID',
    'country-code': '360',
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha_2: 'IR',
    'country-code': '364',
  },
  {
    name: 'Iraq',
    alpha_2: 'IQ',
    'country-code': '368',
  },
  {
    name: 'Ireland',
    alpha_2: 'IE',
    'country-code': '372',
  },
  {
    name: 'Isle of Man',
    alpha_2: 'IM',
    'country-code': '833',
  },
  {
    name: 'Israel',
    alpha_2: 'IL',
    'country-code': '376',
  },
  {
    name: 'Italy',
    alpha_2: 'IT',
    'country-code': '380',
  },
  {
    name: 'Jamaica',
    alpha_2: 'JM',
    'country-code': '388',
  },
  {
    name: 'Japan',
    alpha_2: 'JP',
    'country-code': '392',
  },
  {
    name: 'Jersey',
    alpha_2: 'JE',
    'country-code': '832',
  },
  {
    name: 'Jordan',
    alpha_2: 'JO',
    'country-code': '400',
  },
  {
    name: 'Kazakhstan',
    alpha_2: 'KZ',
    'country-code': '398',
  },
  {
    name: 'Kenya',
    alpha_2: 'KE',
    'country-code': '404',
  },
  {
    name: 'Kiribati',
    alpha_2: 'KI',
    'country-code': '296',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha_2: 'KP',
    'country-code': '408',
  },
  {
    name: 'Korea, Republic of',
    alpha_2: 'KR',
    'country-code': '410',
  },
  {
    name: 'Kuwait',
    alpha_2: 'KW',
    'country-code': '414',
  },
  {
    name: 'Kyrgyzstan',
    alpha_2: 'KG',
    'country-code': '417',
  },
  {
    name: "Lao People's Democratic Republic",
    alpha_2: 'LA',
    'country-code': '418',
  },
  {
    name: 'Latvia',
    alpha_2: 'LV',
    'country-code': '428',
  },
  {
    name: 'Lebanon',
    alpha_2: 'LB',
    'country-code': '422',
  },
  {
    name: 'Lesotho',
    alpha_2: 'LS',
    'country-code': '426',
  },
  {
    name: 'Liberia',
    alpha_2: 'LR',
    'country-code': '430',
  },
  {
    name: 'Libya',
    alpha_2: 'LY',
    'country-code': '434',
  },
  {
    name: 'Liechtenstein',
    alpha_2: 'LI',
    'country-code': '438',
  },
  {
    name: 'Lithuania',
    alpha_2: 'LT',
    'country-code': '440',
  },
  {
    name: 'Luxembourg',
    alpha_2: 'LU',
    'country-code': '442',
  },
  {
    name: 'Macao',
    alpha_2: 'MO',
    'country-code': '446',
  },
  {
    name: 'Madagascar',
    alpha_2: 'MG',
    'country-code': '450',
  },
  {
    name: 'Malawi',
    alpha_2: 'MW',
    'country-code': '454',
  },
  {
    name: 'Malaysia',
    alpha_2: 'MY',
    'country-code': '458',
  },
  {
    name: 'Maldives',
    alpha_2: 'MV',
    'country-code': '462',
  },
  {
    name: 'Mali',
    alpha_2: 'ML',
    'country-code': '466',
  },
  {
    name: 'Malta',
    alpha_2: 'MT',
    'country-code': '470',
  },
  {
    name: 'Marshall Islands',
    alpha_2: 'MH',
    'country-code': '584',
  },
  {
    name: 'Martinique',
    alpha_2: 'MQ',
    'country-code': '474',
  },
  {
    name: 'Mauritania',
    alpha_2: 'MR',
    'country-code': '478',
  },
  {
    name: 'Mauritius',
    alpha_2: 'MU',
    'country-code': '480',
  },
  {
    name: 'Mayotte',
    alpha_2: 'YT',
    'country-code': '175',
  },
  {
    name: 'Mexico',
    alpha_2: 'MX',
    'country-code': '484',
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha_2: 'FM',
    'country-code': '583',
  },
  {
    name: 'Moldova, Republic of',
    alpha_2: 'MD',
    'country-code': '498',
  },
  {
    name: 'Monaco',
    alpha_2: 'MC',
    'country-code': '492',
  },
  {
    name: 'Mongolia',
    alpha_2: 'MN',
    'country-code': '496',
  },
  {
    name: 'Montenegro',
    alpha_2: 'ME',
    'country-code': '499',
  },
  {
    name: 'Montserrat',
    alpha_2: 'MS',
    'country-code': '500',
  },
  {
    name: 'Morocco',
    alpha_2: 'MA',
    'country-code': '504',
  },
  {
    name: 'Mozambique',
    alpha_2: 'MZ',
    'country-code': '508',
  },
  {
    name: 'Myanmar',
    alpha_2: 'MM',
    'country-code': '104',
  },
  {
    name: 'Namibia',
    alpha_2: 'NA',
    'country-code': '516',
  },
  {
    name: 'Nauru',
    alpha_2: 'NR',
    'country-code': '520',
  },
  {
    name: 'Nepal',
    alpha_2: 'NP',
    'country-code': '524',
  },
  {
    name: 'Netherlands',
    alpha_2: 'NL',
    'country-code': '528',
  },
  {
    name: 'New Caledonia',
    alpha_2: 'NC',
    'country-code': '540',
  },
  {
    name: 'New Zealand',
    alpha_2: 'NZ',
    'country-code': '554',
  },
  {
    name: 'Nicaragua',
    alpha_2: 'NI',
    'country-code': '558',
  },
  {
    name: 'Niger',
    alpha_2: 'NE',
    'country-code': '562',
  },
  {
    name: 'Nigeria',
    alpha_2: 'NG',
    'country-code': '566',
  },
  {
    name: 'Niue',
    alpha_2: 'NU',
    'country-code': '570',
  },
  {
    name: 'Norfolk Island',
    alpha_2: 'NF',
    'country-code': '574',
  },
  {
    name: 'North Macedonia',
    alpha_2: 'MK',
    'country-code': '807',
  },
  {
    name: 'Northern Mariana Islands',
    alpha_2: 'MP',
    'country-code': '580',
  },
  {
    name: 'Norway',
    alpha_2: 'NO',
    'country-code': '578',
  },
  {
    name: 'Oman',
    alpha_2: 'OM',
    'country-code': '512',
  },
  {
    name: 'Pakistan',
    alpha_2: 'PK',
    'country-code': '586',
  },
  {
    name: 'Palau',
    alpha_2: 'PW',
    'country-code': '585',
  },
  {
    name: 'Palestine, State of',
    alpha_2: 'PS',
    'country-code': '275',
  },
  {
    name: 'Panama',
    alpha_2: 'PA',
    'country-code': '591',
  },
  {
    name: 'Papua New Guinea',
    alpha_2: 'PG',
    'country-code': '598',
  },
  {
    name: 'Paraguay',
    alpha_2: 'PY',
    'country-code': '600',
  },
  {
    name: 'Peru',
    alpha_2: 'PE',
    'country-code': '604',
  },
  {
    name: 'Philippines',
    alpha_2: 'PH',
    'country-code': '608',
  },
  {
    name: 'Pitcairn',
    alpha_2: 'PN',
    'country-code': '612',
  },
  {
    name: 'Poland',
    alpha_2: 'PL',
    'country-code': '616',
  },
  {
    name: 'Portugal',
    alpha_2: 'PT',
    'country-code': '620',
  },
  {
    name: 'Puerto Rico',
    alpha_2: 'PR',
    'country-code': '630',
  },
  {
    name: 'Qatar',
    alpha_2: 'QA',
    'country-code': '634',
  },
  {
    name: 'Réunion',
    alpha_2: 'RE',
    'country-code': '638',
  },
  {
    name: 'Romania',
    alpha_2: 'RO',
    'country-code': '642',
  },
  {
    name: 'Russian Federation',
    alpha_2: 'RU',
    'country-code': '643',
  },
  {
    name: 'Rwanda',
    alpha_2: 'RW',
    'country-code': '646',
  },
  {
    name: 'Saint Barthélemy',
    alpha_2: 'BL',
    'country-code': '652',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha_2: 'SH',
    'country-code': '654',
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha_2: 'KN',
    'country-code': '659',
  },
  {
    name: 'Saint Lucia',
    alpha_2: 'LC',
    'country-code': '662',
  },
  {
    name: 'Saint Martin (French part)',
    alpha_2: 'MF',
    'country-code': '663',
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha_2: 'PM',
    'country-code': '666',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha_2: 'VC',
    'country-code': '670',
  },
  {
    name: 'Samoa',
    alpha_2: 'WS',
    'country-code': '882',
  },
  {
    name: 'San Marino',
    alpha_2: 'SM',
    'country-code': '674',
  },
  {
    name: 'Sao Tome and Principe',
    alpha_2: 'ST',
    'country-code': '678',
  },
  {
    name: 'Saudi Arabia',
    alpha_2: 'SA',
    'country-code': '682',
  },
  {
    name: 'Senegal',
    alpha_2: 'SN',
    'country-code': '686',
  },
  {
    name: 'Serbia',
    alpha_2: 'RS',
    'country-code': '688',
  },
  {
    name: 'Seychelles',
    alpha_2: 'SC',
    'country-code': '690',
  },
  {
    name: 'Sierra Leone',
    alpha_2: 'SL',
    'country-code': '694',
  },
  {
    name: 'Singapore',
    alpha_2: 'SG',
    'country-code': '702',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha_2: 'SX',
    'country-code': '534',
  },
  {
    name: 'Slovakia',
    alpha_2: 'SK',
    'country-code': '703',
  },
  {
    name: 'Slovenia',
    alpha_2: 'SI',
    'country-code': '705',
  },
  {
    name: 'Solomon Islands',
    alpha_2: 'SB',
    'country-code': '090',
  },
  {
    name: 'Somalia',
    alpha_2: 'SO',
    'country-code': '706',
  },
  {
    name: 'South Africa',
    alpha_2: 'ZA',
    'country-code': '710',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha_2: 'GS',
    'country-code': '239',
  },
  {
    name: 'South Sudan',
    alpha_2: 'SS',
    'country-code': '728',
  },
  {
    name: 'Spain',
    alpha_2: 'ES',
    'country-code': '724',
  },
  {
    name: 'Sri Lanka',
    alpha_2: 'LK',
    'country-code': '144',
  },
  {
    name: 'Sudan',
    alpha_2: 'SD',
    'country-code': '729',
  },
  {
    name: 'Suriname',
    alpha_2: 'SR',
    'country-code': '740',
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha_2: 'SJ',
    'country-code': '744',
  },
  {
    name: 'Sweden',
    alpha_2: 'SE',
    'country-code': '752',
  },
  {
    name: 'Switzerland',
    alpha_2: 'CH',
    'country-code': '756',
  },
  {
    name: 'Syrian Arab Republic',
    alpha_2: 'SY',
    'country-code': '760',
  },
  {
    name: 'Taiwan, Province of China',
    alpha_2: 'TW',
    'country-code': '158',
  },
  {
    name: 'Tajikistan',
    alpha_2: 'TJ',
    'country-code': '762',
  },
  {
    name: 'Tanzania, United Republic of',
    alpha_2: 'TZ',
    'country-code': '834',
  },
  {
    name: 'Thailand',
    alpha_2: 'TH',
    'country-code': '764',
  },
  {
    name: 'Timor-Leste',
    alpha_2: 'TL',
    'country-code': '626',
  },
  {
    name: 'Togo',
    alpha_2: 'TG',
    'country-code': '768',
  },
  {
    name: 'Tokelau',
    alpha_2: 'TK',
    'country-code': '772',
  },
  {
    name: 'Tonga',
    alpha_2: 'TO',
    'country-code': '776',
  },
  {
    name: 'Trinidad and Tobago',
    alpha_2: 'TT',
    'country-code': '780',
  },
  {
    name: 'Tunisia',
    alpha_2: 'TN',
    'country-code': '788',
  },
  {
    name: 'Turkey',
    alpha_2: 'TR',
    'country-code': '792',
  },
  {
    name: 'Turkmenistan',
    alpha_2: 'TM',
    'country-code': '795',
  },
  {
    name: 'Turks and Caicos Islands',
    alpha_2: 'TC',
    'country-code': '796',
  },
  {
    name: 'Tuvalu',
    alpha_2: 'TV',
    'country-code': '798',
  },
  {
    name: 'Uganda',
    alpha_2: 'UG',
    'country-code': '800',
  },
  {
    name: 'Ukraine',
    alpha_2: 'UA',
    'country-code': '804',
  },
  {
    name: 'United Arab Emirates',
    alpha_2: 'AE',
    'country-code': '784',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha_2: 'GB',
    'country-code': '826',
  },
  {
    name: 'United States of America',
    alpha_2: 'US',
    'country-code': '840',
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha_2: 'UM',
    'country-code': '581',
  },
  {
    name: 'Uruguay',
    alpha_2: 'UY',
    'country-code': '858',
  },
  {
    name: 'Uzbekistan',
    alpha_2: 'UZ',
    'country-code': '860',
  },
  {
    name: 'Vanuatu',
    alpha_2: 'VU',
    'country-code': '548',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha_2: 'VE',
    'country-code': '862',
  },
  {
    name: 'Viet Nam',
    alpha_2: 'VN',
    'country-code': '704',
  },
  {
    name: 'Virgin Islands (British)',
    alpha_2: 'VG',
    'country-code': '092',
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha_2: 'VI',
    'country-code': '850',
  },
  {
    name: 'Wallis and Futuna',
    alpha_2: 'WF',
    'country-code': '876',
  },
  {
    name: 'Western Sahara',
    alpha_2: 'EH',
    'country-code': '732',
  },
  {
    name: 'Yemen',
    alpha_2: 'YE',
    'country-code': '887',
  },
  {
    name: 'Zambia',
    alpha_2: 'ZM',
    'country-code': '894',
  },
  {
    name: 'Zimbabwe',
    alpha_2: 'ZW',
    'country-code': '716',
  },
];
