import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { formatCurrency, formatLocaleDateTime } from 'assets/utils';
import React from 'react';
import { TransactionStatus, capitalize } from './TransactionStatus';
import TransactionLabel, { txnType } from './TransactionLabel';
import { TransactionAmount } from './TransactionAmount';
import { tirnuModalBg } from 'jsx/pages/Accounts/AccountTopup';
import { internationalCountries } from 'jsx/pages/SendMoney/SendMoneyTab/International';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransactionInfo = ({
  open,
  handleClose,
  txnInfo,
  isFees,
  activeIBAN,
  hideAmt,
}) => {
  if (!txnInfo) {
    return null;
  }

  const {
    _id: transaction_id,
    transaction_type,
    status: transaction_status,
    createdAt: txn_date_time,
    transaction_meta,
    transaction_fees,
    amount,
    overseas_receiver,
  } = txnInfo;
  const receiverName = txnInfo.receiver_details.user.name;
  const receiverAcc = txnInfo.receiver_details?.account_number;
  const receiverIBAN = txnInfo.transaction_meta?.receiver_account?.iban;
  const senderName = txnInfo.sender_details.name;
  const senderIBAN = txnInfo.transaction_meta?.sender_account?.iban;

  const isReceiver =
    [receiverAcc, receiverIBAN].includes(activeIBAN) &&
    ![txnType.BUY_CRYPTO, txnType.LOAD_MONEY].includes(transaction_type);

  const isRefund = receiverName === 'Refunded Crypto Amount';
  const isPositive =
    isReceiver ||
    ['LOAD_MONEY', 'SELL_CRYPTO_TRANSACTION'].includes(transaction_type);

  const conversionCurrency = internationalCountries.find(
    (country) => country.value === overseas_receiver.transferToCountry
  )?.currency;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="txn-description"
      PaperProps={{
        sx: {
          borderRadius: 2.5,
          boxShadow: 'none',
        },
      }}
      sx={{
        background: tirnuModalBg,
      }}
    >
      <DialogContent>
        <Stack gap={2}>
          <Grid container gap={1} textAlign="center">
            {!hideAmt && (
              <Grid item tablet={12}>
                <TransactionAmount
                  isLargeFont
                  isCenter
                  amount={isFees ? transaction_fees?.feeAmount : amount}
                  fee={0}
                  isPositive={isFees ? false : isPositive}
                  transaction_type={transaction_type}
                  transaction_status={transaction_status}
                />
              </Grid>
            )}
            <Grid item tablet={12}>
              <Stack>
                {!hideAmt && (
                  <TransactionStatus
                    transaction_meta={transaction_meta}
                    transaction_status={
                      isFees
                        ? capitalize(transaction_fees.status)
                        : transaction_status
                    }
                  />
                )}
                <Typography fontSize={14}>
                  {formatLocaleDateTime(
                    isFees ? transaction_fees.createdAt : txn_date_time
                  )}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Divider />

          <Grid container gap={1}>
            <Grid item tablet={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">From: </Typography>
                <Typography noWrap>{senderName}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end">
                <Typography fontSize={14}>{senderIBAN}</Typography>
              </Stack>
            </Grid>
            <Grid item tablet={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">To: </Typography>
                <Typography noWrap>
                  {transaction_type === txnType.TXN_INTERNATIONAL
                    ? overseas_receiver.transferToAccountName
                    : receiverName}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end">
                {transaction_type !== txnType.TXN_INTERNATIONAL ? (
                  <Typography fontSize={14}>{receiverIBAN}</Typography>
                ) : (
                  <Stack direction="column" alignItems="end" width="100%">
                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Bank: </Typography>
                      <Typography>
                        {overseas_receiver.transferToBank?.name}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">A/C No.: </Typography>
                      <Typography>{overseas_receiver.transferTo}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Amount: </Typography>
                      <Typography>{formatCurrency(amount)}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Exchange Rate: </Typography>
                      <Typography>
                        {conversionCurrency +
                          ' ' +
                          overseas_receiver.transferConversionRate[
                            '$numberDecimal'
                          ]}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">
                        Conversion Amount:{' '}
                      </Typography>
                      <Typography>
                        {conversionCurrency +
                          ' ' +
                          overseas_receiver.transferConversionAmount[
                            '$numberDecimal'
                          ]}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Contact: </Typography>
                      <Typography>
                        {overseas_receiver.transferToNumber
                          ? '+' + overseas_receiver.transferToNumber
                          : '-'}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Code: </Typography>
                      <Typography>{overseas_receiver.code}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">
                        Fulfillment Status:
                      </Typography>
                      <Typography>{overseas_receiver.status}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Type: </Typography>
                      <Typography>
                        {overseas_receiver.transferType === 'bank' &&
                          'Bank Transfer'}
                        {overseas_receiver.transferType === 'pickup' &&
                          'Cash Pickup'}
                        {!overseas_receiver.transferType && '-'}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Agent: </Typography>
                      <Typography>
                        {overseas_receiver.fulfillAgent
                          ? overseas_receiver.fulfillAgent?.full_name
                              .first_name +
                            ' ' +
                            overseas_receiver.fulfillAgent?.full_name.last_name
                          : '-'}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>

          <Divider />

          <Grid container gap={1}>
            <Grid item tablet={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">Reference: </Typography>
                <Typography>
                  {transaction_meta?.reference || txnInfo?.description || ' - '}
                </Typography>
              </Stack>
            </Grid>
            {!hideAmt && (
              <Grid item tablet={12}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontWeight="bold">Type: </Typography>
                  <TransactionLabel
                    type={isFees ? txnType.FEES : transaction_type}
                    isReceiver={isReceiver}
                    isRefund={isRefund}
                  />
                </Stack>
              </Grid>
            )}
          </Grid>

          <Divider />

          <Grid container gap={1}>
            <Grid item tablet={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">Transaction ID: </Typography>
                <Typography>{transaction_id}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionInfo;
