import { ThemeProvider } from '@mui/material/styles';
import BasicModal from 'jsx/components/BasicModal/BasicModal';
import DotsLoader from 'jsx/components/Loader/DotsLoader';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { theme } from 'theme/theme';

import App from './App';
import ThemeContext from './context/ThemeContext';
import { HeaderContextProvider } from './jsx/layouts/nav/Header';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './store/store';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      // staleTime: 0,
      staleTime: 1000 * 60 * 0.5, // 30 secs
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SimpleReactLightbox>
          <BrowserRouter basename="/">
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<DotsLoader />}>
                <ThemeContext>
                  <HeaderContextProvider>
                    <BasicModal />
                    <App />
                  </HeaderContextProvider>
                </ThemeContext>
              </Suspense>
            </QueryClientProvider>
          </BrowserRouter>
        </SimpleReactLightbox>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// TODO: Re-enable
// serviceWorkerRegistration.register({
//   onUpdate: async (registration) => {
//     // We want to run this code only if we detect a new service worker is
//     // waiting to be activated.
//     // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//     if (registration && registration.waiting) {
//       await registration.unregister();
//       // Makes Workbox call skipWaiting()
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//       // Once the service worker is unregistered, we can reload the page to let
//       // the browser download a fresh copy of our app (invalidating the cache)
//       window.location.reload();
//     }
//   },
// });

serviceWorkerRegistration.unregister();
