export const breakpoints = {
  values: {
    mobile: 460,
    tabletS: 768,
    tablet: 1024,
    laptop: 1280,
    desktop: 1441,
    l: 1537,
    xl: 1921,
    xxl: 2561,
  },
};
