// import {
//   LOADING_TOGGLE_ACTION,
//   LOGIN_CONFIRMED_ACTION,
//   LOGIN_FAILED_ACTION,
//   LOGOUT_ACTION,
//   SIGNUP_CONFIRMED_ACTION,
//   SIGNUP_FAILED_ACTION,
//   FORGOT_PASSWORD_ACTION,
// } from '../actions/AuthActions';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const FORGOT_PASSWORD_ACTION = '[ForgotPassword action] forgot password';
export const RESET_PASSWORD_ACTION = '[ResetPassword action] reset password';
export const VERIFY_ACCOUNT_ACTION = '[Verify Account action] verify account';
export const ADMIN_LOGIN = 'ADMIN_LOGIN';

const initialState = {
  auth: {
    accessToken: '',
    refreshToken: '',
  },
  errorMessage: '',
  successMessage: '',
  showLoading: false,
  adminResponse: null,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Signup Successfully Completed',
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Login Successfully Completed',
      showLoading: false,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: '',
      successMessage: '',
      auth: {
        accessToken: '',
        refreshToken: '',
      },
    };
  }

  if (
    action.type === SIGNUP_FAILED_ACTION ||
    action.type === LOGIN_FAILED_ACTION
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: '',
      showLoading: false,
    };
  }

  if (action.type === FORGOT_PASSWORD_ACTION) {
    return {
      ...state,
      forgotPassword: true,
      showLoading: action.payload,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  if (action.type === ADMIN_LOGIN) {
    return {
      ...state,
      adminResponse: action.payload,
    };
  }
  return state;
}
