import axios from 'axios';
import { store } from '../store/store';

const THREE_MINUTES = 3 * 60 * 1000;

const TirnuClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  timeout: THREE_MINUTES,
});

TirnuClient.interceptors.request.use((config) => {
  try {
    const token = store.getState().auth.auth.accessToken;

    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
});

export { TirnuClient };
