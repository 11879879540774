import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const MainContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const InnerContainer = styled(Box)(({ isError, theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: '27.44rem',
  minHeight: '16.38rem',
  border: `0.031rem solid ${
    isError ? theme.palette.error.main : 'rgba(120, 140, 165, 0.5)'
  }`,
  borderRadius: '1.25rem',
  padding: '1.56rem 1.25rem 1.375rem 1.375rem',
}));

export const SchdeulePaymentDatePicker = styled(Box)(() => ({
  input: {
    width: '100%',
    height: '50px',
    borderRadius: 4,
    paddingLeft: '15px',
    border: '1px solid #ccc5c5',
  },
}));
export const TransferRowBlock = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '1.375rem',
}));

export const ResultRowBlock = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '0.812rem',
}));

export const Divider = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `0.025rem solid ${theme.palette.grey500.main}`,
  marginTop: '1.25rem',
}));

export const TransferInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.black400.main,
}));

export const Actions = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  maxWidth: '24rem',
  justifyContent: 'space-between',
  marginTop: '1.25rem',
}));

export const Change = styled(Box)(({ theme }) => ({
  color: theme.palette.green500.main,
  cursor: 'pointer',
  '&:hover': {
    opacity: '0.9',
  },
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: '0.5rem',
}));
