import { Box, Typography } from '@mui/material';
import SendMoneySuccsess from 'images/sendMoney/SendMoneySuccsess';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

const ResetPinSuccessModal = (content) => {
  const consentVerificationModal = () => {
    window.location.reload();
    localStorage.removeItem('userDetails');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box mt="1.25rem" mb="1.56rem">
        <SendMoneySuccsess />
      </Box>
      <Typography variant="paymentSent" mb="0.69rem">
        Pin successfully reset!
      </Typography>
      <div>
        <Button
          onClick={consentVerificationModal}
          variant="contained"
          sx={{
            maxWidth: '11.38rem',
          }}
        >
          Close
        </Button>
      </div>
    </Box>
  );
};

export default ResetPinSuccessModal;
