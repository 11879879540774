import {
  SEND_INTERNATIONAL_ACTION,
  SEND_INTERNATIONAL_LOADING_ACTION,
} from './sendInternationalAction';

export function SendMoneyInternationalAction(payload) {
  return {
    type: SEND_INTERNATIONAL_ACTION,
    payload,
  };
}

export function SendMoneyInternationalLoadingAction(payload) {
  return {
    type: SEND_INTERNATIONAL_LOADING_ACTION,
    payload,
  };
}
