import {
  BETWEEN_ACCOUNT_ACTION,
  BETWEEN_ACC_LOADING_ACTION,
} from 'store/actions/SendMoneyMyOwnAccActions/sendBetweenAccAction';

const initialState = {
  betweenAccData: null,
  isLoading: false,
};

const sendBetweenAccReducer = (state = initialState, action) => {
  switch (action.type) {
    case BETWEEN_ACCOUNT_ACTION:
      return { betweenAccData: action.payload };
    case BETWEEN_ACC_LOADING_ACTION:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default sendBetweenAccReducer;
