import { styled } from '@mui/material';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

export const CustomSelect = styled(Select)(({ theme, selected, error }) => ({
  '& .MuiSelect-select': {
    border: error
      ? `0.0625rem solid ${theme.palette.error.main}`
      : selected
      ? `0.0625rem solid ${theme.palette.green500.main}`
      : `0.0625rem solid ${theme.palette.grey500.main}`,
    borderRadius: '0.625rem',
  },
  '& .MuiInputBase-input': {
    borderRadius: '0.625rem',
  },
  '& .MuiPopover-paper': {
    [theme.breakpoints.down('mobile')]: {
      minWidth: 'auto !important',
      maxWidth: '280px',
    },
  },
}));

export const SelectContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '24.06rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mb: '0.125rem',
}));
