import * as yup from 'yup';
import * as MESSAGES from 'constants/constants';

export const transferPasscodeVerificationSchema = yup.object().shape({
  passcode: yup
    .number(MESSAGES.CODE_FORMAT_MESSAGE)
    .typeError(MESSAGES.CODE_FORMAT_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
export const transferSMSVerificationSchema = yup.object().shape({
  sms: yup
    .number(MESSAGES.CODE_FORMAT_MESSAGE)
    .typeError(MESSAGES.CODE_FORMAT_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
export const pinResetSchema = yup.object().shape({
  passcode: yup
    .number(MESSAGES.CODE_FORMAT_MESSAGE)
    .typeError(MESSAGES.CODE_FORMAT_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  confirmPasscode: yup
    .number(MESSAGES.CODE_FORMAT_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE)
    .oneOf([yup.ref('passcode'), null], 'Passcodes must match'),
});
