export const CHANGE_MODAL_STATE = 'CHANGE_MODAL_STATE';

const initialState = {
  modalData: {
    isOpen: false,
    modalName: '',
    content: {},
  },
};

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MODAL_STATE:
      return { modalData: action.payload };

    default:
      return state;
  }
};

export default modalsReducer;
