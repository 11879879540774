import React, { useState, useRef, useMemo, Fragment } from 'react';

import ArrowDown from 'images/arrowdown.png';

import accountList from '../../Shared/account-list';
import {
  AccDropDownMenu,
  AccDropDownMenuHeader,
  AccDropDownMenuItem,
  AccDropDownMenuSearch,
  AccDropdownSelect,
  AccDropdownSelectArrow,
  AccDropdownWrapper,
} from '../../Accounts.styles';
import { useOnClickOutside } from 'assets/utils/Hooks';

const AccountDropdown = ({ handleSelect, handleAssociation }) => {
  const headerRef = useRef(null);

  const [filterTerm, setFilterTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState('');

  const slectedAccountDetail = useMemo(
    () => accountList.find((acc) => acc.asset_type === selectedAccount),
    [selectedAccount]
  );

  const filteredAccountList = useMemo(
    () =>
      accountList.filter((acc) => acc.title.toLowerCase().includes(filterTerm)),
    [filterTerm]
  );

  const onAssociationUpdate = ({ target: { value } }) => {
    handleAssociation(value);
  };

  const onSelectAccount = (asset_type) => () => {
    handleSelect(asset_type);

    setSelectedAccount(asset_type);
    setIsMenuOpen(false);
  };

  useOnClickOutside(headerRef, () => {
    if (isMenuOpen) setIsMenuOpen(false);
  });

  return (
    <Fragment>
      <AccDropdownWrapper className="mb-3">
        <label className="fs-12 text-uppercase mb-1">Association</label>
        <div className="mb-1">
          <input
            type="text"
            className="form-control"
            placeholder="Example: Administration"
            onChange={onAssociationUpdate}
          />
        </div>
      </AccDropdownWrapper>

      <AccDropdownWrapper ref={headerRef}>
        <AccDropdownSelect onClick={() => setIsMenuOpen(true)} autoFocus>
          {selectedAccount ? (
            <div className="d-flex align-items-center">
              <img
                src={slectedAccountDetail.flag}
                alt={slectedAccountDetail.title}
              />
              {slectedAccountDetail.title}
            </div>
          ) : (
            'Choose currency...'
          )}

          <AccDropdownSelectArrow
            isMenuOpen={isMenuOpen}
            src={ArrowDown}
            alt="Arrow"
          />
        </AccDropdownSelect>

        {isMenuOpen && (
          <AccDropDownMenu
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="py-1 d-flex flex-column">
              <AccDropDownMenuSearch>
                <span>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    focusable="false"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.35 12.484a7 7 0 111.133-1.133l3.517 3.508L14.86 16l-3.51-3.515zM12.4 7A5.4 5.4 0 111.6 7a5.4 5.4 0 0110.8 0z"
                    ></path>
                  </svg>
                </span>
                <input
                  type="text"
                  autoFocus
                  className="form-control"
                  placeholder="Search..."
                  spellCheck="false"
                  value={filterTerm}
                  onChange={(e) => setFilterTerm(e.target.value)}
                />
              </AccDropDownMenuSearch>

              <div className="p-2">
                {!!filteredAccountList.length && (
                  <AccDropDownMenuHeader>
                    Currency accounts with account details
                  </AccDropDownMenuHeader>
                )}

                {filteredAccountList.length ? (
                  filteredAccountList.map((account) => (
                    <AccDropDownMenuItem
                      role="menuitem"
                      id={`account-${account.symbol}`}
                      key={`account-${account.symbol}`}
                      tabIndex={0}
                      onClick={onSelectAccount(account.asset_type)}
                    >
                      <img src={account.flag} alt="" />
                      {account.title}
                    </AccDropDownMenuItem>
                  ))
                ) : (
                  <AccDropDownMenuHeader className="text-center m-0">
                    No Accounts
                  </AccDropDownMenuHeader>
                )}
              </div>
            </div>
          </AccDropDownMenu>
        )}
      </AccDropdownWrapper>
    </Fragment>
  );
};

export default AccountDropdown;
