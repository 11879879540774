export const getErrorMessageFields = (errors) => {
  let errorMessage = '';
  try {
    errors?.forEach((error, index) => {
      if (index === errors?.length - 1) {
        errorMessage = errorMessage + error?.message;
      } else {
        errorMessage = errorMessage + error?.message + '\n';
      }
    });
  } catch (error) {
    console.log(error);
  }

  return errorMessage || '';
};
