import {
  TRANSFER_FROM_ACTION,
  RECIPIENTS_NAME_ACTION,
  TRANSFER_AMOUNT_ACTION,
  CURRENCY_ACTION,
} from 'store/actions/SendMoneyMyContactActions/SendMoneyMyContactActionsTypes';

const initialState = {
  transferFromData: null,
  recipientsData: null,
  transferAmount: null,
  currencyData: null,
};

const sendMoneyMyContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSFER_FROM_ACTION:
      return { ...state, transferFromData: action.payload };
    case RECIPIENTS_NAME_ACTION:
      return { ...state, recipientsData: action.payload };
    case TRANSFER_AMOUNT_ACTION:
      return { ...state, transferAmount: action.payload };
    case CURRENCY_ACTION:
      return { ...state, currencyData: action.payload };

    default:
      return state;
  }
};

export default sendMoneyMyContactReducer;
