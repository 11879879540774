import { cardStatus } from 'constants/cards';
import {
  SET_CARDS_DATA_ACTION,
  SET_SELECT_CARD_DATA_ACTION,
  CHANGE_CARD_STATUS,
} from 'store/actions/CardsActions';

const initialState = {
  cardsData: null,
  selectCard: null,
};

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CARDS_DATA_ACTION:
      const cardsDataTemp = action.payload?.data
        ?.filter((item) => {
          return item?.account_id === action.payload?.ledger_id;
        })
        ?.sort((a) => (a?.status === cardStatus.active ? -1 : 1));

      return {
        ...state,
        cardsData: cardsDataTemp || [],
        selectCard: cardsDataTemp[0],
      };

    case SET_SELECT_CARD_DATA_ACTION:
      return { ...state, selectCard: action.payload };

    case CHANGE_CARD_STATUS:
      const cardsData2 = [...state.cardsData];
      const selectCardTemp = { ...state.selectCard };
      cardsData2.forEach((item) => {
        if (item?.id === action.payload?.id) {
          item.status = action.payload?.status;
          selectCardTemp.status = action.payload?.status;
        }
      });
      return { ...state, selectCard: selectCardTemp, cardsData: cardsData2 };

    default:
      return state;
  }
};

export default cardsReducer;
