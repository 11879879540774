import CreditCardIcon from '@mui/icons-material/CreditCard';

export const CardsManagePaymentMethodModalItems = [
  {
    Icon: CreditCardIcon,
    text: 'ATM withdrawals',
    onSwitch: () => {},
    isChecked: true,
  },
  {
    Icon: CreditCardIcon,
    text: 'Online payments',
    onSwitch: () => {},
    isChecked: true,
  },
  {
    Icon: CreditCardIcon,
    text: 'Chip and PIN transactions',
    onSwitch: () => {},
    isChecked: true,
  },
  {
    Icon: CreditCardIcon,
    text: 'Mobile wallets',
    onSwitch: () => {},
    isChecked: true,
  },
  {
    Icon: CreditCardIcon,
    text: 'Contactless transactions',
    onSwitch: () => {},
    isChecked: true,
  },
  {
    Icon: CreditCardIcon,
    text: 'Magnetic stripe transactions',
    onSwitch: () => {},
    isChecked: true,
  },
];
