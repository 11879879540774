import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useField, useFormikContext } from 'formik';
import { InputLabel } from './styles';
import { capitalize } from 'utils/capitalize';

export const TextFieldFormik = ({
  name,
  label,
  placeholder,
  type = 'text',
  defaultValue,
  disabled,
  fullWidth,
  dashboard,
  max,
  rows,
  multiline,
  autoFocus,
}) => {
  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();
  const { palette } = useTheme();

  const hasError = Boolean(meta.error && meta.touched);

  return (
    <Box>
      {label && <InputLabel dashboard={dashboard}>{label}</InputLabel>}
      <TextField
        id={name}
        name={name}
        value={field.value}
        onChange={handleChange(name)}
        error={hasError}
        helperText={hasError ? meta.error : ''}
        placeholder={capitalize(placeholder)}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        disabled={disabled}
        type={type}
        InputProps={{ inputProps: { max: max } }}
        rows={rows}
        multiline={multiline}
        autoFocus={autoFocus}
        sx={{
          input: {
            color: hasError ? palette.error.main : palette.text.main,
            border: hasError
              ? `0.0625rem solid ${palette.error.main}`
              : dashboard && field.value
              ? `0.0625rem solid ${palette.green500.main}`
              : dashboard
              ? `0.0625rem solid ${palette.grey500.main}`
              : `0.0625rem solid ${palette.border.main}`,
            '&.MuiInputBase-input::placeholder': {
              color: dashboard ? palette.grey500.main : palette.border.main,
              fontSize: dashboard ? '1rem' : '0.875rem',
            },
          },
          textarea: {
            color: hasError ? palette.error.main : palette.text.main,
            border: hasError
              ? `0.0625rem solid ${palette.error.main}`
              : dashboard && field.value
              ? `0.0625rem solid ${palette.green500.main}`
              : dashboard
              ? `0.0625rem solid ${palette.grey500.main}`
              : `0.0625rem solid ${palette.border.main}`,
            '&.MuiInputBase-input::placeholder': {
              color: dashboard ? palette.grey500.main : palette.border.main,
              fontSize: dashboard ? '1rem' : '0.875rem',
            },
          },
        }}
      />
    </Box>
  );
};
