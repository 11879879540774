import { CHANGE_MODAL_STATE } from 'store/reducers/modalsReducer';

export function ModalCloseAction(payload) {
  return {
    type: CHANGE_MODAL_STATE,
    payload: {
      ...payload,
      isOpen: false,
    },
  };
}
