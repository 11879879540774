import afIcon from './svg/af.svg';
import axIcon from './svg/ax.svg';
import alIcon from './svg/al.svg';
import dzIcon from './svg/dz.svg';
import asIcon from './svg/as.svg';
import adIcon from './svg/ad.svg';
import aoIcon from './svg/ao.svg';
import aiIcon from './svg/ai.svg';
import aqIcon from './svg/aq.svg';
import agIcon from './svg/ag.svg';
import arIcon from './svg/ar.svg';
import amIcon from './svg/am.svg';
import awIcon from './svg/aw.svg';
import auIcon from './svg/au.svg';
import atIcon from './svg/at.svg';
import azIcon from './svg/az.svg';
import bsIcon from './svg/bs.svg';
import bhIcon from './svg/bh.svg';
import bdIcon from './svg/bd.svg';
import bbIcon from './svg/bb.svg';
import byIcon from './svg/by.svg';
import beIcon from './svg/be.svg';
import bzIcon from './svg/bz.svg';
import bjIcon from './svg/bj.svg';
import bmIcon from './svg/bm.svg';
import btIcon from './svg/bt.svg';
import boIcon from './svg/bo.svg';
import baIcon from './svg/ba.svg';
import bwIcon from './svg/bw.svg';
import bvIcon from './svg/bv.svg';
import brIcon from './svg/br.svg';
import ioIcon from './svg/io.svg';
import bnIcon from './svg/bn.svg';
import bgIcon from './svg/bg.svg';
import bfIcon from './svg/bf.svg';
import biIcon from './svg/bi.svg';
import khIcon from './svg/kh.svg';
import cmIcon from './svg/cm.svg';
import caIcon from './svg/ca.svg';
import cvIcon from './svg/cv.svg';
import kyIcon from './svg/ky.svg';
import cfIcon from './svg/cf.svg';
import tdIcon from './svg/td.svg';
import clIcon from './svg/cl.svg';
import cnIcon from './svg/cn.svg';
import cxIcon from './svg/cx.svg';
import ccIcon from './svg/cc.svg';
import coIcon from './svg/co.svg';
import kmIcon from './svg/km.svg';
import cgIcon from './svg/cg.svg';
import cdIcon from './svg/cd.svg';
import ckIcon from './svg/ck.svg';
import crIcon from './svg/cr.svg';
import ciIcon from './svg/ci.svg';
import hrIcon from './svg/hr.svg';
import cuIcon from './svg/cu.svg';
import cyIcon from './svg/cy.svg';
import czIcon from './svg/cz.svg';
import dkIcon from './svg/dk.svg';
import djIcon from './svg/dj.svg';
import dmIcon from './svg/dm.svg';
import doIcon from './svg/do.svg';
import ecIcon from './svg/ec.svg';
import egIcon from './svg/eg.svg';
import svIcon from './svg/sv.svg';
import gqIcon from './svg/gq.svg';
import erIcon from './svg/er.svg';
import eeIcon from './svg/ee.svg';
import etIcon from './svg/et.svg';
import fkIcon from './svg/fk.svg';
import foIcon from './svg/fo.svg';
import fjIcon from './svg/fj.svg';
import fiIcon from './svg/fi.svg';
import frIcon from './svg/fr.svg';
import gfIcon from './svg/gf.svg';
import pfIcon from './svg/pf.svg';
import tfIcon from './svg/tf.svg';
import gaIcon from './svg/ga.svg';
import gmIcon from './svg/gm.svg';
import geIcon from './svg/ge.svg';
import deIcon from './svg/de.svg';
import ghIcon from './svg/gh.svg';
import giIcon from './svg/gi.svg';
import grIcon from './svg/gr.svg';
import glIcon from './svg/gl.svg';
import gdIcon from './svg/gd.svg';
import gpIcon from './svg/gp.svg';
import guIcon from './svg/gu.svg';
import gtIcon from './svg/gt.svg';
import ggIcon from './svg/gg.svg';
import gnIcon from './svg/gn.svg';
import gwIcon from './svg/gw.svg';
import gyIcon from './svg/gy.svg';
import htIcon from './svg/ht.svg';
import hmIcon from './svg/hm.svg';
import vaIcon from './svg/va.svg';
import hnIcon from './svg/hn.svg';
import hkIcon from './svg/hk.svg';
import huIcon from './svg/hu.svg';
import isIcon from './svg/is.svg';
import inIcon from './svg/in.svg';
import idIcon from './svg/id.svg';
import irIcon from './svg/ir.svg';
import iqIcon from './svg/iq.svg';
import ieIcon from './svg/ie.svg';
import imIcon from './svg/im.svg';
import ilIcon from './svg/il.svg';
import itIcon from './svg/it.svg';
import jmIcon from './svg/jm.svg';
import jpIcon from './svg/jp.svg';
import jeIcon from './svg/je.svg';
import joIcon from './svg/jo.svg';
import kzIcon from './svg/kz.svg';
import keIcon from './svg/ke.svg';
import kiIcon from './svg/ki.svg';
import kpIcon from './svg/kp.svg';
import krIcon from './svg/kr.svg';
import xkIcon from './svg/xk.svg';
import kwIcon from './svg/kw.svg';
import kgIcon from './svg/kg.svg';
import laIcon from './svg/la.svg';
import lvIcon from './svg/lv.svg';
import lbIcon from './svg/lb.svg';
import lsIcon from './svg/ls.svg';
import lrIcon from './svg/lr.svg';
import lyIcon from './svg/ly.svg';
import liIcon from './svg/li.svg';
import ltIcon from './svg/lt.svg';
import luIcon from './svg/lu.svg';
import moIcon from './svg/mo.svg';
import mkIcon from './svg/mk.svg';
import mgIcon from './svg/mg.svg';
import mwIcon from './svg/mw.svg';
import myIcon from './svg/my.svg';
import mvIcon from './svg/mv.svg';
import mlIcon from './svg/ml.svg';
import mtIcon from './svg/mt.svg';
import mhIcon from './svg/mh.svg';
import mqIcon from './svg/mq.svg';
import mrIcon from './svg/mr.svg';
import muIcon from './svg/mu.svg';
import ytIcon from './svg/yt.svg';
import mxIcon from './svg/mx.svg';
import fmIcon from './svg/fm.svg';
import mdIcon from './svg/md.svg';
import mcIcon from './svg/mc.svg';
import mnIcon from './svg/mn.svg';
import meIcon from './svg/me.svg';
import msIcon from './svg/ms.svg';
import maIcon from './svg/ma.svg';
import mzIcon from './svg/mz.svg';
import mmIcon from './svg/mm.svg';
import naIcon from './svg/na.svg';
import nrIcon from './svg/nr.svg';
import npIcon from './svg/np.svg';
import nlIcon from './svg/nl.svg';
import anIcon from './svg/an.svg';
import ncIcon from './svg/nc.svg';
import nzIcon from './svg/nz.svg';
import niIcon from './svg/ni.svg';
import neIcon from './svg/ne.svg';
import ngIcon from './svg/ng.svg';
import nuIcon from './svg/nu.svg';
import nfIcon from './svg/nf.svg';
import mpIcon from './svg/mp.svg';
import noIcon from './svg/no.svg';
import omIcon from './svg/om.svg';
import pkIcon from './svg/pk.svg';
import pwIcon from './svg/pw.svg';
import psIcon from './svg/ps.svg';
import paIcon from './svg/pa.svg';
import pgIcon from './svg/pg.svg';
import pyIcon from './svg/py.svg';
import peIcon from './svg/pe.svg';
import phIcon from './svg/ph.svg';
import pnIcon from './svg/pn.svg';
import plIcon from './svg/pl.svg';
import ptIcon from './svg/pt.svg';
import prIcon from './svg/pr.svg';
import qaIcon from './svg/qa.svg';
import roIcon from './svg/ro.svg';
import ruIcon from './svg/ru.svg';
import rwIcon from './svg/rw.svg';
import reIcon from './svg/re.svg';
import blIcon from './svg/bl.svg';
import shIcon from './svg/sh.svg';
import knIcon from './svg/kn.svg';
import lcIcon from './svg/lc.svg';
import mfIcon from './svg/mf.svg';
import pmIcon from './svg/pm.svg';
import vcIcon from './svg/vc.svg';
import wsIcon from './svg/ws.svg';
import smIcon from './svg/sm.svg';
import stIcon from './svg/st.svg';
import saIcon from './svg/sa.svg';
import snIcon from './svg/sn.svg';
import rsIcon from './svg/rs.svg';
import scIcon from './svg/sc.svg';
import slIcon from './svg/sl.svg';
import sgIcon from './svg/sg.svg';
import skIcon from './svg/sk.svg';
import siIcon from './svg/si.svg';
import sbIcon from './svg/sb.svg';
import soIcon from './svg/so.svg';
import zaIcon from './svg/za.svg';
import ssIcon from './svg/ss.svg';
import gsIcon from './svg/gs.svg';
import esIcon from './svg/es.svg';
import lkIcon from './svg/lk.svg';
import sdIcon from './svg/sd.svg';
import srIcon from './svg/sr.svg';
import sjIcon from './svg/sj.svg';
import szIcon from './svg/sz.svg';
import seIcon from './svg/se.svg';
import chIcon from './svg/ch.svg';
import syIcon from './svg/sy.svg';
import twIcon from './svg/tw.svg';
import tjIcon from './svg/tj.svg';
import tzIcon from './svg/tz.svg';
import thIcon from './svg/th.svg';
import tlIcon from './svg/tl.svg';
import tgIcon from './svg/tg.svg';
import tkIcon from './svg/tk.svg';
import toIcon from './svg/to.svg';
import ttIcon from './svg/tt.svg';
import tnIcon from './svg/tn.svg';
import trIcon from './svg/tr.svg';
import tmIcon from './svg/tm.svg';
import tcIcon from './svg/tc.svg';
import tvIcon from './svg/tv.svg';
import ugIcon from './svg/ug.svg';
import uaIcon from './svg/ua.svg';
import aeIcon from './svg/ae.svg';
import gbIcon from './svg/gb.svg';
import usIcon from './svg/us.svg';
import uyIcon from './svg/uy.svg';
import uzIcon from './svg/uz.svg';
import vuIcon from './svg/vu.svg';
import veIcon from './svg/ve.svg';
import vnIcon from './svg/vn.svg';
import vgIcon from './svg/vg.svg';
import viIcon from './svg/vi.svg';
import wfIcon from './svg/wf.svg';
import yeIcon from './svg/ye.svg';
import zmIcon from './svg/zm.svg';
import zwIcon from './svg/zw.svg';
import euIcon from './svg/eu.svg';
const countryFlags = [
  {
    name: 'Afghanistan',
    flag: '🇦🇫',
    code: 'AF',
    dial_code: '+93',
    countryFlag: afIcon,
  },
  {
    name: 'Åland Islands',
    flag: '🇦🇽',
    code: 'AX',
    dial_code: '+358',
    countryFlag: axIcon,
  },
  {
    name: 'Albania',
    flag: '🇦🇱',
    code: 'AL',
    dial_code: '+355',
    countryFlag: alIcon,
  },
  {
    name: 'Algeria',
    flag: '🇩🇿',
    code: 'DZ',
    dial_code: '+213',
    countryFlag: dzIcon,
  },
  {
    name: 'American Samoa',
    flag: '🇦🇸',
    code: 'AS',
    dial_code: '+1684',
    countryFlag: asIcon,
  },
  {
    name: 'Andorra',
    flag: '🇦🇩',
    code: 'AD',
    dial_code: '+376',
    countryFlag: adIcon,
  },
  {
    name: 'Angola',
    flag: '🇦🇴',
    code: 'AO',
    dial_code: '+244',
    countryFlag: aoIcon,
  },
  {
    name: 'Anguilla',
    flag: '🇦🇮',
    code: 'AI',
    dial_code: '+1264',
    countryFlag: aiIcon,
  },
  {
    name: 'Antarctica',
    flag: '🇦🇶',
    code: 'AQ',
    dial_code: '+672',
    countryFlag: aqIcon,
  },
  {
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
    code: 'AG',
    dial_code: '+1268',
    countryFlag: agIcon,
  },
  {
    name: 'Argentina',
    flag: '🇦🇷',
    code: 'AR',
    dial_code: '+54',
    countryFlag: arIcon,
  },
  {
    name: 'Armenia',
    flag: '🇦🇲',
    code: 'AM',
    dial_code: '+374',
    countryFlag: amIcon,
  },
  {
    name: 'Aruba',
    flag: '🇦🇼',
    code: 'AW',
    dial_code: '+297',
    countryFlag: awIcon,
  },
  {
    name: 'Australia',
    flag: '🇦🇺',
    code: 'AU',
    dial_code: '+61',
    countryFlag: auIcon,
  },
  {
    name: 'Austria',
    flag: '🇦🇹',
    code: 'AT',
    dial_code: '+43',
    countryFlag: atIcon,
  },
  {
    name: 'Azerbaijan',
    flag: '🇦🇿',
    code: 'AZ',
    dial_code: '+994',
    countryFlag: azIcon,
  },
  {
    name: 'Bahamas',
    flag: '🇧🇸',
    code: 'BS',
    dial_code: '+1242',
    countryFlag: bsIcon,
  },
  {
    name: 'Bahrain',
    flag: '🇧🇭',
    code: 'BH',
    dial_code: '+973',
    countryFlag: bhIcon,
  },
  {
    name: 'Bangladesh',
    flag: '🇧🇩',
    code: 'BD',
    dial_code: '+880',
    countryFlag: bdIcon,
  },
  {
    name: 'Barbados',
    flag: '🇧🇧',
    code: 'BB',
    dial_code: '+1246',
    countryFlag: bbIcon,
  },
  {
    name: 'Belarus',
    flag: '🇧🇾',
    code: 'BY',
    dial_code: '+375',
    countryFlag: byIcon,
  },
  {
    name: 'Belgium',
    flag: '🇧🇪',
    code: 'BE',
    dial_code: '+32',
    countryFlag: beIcon,
  },
  {
    name: 'Belize',
    flag: '🇧🇿',
    code: 'BZ',
    dial_code: '+501',
    countryFlag: bzIcon,
  },
  {
    name: 'Benin',
    flag: '🇧🇯',
    code: 'BJ',
    dial_code: '+229',
    countryFlag: bjIcon,
  },
  {
    name: 'Bermuda',
    flag: '🇧🇲',
    code: 'BM',
    dial_code: '+1441',
    countryFlag: bmIcon,
  },
  {
    name: 'Bhutan',
    flag: '🇧🇹',
    code: 'BT',
    dial_code: '+975',
    countryFlag: btIcon,
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    flag: '🇧🇴',
    code: 'BO',
    dial_code: '+591',
    countryFlag: boIcon,
  },
  {
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    code: 'BA',
    dial_code: '+387',
    countryFlag: baIcon,
  },
  {
    name: 'Botswana',
    flag: '🇧🇼',
    code: 'BW',
    dial_code: '+267',
    countryFlag: bwIcon,
  },
  {
    name: 'Bouvet Island',
    flag: '🇧🇻',
    code: 'BV',
    dial_code: '+47',
    countryFlag: bvIcon,
  },
  {
    name: 'Brazil',
    flag: '🇧🇷',
    code: 'BR',
    dial_code: '+55',
    countryFlag: brIcon,
  },
  {
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    code: 'IO',
    dial_code: '+246',
    countryFlag: ioIcon,
  },
  {
    name: 'Brunei Darussalam',
    flag: '🇧🇳',
    code: 'BN',
    dial_code: '+673',
    countryFlag: bnIcon,
  },
  {
    name: 'Bulgaria',
    flag: '🇧🇬',
    code: 'BG',
    dial_code: '+359',
    countryFlag: bgIcon,
  },
  {
    name: 'Burkina Faso',
    flag: '🇧🇫',
    code: 'BF',
    dial_code: '+226',
    countryFlag: bfIcon,
  },
  {
    name: 'Burundi',
    flag: '🇧🇮',
    code: 'BI',
    dial_code: '+257',
    countryFlag: biIcon,
  },
  {
    name: 'Cambodia',
    flag: '🇰🇭',
    code: 'KH',
    dial_code: '+855',
    countryFlag: khIcon,
  },
  {
    name: 'Cameroon',
    flag: '🇨🇲',
    code: 'CM',
    dial_code: '+237',
    countryFlag: cmIcon,
  },
  {
    name: 'Canada',
    flag: '🇨🇦',
    code: 'CA',
    dial_code: '+1',
    countryFlag: caIcon,
  },
  {
    name: 'Cape Verde',
    flag: '🇨🇻',
    code: 'CV',
    dial_code: '+238',
    countryFlag: cvIcon,
  },
  {
    name: 'Cayman Islands',
    flag: '🇰🇾',
    code: 'KY',
    dial_code: '+345',
    countryFlag: kyIcon,
  },
  {
    name: 'Central African Republic',
    flag: '🇨🇫',
    code: 'CF',
    dial_code: '+236',
    countryFlag: cfIcon,
  },
  {
    name: 'Chad',
    flag: '🇹🇩',
    code: 'TD',
    dial_code: '+235',
    countryFlag: tdIcon,
  },
  {
    name: 'Chile',
    flag: '🇨🇱',
    code: 'CL',
    dial_code: '+56',
    countryFlag: clIcon,
  },
  {
    name: 'China',
    flag: '🇨🇳',
    code: 'CN',
    dial_code: '+86',
    countryFlag: cnIcon,
  },
  {
    name: 'Christmas Island',
    flag: '🇨🇽',
    code: 'CX',
    dial_code: '+61',
    countryFlag: cxIcon,
  },
  {
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    code: 'CC',
    dial_code: '+61',
    countryFlag: ccIcon,
  },
  {
    name: 'Colombia',
    flag: '🇨🇴',
    code: 'CO',
    dial_code: '+57',
    countryFlag: coIcon,
  },
  {
    name: 'Comoros',
    flag: '🇰🇲',
    code: 'KM',
    dial_code: '+269',
    countryFlag: kmIcon,
  },
  {
    name: 'Congo',
    flag: '🇨🇬',
    code: 'CG',
    dial_code: '+242',
    countryFlag: cgIcon,
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    flag: '🇨🇩',
    code: 'CD',
    dial_code: '+243',
    countryFlag: cdIcon,
  },
  {
    name: 'Cook Islands',
    flag: '🇨🇰',
    code: 'CK',
    dial_code: '+682',
    countryFlag: ckIcon,
  },
  {
    name: 'Costa Rica',
    flag: '🇨🇷',
    code: 'CR',
    dial_code: '+506',
    countryFlag: crIcon,
  },
  {
    name: "Cote d'Ivoire",
    flag: '🇨🇮',
    code: 'CI',
    dial_code: '+225',
    countryFlag: ciIcon,
  },
  {
    name: 'Croatia',
    flag: '🇭🇷',
    code: 'HR',
    dial_code: '+385',
    countryFlag: hrIcon,
  },
  {
    name: 'Cuba',
    flag: '🇨🇺',
    code: 'CU',
    dial_code: '+53',
    countryFlag: cuIcon,
  },
  {
    name: 'Cyprus',
    flag: '🇨🇾',
    code: 'CY',
    dial_code: '+357',
    countryFlag: cyIcon,
  },
  {
    name: 'Czech Republic',
    flag: '🇨🇿',
    code: 'CZ',
    dial_code: '+420',
    countryFlag: czIcon,
  },
  {
    name: 'Denmark',
    flag: '🇩🇰',
    code: 'DK',
    dial_code: '+45',
    countryFlag: dkIcon,
  },
  {
    name: 'Djibouti',
    flag: '🇩🇯',
    code: 'DJ',
    dial_code: '+253',
    countryFlag: djIcon,
  },
  {
    name: 'Dominica',
    flag: '🇩🇲',
    code: 'DM',
    dial_code: '+1767',
    countryFlag: dmIcon,
  },
  {
    name: 'Dominican Republic',
    flag: '🇩🇴',
    code: 'DO',
    dial_code: '+1849',
    countryFlag: doIcon,
  },
  {
    name: 'Ecuador',
    flag: '🇪🇨',
    code: 'EC',
    dial_code: '+593',
    countryFlag: ecIcon,
  },
  {
    name: 'Egypt',
    flag: '🇪🇬',
    code: 'EG',
    dial_code: '+20',
    countryFlag: egIcon,
  },
  {
    name: 'El Salvador',
    flag: '🇸🇻',
    code: 'SV',
    dial_code: '+503',
    countryFlag: svIcon,
  },
  {
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    code: 'GQ',
    dial_code: '+240',
    countryFlag: gqIcon,
  },
  {
    name: 'Eritrea',
    flag: '🇪🇷',
    code: 'ER',
    dial_code: '+291',
    countryFlag: erIcon,
  },
  {
    name: 'Estonia',
    flag: '🇪🇪',
    code: 'EE',
    dial_code: '+372',
    countryFlag: eeIcon,
  },
  {
    name: 'Ethiopia',
    flag: '🇪🇹',
    code: 'ET',
    dial_code: '+251',
    countryFlag: etIcon,
  },
  {
    name: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    code: 'FK',
    dial_code: '+500',
    countryFlag: fkIcon,
  },
  {
    name: 'Faroe Islands',
    flag: '🇫🇴',
    code: 'FO',
    dial_code: '+298',
    countryFlag: foIcon,
  },
  {
    name: 'Fiji',
    flag: '🇫🇯',
    code: 'FJ',
    dial_code: '+679',
    countryFlag: fjIcon,
  },
  {
    name: 'Finland',
    flag: '🇫🇮',
    code: 'FI',
    dial_code: '+358',
    countryFlag: fiIcon,
  },
  {
    name: 'France',
    flag: '🇫🇷',
    code: 'FR',
    dial_code: '+33',
    countryFlag: frIcon,
  },
  {
    name: 'French Guiana',
    flag: '🇬🇫',
    code: 'GF',
    dial_code: '+594',
    countryFlag: gfIcon,
  },
  {
    name: 'French Polynesia',
    flag: '🇵🇫',
    code: 'PF',
    dial_code: '+689',
    countryFlag: pfIcon,
  },
  {
    name: 'French Southern Territories',
    flag: '🇹🇫',
    code: 'TF',
    dial_code: '+262',
    countryFlag: tfIcon,
  },
  {
    name: 'Gabon',
    flag: '🇬🇦',
    code: 'GA',
    dial_code: '+241',
    countryFlag: gaIcon,
  },
  {
    name: 'Gambia',
    flag: '🇬🇲',
    code: 'GM',
    dial_code: '+220',
    countryFlag: gmIcon,
  },
  {
    name: 'Georgia',
    flag: '🇬🇪',
    code: 'GE',
    dial_code: '+995',
    countryFlag: geIcon,
  },
  {
    name: 'Germany',
    flag: '🇩🇪',
    code: 'DE',
    dial_code: '+49',
    countryFlag: deIcon,
  },
  {
    name: 'Ghana',
    flag: '🇬🇭',
    code: 'GH',
    dial_code: '+233',
    countryFlag: ghIcon,
  },
  {
    name: 'Gibraltar',
    flag: '🇬🇮',
    code: 'GI',
    dial_code: '+350',
    countryFlag: giIcon,
  },
  {
    name: 'Greece',
    flag: '🇬🇷',
    code: 'GR',
    dial_code: '+30',
    countryFlag: grIcon,
  },
  {
    name: 'Greenland',
    flag: '🇬🇱',
    code: 'GL',
    dial_code: '+299',
    countryFlag: glIcon,
  },
  {
    name: 'Grenada',
    flag: '🇬🇩',
    code: 'GD',
    dial_code: '+1473',
    countryFlag: gdIcon,
  },
  {
    name: 'Guadeloupe',
    flag: '🇬🇵',
    code: 'GP',
    dial_code: '+590',
    countryFlag: gpIcon,
  },
  {
    name: 'Guam',
    flag: '🇬🇺',
    code: 'GU',
    dial_code: '+1671',
    countryFlag: guIcon,
  },
  {
    name: 'Guatemala',
    flag: '🇬🇹',
    code: 'GT',
    dial_code: '+502',
    countryFlag: gtIcon,
  },
  {
    name: 'Guernsey',
    flag: '🇬🇬',
    code: 'GG',
    dial_code: '+44',
    countryFlag: ggIcon,
  },
  {
    name: 'Guinea',
    flag: '🇬🇳',
    code: 'GN',
    dial_code: '+224',
    countryFlag: gnIcon,
  },
  {
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
    code: 'GW',
    dial_code: '+245',
    countryFlag: gwIcon,
  },
  {
    name: 'Guyana',
    flag: '🇬🇾',
    code: 'GY',
    dial_code: '+592',
    countryFlag: gyIcon,
  },
  {
    name: 'Haiti',
    flag: '🇭🇹',
    code: 'HT',
    dial_code: '+509',
    countryFlag: htIcon,
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    flag: '🇭🇲',
    code: 'HM',
    dial_code: '+672',
    countryFlag: hmIcon,
  },
  {
    name: 'Holy See (Vatican City State)',
    flag: '🇻🇦',
    code: 'VA',
    dial_code: '+379',
    countryFlag: vaIcon,
  },
  {
    name: 'Honduras',
    flag: '🇭🇳',
    code: 'HN',
    dial_code: '+504',
    countryFlag: hnIcon,
  },
  {
    name: 'Hong Kong',
    flag: '🇭🇰',
    code: 'HK',
    dial_code: '+852',
    countryFlag: hkIcon,
  },
  {
    name: 'Hungary',
    flag: '🇭🇺',
    code: 'HU',
    dial_code: '+36',
    countryFlag: huIcon,
  },
  {
    name: 'Iceland',
    flag: '🇮🇸',
    code: 'IS',
    dial_code: '+354',
    countryFlag: isIcon,
  },
  {
    name: 'India',
    flag: '🇮🇳',
    code: 'IN',
    dial_code: '+91',
    countryFlag: inIcon,
  },
  {
    name: 'Indonesia',
    flag: '🇮🇩',
    code: 'ID',
    dial_code: '+62',
    countryFlag: idIcon,
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    flag: '🇮🇷',
    code: 'IR',
    dial_code: '+98',
    countryFlag: irIcon,
  },
  {
    name: 'Iraq',
    flag: '🇮🇶',
    code: 'IQ',
    dial_code: '+964',
    countryFlag: iqIcon,
  },
  {
    name: 'Ireland',
    flag: '🇮🇪',
    code: 'IE',
    dial_code: '+353',
    countryFlag: ieIcon,
  },
  {
    name: 'Isle of Man',
    flag: '🇮🇲',
    code: 'IM',
    dial_code: '+44',
    countryFlag: imIcon,
  },
  {
    name: 'Israel',
    flag: '🇮🇱',
    code: 'IL',
    dial_code: '+972',
    countryFlag: ilIcon,
  },
  {
    name: 'Italy',
    flag: '🇮🇹',
    code: 'IT',
    dial_code: '+39',
    countryFlag: itIcon,
  },
  {
    name: 'Jamaica',
    flag: '🇯🇲',
    code: 'JM',
    dial_code: '+1876',
    countryFlag: jmIcon,
  },
  {
    name: 'Japan',
    flag: '🇯🇵',
    code: 'JP',
    dial_code: '+81',
    countryFlag: jpIcon,
  },
  {
    name: 'Jersey',
    flag: '🇯🇪',
    code: 'JE',
    dial_code: '+44',
    countryFlag: jeIcon,
  },
  {
    name: 'Jordan',
    flag: '🇯🇴',
    code: 'JO',
    dial_code: '+962',
    countryFlag: joIcon,
  },
  {
    name: 'Kazakhstan',
    flag: '🇰🇿',
    code: 'KZ',
    dial_code: '+7',
    countryFlag: kzIcon,
  },
  {
    name: 'Kenya',
    flag: '🇰🇪',
    code: 'KE',
    dial_code: '+254',
    countryFlag: keIcon,
  },
  {
    name: 'Kiribati',
    flag: '🇰🇮',
    code: 'KI',
    dial_code: '+686',
    countryFlag: kiIcon,
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: '🇰🇵',
    code: 'KP',
    dial_code: '+850',
    countryFlag: kpIcon,
  },
  {
    name: 'Korea, Republic of South Korea',
    flag: '🇰🇷',
    code: 'KR',
    dial_code: '+82',
    countryFlag: krIcon,
  },
  {
    name: 'Kosovo',
    flag: '🇽🇰',
    code: 'XK',
    dial_code: '+383',
    countryFlag: xkIcon,
  },
  {
    name: 'Kuwait',
    flag: '🇰🇼',
    code: 'KW',
    dial_code: '+965',
    countryFlag: kwIcon,
  },
  {
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
    code: 'KG',
    dial_code: '+996',
    countryFlag: kgIcon,
  },
  {
    name: 'Laos',
    flag: '🇱🇦',
    code: 'LA',
    dial_code: '+856',
    countryFlag: laIcon,
  },
  {
    name: 'Latvia',
    flag: '🇱🇻',
    code: 'LV',
    dial_code: '+371',
    countryFlag: lvIcon,
  },
  {
    name: 'Lebanon',
    flag: '🇱🇧',
    code: 'LB',
    dial_code: '+961',
    countryFlag: lbIcon,
  },
  {
    name: 'Lesotho',
    flag: '🇱🇸',
    code: 'LS',
    dial_code: '+266',
    countryFlag: lsIcon,
  },
  {
    name: 'Liberia',
    flag: '🇱🇷',
    code: 'LR',
    dial_code: '+231',
    countryFlag: lrIcon,
  },
  {
    name: 'Libyan Arab Jamahiriya',
    flag: '🇱🇾',
    code: 'LY',
    dial_code: '+218',
    countryFlag: lyIcon,
  },
  {
    name: 'Liechtenstein',
    flag: '🇱🇮',
    code: 'LI',
    dial_code: '+423',
    countryFlag: liIcon,
  },
  {
    name: 'Lithuania',
    flag: '🇱🇹',
    code: 'LT',
    dial_code: '+370',
    countryFlag: ltIcon,
  },
  {
    name: 'Luxembourg',
    flag: '🇱🇺',
    code: 'LU',
    dial_code: '+352',
    countryFlag: luIcon,
  },
  {
    name: 'Macao',
    flag: '🇲🇴',
    code: 'MO',
    dial_code: '+853',
    countryFlag: moIcon,
  },
  {
    name: 'Macedonia',
    flag: '🇲🇰',
    code: 'MK',
    dial_code: '+389',
    countryFlag: mkIcon,
  },
  {
    name: 'Madagascar',
    flag: '🇲🇬',
    code: 'MG',
    dial_code: '+261',
    countryFlag: mgIcon,
  },
  {
    name: 'Malawi',
    flag: '🇲🇼',
    code: 'MW',
    dial_code: '+265',
    countryFlag: mwIcon,
  },
  {
    name: 'Malaysia',
    flag: '🇲🇾',
    code: 'MY',
    dial_code: '+60',
    countryFlag: myIcon,
  },
  {
    name: 'Maldives',
    flag: '🇲🇻',
    code: 'MV',
    dial_code: '+960',
    countryFlag: mvIcon,
  },
  {
    name: 'Mali',
    flag: '🇲🇱',
    code: 'ML',
    dial_code: '+223',
    countryFlag: mlIcon,
  },
  {
    name: 'Malta',
    flag: '🇲🇹',
    code: 'MT',
    dial_code: '+356',
    countryFlag: mtIcon,
  },
  {
    name: 'Marshall Islands',
    flag: '🇲🇭',
    code: 'MH',
    dial_code: '+692',
    countryFlag: mhIcon,
  },
  {
    name: 'Martinique',
    flag: '🇲🇶',
    code: 'MQ',
    dial_code: '+596',
    countryFlag: mqIcon,
  },
  {
    name: 'Mauritania',
    flag: '🇲🇷',
    code: 'MR',
    dial_code: '+222',
    countryFlag: mrIcon,
  },
  {
    name: 'Mauritius',
    flag: '🇲🇺',
    code: 'MU',
    dial_code: '+230',
    countryFlag: muIcon,
  },
  {
    name: 'Mayotte',
    flag: '🇾🇹',
    code: 'YT',
    dial_code: '+262',
    countryFlag: ytIcon,
  },
  {
    name: 'Mexico',
    flag: '🇲🇽',
    code: 'MX',
    dial_code: '+52',
    countryFlag: mxIcon,
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    flag: '🇫🇲',
    code: 'FM',
    dial_code: '+691',
    countryFlag: fmIcon,
  },
  {
    name: 'Moldova',
    flag: '🇲🇩',
    code: 'MD',
    dial_code: '+373',
    countryFlag: mdIcon,
  },
  {
    name: 'Monaco',
    flag: '🇲🇨',
    code: 'MC',
    dial_code: '+377',
    countryFlag: mcIcon,
  },
  {
    name: 'Mongolia',
    flag: '🇲🇳',
    code: 'MN',
    dial_code: '+976',
    countryFlag: mnIcon,
  },
  {
    name: 'Montenegro',
    flag: '🇲🇪',
    code: 'ME',
    dial_code: '+382',
    countryFlag: meIcon,
  },
  {
    name: 'Montserrat',
    flag: '🇲🇸',
    code: 'MS',
    dial_code: '+1664',
    countryFlag: msIcon,
  },
  {
    name: 'Morocco',
    flag: '🇲🇦',
    code: 'MA',
    dial_code: '+212',
    countryFlag: maIcon,
  },
  {
    name: 'Mozambique',
    flag: '🇲🇿',
    code: 'MZ',
    dial_code: '+258',
    countryFlag: mzIcon,
  },
  {
    name: 'Myanmar',
    flag: '🇲🇲',
    code: 'MM',
    dial_code: '+95',
    countryFlag: mmIcon,
  },
  {
    name: 'Namibia',
    flag: '🇳🇦',
    code: 'NA',
    dial_code: '+264',
    countryFlag: naIcon,
  },
  {
    name: 'Nauru',
    flag: '🇳🇷',
    code: 'NR',
    dial_code: '+674',
    countryFlag: nrIcon,
  },
  {
    name: 'Nepal',
    flag: '🇳🇵',
    code: 'NP',
    dial_code: '+977',
    countryFlag: npIcon,
  },
  {
    name: 'Netherlands',
    flag: '🇳🇱',
    code: 'NL',
    dial_code: '+31',
    countryFlag: nlIcon,
  },
  {
    name: 'Netherlands Antilles',
    flag: '',
    code: 'AN',
    dial_code: '+599',
    countryFlag: anIcon,
  },
  {
    name: 'New Caledonia',
    flag: '🇳🇨',
    code: 'NC',
    dial_code: '+687',
    countryFlag: ncIcon,
  },
  {
    name: 'New Zealand',
    flag: '🇳🇿',
    code: 'NZ',
    dial_code: '+64',
    countryFlag: nzIcon,
  },
  {
    name: 'Nicaragua',
    flag: '🇳🇮',
    code: 'NI',
    dial_code: '+505',
    countryFlag: niIcon,
  },
  {
    name: 'Niger',
    flag: '🇳🇪',
    code: 'NE',
    dial_code: '+227',
    countryFlag: neIcon,
  },
  {
    name: 'Nigeria',
    flag: '🇳🇬',
    code: 'NG',
    dial_code: '+234',
    countryFlag: ngIcon,
  },
  {
    name: 'Niue',
    flag: '🇳🇺',
    code: 'NU',
    dial_code: '+683',
    countryFlag: nuIcon,
  },
  {
    name: 'Norfolk Island',
    flag: '🇳🇫',
    code: 'NF',
    dial_code: '+672',
    countryFlag: nfIcon,
  },
  {
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    code: 'MP',
    dial_code: '+1670',
    countryFlag: mpIcon,
  },
  {
    name: 'Norway',
    flag: '🇳🇴',
    code: 'NO',
    dial_code: '+47',
    countryFlag: noIcon,
  },
  {
    name: 'Oman',
    flag: '🇴🇲',
    code: 'OM',
    dial_code: '+968',
    countryFlag: omIcon,
  },
  {
    name: 'Pakistan',
    flag: '🇵🇰',
    code: 'PK',
    dial_code: '+92',
    countryFlag: pkIcon,
  },
  {
    name: 'Palau',
    flag: '🇵🇼',
    code: 'PW',
    dial_code: '+680',
    countryFlag: pwIcon,
  },
  {
    name: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    code: 'PS',
    dial_code: '+970',
    countryFlag: psIcon,
  },
  {
    name: 'Panama',
    flag: '🇵🇦',
    code: 'PA',
    dial_code: '+507',
    countryFlag: paIcon,
  },
  {
    name: 'Papua New Guinea',
    flag: '🇵🇬',
    code: 'PG',
    dial_code: '+675',
    countryFlag: pgIcon,
  },
  {
    name: 'Paraguay',
    flag: '🇵🇾',
    code: 'PY',
    dial_code: '+595',
    countryFlag: pyIcon,
  },
  {
    name: 'Peru',
    flag: '🇵🇪',
    code: 'PE',
    dial_code: '+51',
    countryFlag: peIcon,
  },
  {
    name: 'Philippines',
    flag: '🇵🇭',
    code: 'PH',
    dial_code: '+63',
    countryFlag: phIcon,
  },
  {
    name: 'Pitcairn',
    flag: '🇵🇳',
    code: 'PN',
    dial_code: '+64',
    countryFlag: pnIcon,
  },
  {
    name: 'Poland',
    flag: '🇵🇱',
    code: 'PL',
    dial_code: '+48',
    countryFlag: plIcon,
  },
  {
    name: 'Portugal',
    flag: '🇵🇹',
    code: 'PT',
    dial_code: '+351',
    countryFlag: ptIcon,
  },
  {
    name: 'Puerto Rico',
    flag: '🇵🇷',
    code: 'PR',
    dial_code: '+1939',
    countryFlag: prIcon,
  },
  {
    name: 'Qatar',
    flag: '🇶🇦',
    code: 'QA',
    dial_code: '+974',
    countryFlag: qaIcon,
  },
  {
    name: 'Romania',
    flag: '🇷🇴',
    code: 'RO',
    dial_code: '+40',
    countryFlag: roIcon,
  },
  {
    name: 'Russia',
    flag: '🇷🇺',
    code: 'RU',
    dial_code: '+7',
    countryFlag: ruIcon,
  },
  {
    name: 'Rwanda',
    flag: '🇷🇼',
    code: 'RW',
    dial_code: '+250',
    countryFlag: rwIcon,
  },
  {
    name: 'Reunion',
    flag: '🇷🇪',
    code: 'RE',
    dial_code: '+262',
    countryFlag: reIcon,
  },
  {
    name: 'Saint Barthelemy',
    flag: '🇧🇱',
    code: 'BL',
    dial_code: '+590',
    countryFlag: blIcon,
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    flag: '🇸🇭',
    code: 'SH',
    dial_code: '+290',
    countryFlag: shIcon,
  },
  {
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    code: 'KN',
    dial_code: '+1869',
    countryFlag: knIcon,
  },
  {
    name: 'Saint Lucia',
    flag: '🇱🇨',
    code: 'LC',
    dial_code: '+1758',
    countryFlag: lcIcon,
  },
  {
    name: 'Saint Martin',
    flag: '🇲🇫',
    code: 'MF',
    dial_code: '+590',
    countryFlag: mfIcon,
  },
  {
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    code: 'PM',
    dial_code: '+508',
    countryFlag: pmIcon,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    code: 'VC',
    dial_code: '+1784',
    countryFlag: vcIcon,
  },
  {
    name: 'Samoa',
    flag: '🇼🇸',
    code: 'WS',
    dial_code: '+685',
    countryFlag: wsIcon,
  },
  {
    name: 'San Marino',
    flag: '🇸🇲',
    code: 'SM',
    dial_code: '+378',
    countryFlag: smIcon,
  },
  {
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
    code: 'ST',
    dial_code: '+239',
    countryFlag: stIcon,
  },
  {
    name: 'Saudi Arabia',
    flag: '🇸🇦',
    code: 'SA',
    dial_code: '+966',
    countryFlag: saIcon,
  },
  {
    name: 'Senegal',
    flag: '🇸🇳',
    code: 'SN',
    dial_code: '+221',
    countryFlag: snIcon,
  },
  {
    name: 'Serbia',
    flag: '🇷🇸',
    code: 'RS',
    dial_code: '+381',
    countryFlag: rsIcon,
  },
  {
    name: 'Seychelles',
    flag: '🇸🇨',
    code: 'SC',
    dial_code: '+248',
    countryFlag: scIcon,
  },
  {
    name: 'Sierra Leone',
    flag: '🇸🇱',
    code: 'SL',
    dial_code: '+232',
    countryFlag: slIcon,
  },
  {
    name: 'Singapore',
    flag: '🇸🇬',
    code: 'SG',
    dial_code: '+65',
    countryFlag: sgIcon,
  },
  {
    name: 'Slovakia',
    flag: '🇸🇰',
    code: 'SK',
    dial_code: '+421',
    countryFlag: skIcon,
  },
  {
    name: 'Slovenia',
    flag: '🇸🇮',
    code: 'SI',
    dial_code: '+386',
    countryFlag: siIcon,
  },
  {
    name: 'Solomon Islands',
    flag: '🇸🇧',
    code: 'SB',
    dial_code: '+677',
    countryFlag: sbIcon,
  },
  {
    name: 'Somalia',
    flag: '🇸🇴',
    code: 'SO',
    dial_code: '+252',
    countryFlag: soIcon,
  },
  {
    name: 'South Africa',
    flag: '🇿🇦',
    code: 'ZA',
    dial_code: '+27',
    countryFlag: zaIcon,
  },
  {
    name: 'South Sudan',
    flag: '🇸🇸',
    code: 'SS',
    dial_code: '+211',
    countryFlag: ssIcon,
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    code: 'GS',
    dial_code: '+500',
    countryFlag: gsIcon,
  },
  {
    name: 'Spain',
    flag: '🇪🇸',
    code: 'ES',
    dial_code: '+34',
    countryFlag: esIcon,
  },
  {
    name: 'Sri Lanka',
    flag: '🇱🇰',
    code: 'LK',
    dial_code: '+94',
    countryFlag: lkIcon,
  },
  {
    name: 'Sudan',
    flag: '🇸🇩',
    code: 'SD',
    dial_code: '+249',
    countryFlag: sdIcon,
  },
  {
    name: 'Suriname',
    flag: '🇸🇷',
    code: 'SR',
    dial_code: '+597',
    countryFlag: srIcon,
  },
  {
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    code: 'SJ',
    dial_code: '+47',
    countryFlag: sjIcon,
  },
  {
    name: 'Swaziland',
    flag: '🇸🇿',
    code: 'SZ',
    dial_code: '+268',
    countryFlag: szIcon,
  },
  {
    name: 'Sweden',
    flag: '🇸🇪',
    code: 'SE',
    dial_code: '+46',
    countryFlag: seIcon,
  },
  {
    name: 'Switzerland',
    flag: '🇨🇭',
    code: 'CH',
    dial_code: '+41',
    countryFlag: chIcon,
  },
  {
    name: 'Syrian Arab Republic',
    flag: '🇸🇾',
    code: 'SY',
    dial_code: '+963',
    countryFlag: syIcon,
  },
  {
    name: 'Taiwan',
    flag: '🇹🇼',
    code: 'TW',
    dial_code: '+886',
    countryFlag: twIcon,
  },
  {
    name: 'Tajikistan',
    flag: '🇹🇯',
    code: 'TJ',
    dial_code: '+992',
    countryFlag: tjIcon,
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    flag: '🇹🇿',
    code: 'TZ',
    dial_code: '+255',
    countryFlag: tzIcon,
  },
  {
    name: 'Thailand',
    flag: '🇹🇭',
    code: 'TH',
    dial_code: '+66',
    countryFlag: thIcon,
  },
  {
    name: 'Timor-Leste',
    flag: '🇹🇱',
    code: 'TL',
    dial_code: '+670',
    countryFlag: tlIcon,
  },
  {
    name: 'Togo',
    flag: '🇹🇬',
    code: 'TG',
    dial_code: '+228',
    countryFlag: tgIcon,
  },
  {
    name: 'Tokelau',
    flag: '🇹🇰',
    code: 'TK',
    dial_code: '+690',
    countryFlag: tkIcon,
  },
  {
    name: 'Tonga',
    flag: '🇹🇴',
    code: 'TO',
    dial_code: '+676',
    countryFlag: toIcon,
  },
  {
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    code: 'TT',
    dial_code: '+1868',
    countryFlag: ttIcon,
  },
  {
    name: 'Tunisia',
    flag: '🇹🇳',
    code: 'TN',
    dial_code: '+216',
    countryFlag: tnIcon,
  },
  {
    name: 'Turkey',
    flag: '🇹🇷',
    code: 'TR',
    dial_code: '+90',
    countryFlag: trIcon,
  },
  {
    name: 'Turkmenistan',
    flag: '🇹🇲',
    code: 'TM',
    dial_code: '+993',
    countryFlag: tmIcon,
  },
  {
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    code: 'TC',
    dial_code: '+1649',
    countryFlag: tcIcon,
  },
  {
    name: 'Tuvalu',
    flag: '🇹🇻',
    code: 'TV',
    dial_code: '+688',
    countryFlag: tvIcon,
  },
  {
    name: 'Uganda',
    flag: '🇺🇬',
    code: 'UG',
    dial_code: '+256',
    countryFlag: ugIcon,
  },
  {
    name: 'Ukraine',
    flag: '🇺🇦',
    code: 'UA',
    dial_code: '+380',
    countryFlag: uaIcon,
  },
  {
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    code: 'AE',
    dial_code: '+971',
    countryFlag: aeIcon,
  },
  {
    name: 'United Kingdom',
    flag: '🇬🇧',
    code: 'GB',
    dial_code: '+44',
    countryFlag: gbIcon,
  },
  {
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dial_code: '+1',
    countryFlag: usIcon,
  },
  {
    name: 'Uruguay',
    flag: '🇺🇾',
    code: 'UY',
    dial_code: '+598',
    countryFlag: uyIcon,
  },
  {
    name: 'Uzbekistan',
    flag: '🇺🇿',
    code: 'UZ',
    dial_code: '+998',
    countryFlag: uzIcon,
  },
  {
    name: 'Vanuatu',
    flag: '🇻🇺',
    code: 'VU',
    dial_code: '+678',
    countryFlag: vuIcon,
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    flag: '🇻🇪',
    code: 'VE',
    dial_code: '+58',
    countryFlag: veIcon,
  },
  {
    name: 'Vietnam',
    flag: '🇻🇳',
    code: 'VN',
    dial_code: '+84',
    countryFlag: vnIcon,
  },
  {
    name: 'Virgin Islands, British',
    flag: '🇻🇬',
    code: 'VG',
    dial_code: '+1284',
    countryFlag: vgIcon,
  },
  {
    name: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    code: 'VI',
    dial_code: '+1340',
    countryFlag: viIcon,
  },
  {
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
    code: 'WF',
    dial_code: '+681',
    countryFlag: wfIcon,
  },
  {
    name: 'Yemen',
    flag: '🇾🇪',
    code: 'YE',
    dial_code: '+967',
    countryFlag: yeIcon,
  },
  {
    name: 'Zambia',
    flag: '🇿🇲',
    code: 'ZM',
    dial_code: '+260',
    countryFlag: zmIcon,
  },
  {
    name: 'Zimbabwe',
    flag: '🇿🇼',
    code: 'ZW',
    dial_code: '+263',
    countryFlag: zwIcon,
  },
];

export const accountList = [
  {
    flag: gbIcon,
    title: 'British pound',
    symbol: 'GBP',
  },
  {
    flag: euIcon,
    title: 'Euro',
    symbol: 'Euro',
  },
  {
    flag: huIcon,
    title: 'Hungarian forint',
    symbol: 'FOR',
  },
  {
    flag: roIcon,
    title: 'New Romanian leu',
    symbol: 'LEU',
  },
  {
    flag: sgIcon,
    title: 'Singapore dallar',
    symbol: 'SGD',
  },
  {
    flag: trIcon,
    title: 'Turkish lira',
    symbol: 'TRL',
  },
  {
    flag: usIcon,
    title: 'US dallar',
    symbol: 'USD',
  },
  {
    flag: arIcon,
    title: 'Argentine peso',
    symbol: 'PES',
  },
  {
    flag: bdIcon,
    title: 'Bangladesh taka',
    symbol: 'BAT',
  },
  {
    flag: caIcon,
    title: 'Canadian dallar',
    symbol: 'CAD',
  },
];

export default countryFlags;
