export const ACCOUNT_TYPE_PERSONAL = 'personal';
export const ACCOUNT_TYPE_BUSINESS = 'business';

const initialState = {
  accountType: null,
};

const sendMoneyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_TYPE_PERSONAL:
      return { accountType: action.payload };
    case ACCOUNT_TYPE_BUSINESS:
      return { accountType: action.payload };
    default:
      return state;
  }
};

export default sendMoneyTypeReducer;
