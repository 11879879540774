import axios from 'axios';
import { useMutation } from 'react-query';
import swal from 'sweetalert';

import { API } from './Api';
import { TirnuClient } from './Axios';

const transferFundsByIBAN = async (transferData) => {
  return await TirnuClient.post(API.v1.transfer.byIBAN, transferData);
};

const useTransferByIBANMutation = () => {
  return useMutation(API.v1.transfer.byIBAN, transferFundsByIBAN, {
    placeholderData: null,
    select: ({ data }) => data || null,
    onError: (error) => {
      let message =
        error.response?.data?.message ||
        error.response?.data?.errors[0]?.message;
      if (error.response?.data?.message?.error === 'not-sufficient-funds') {
        message =
          "The transaction amount doesn't cover the transfer fee.  Pease try a lower transaction amount.";
      }

      swal({
        title: 'Payment Unsuccessful!',
        text: message,
        className: 'transfer-error',
        buttons: 'Close',
      });

      console.dir(error);
    },
  });
};
const schedulePayment = async (transferData) => {
  return await TirnuClient.post(API.v1.schedule.payment, transferData);
};

const useSchedulePaymentMutation = () => {
  return useMutation(API.v1.schedule.payment, schedulePayment, {
    placeholderData: null,
    select: ({ data }) => data || null,
    onError: (error) => {
      let message = error.response?.data?.errors[0]?.message;
      if (error.response?.data?.message?.error === 'not-sufficient-funds') {
        message =
          "The transaction amount doesn't cover the transfer fee.  Pease try a lower transaction amount.";
      }

      swal({
        title: 'Payment Unsuccessful!',
        text: message,
        className: 'transfer-error',
        buttons: 'Close',
      });
      console.dir(error);
    },
  });
};
const transferBeneficiary = async (transferData) => {
  return await TirnuClient.post(API.v1.transfer.beneficiary, transferData);
};

const useTransferBeneficiaryMutation = () => {
  return useMutation(API.v1.transfer.beneficiary, transferBeneficiary, {
    placeholderData: null,
    select: ({ data }) => data || null,
    onError: (error) => {
      let message = error.response?.data?.errors[0]?.message;
      if (error.response?.data?.message?.error === 'not-sufficient-funds') {
        message =
          "The transaction amount doesn't cover the transfer fee. Pease try a lower transaction amount.";
      }

      console.dir(error);
    },
  });
};

const transferUploadFile = async (transferData) => {
  return await TirnuClient.post(
    API.v1.transfer.buldTransfer.fileUpload,
    transferData
  );
};

const useTransferUploadFileMutation = () => {
  return useMutation(
    API.v1.transfer.buldTransfer.fileUpload,
    transferUploadFile,
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const transferBulkPayment = async (transferData) => {
  return await TirnuClient.post(
    API.v1.transfer.buldTransfer.payment,
    transferData
  );
};

const useTransferBulkPaymentMutation = () => {
  return useMutation(
    API.v1.transfer.buldTransfer.payment,
    transferBulkPayment,
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const transferConsentConfirmation = async (transferData) => {
  return await axios.post(
    API.v1.transfer.intergiro.consentConfirmation,
    transferData
  );
};

const useTransferConsentConfirmationMutation = () => {
  return useMutation(
    API.v1.transfer.intergiro.consentConfirmation,
    transferConsentConfirmation,
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const transferSmsVerfication = async (transferData) => {
  return await axios.post(
    API.v1.transfer.intergiro.smsVerfication,
    transferData
  );
};

const useTransferSmsVerficationMutation = () => {
  return useMutation(
    API.v1.transfer.intergiro.consentConfirmation,
    transferSmsVerfication,
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        swal({
          title: 'Error',
          icon: 'error',
          text: error.response?.data?.errors[0]?.message,
          button: 'Try Again',
        });
      },
    }
  );
};

const adminGenerateSms = async () => {
  return await TirnuClient.post(API.v1.transfer.admin.generateOtp);
};

const useAdminGenerateSmsMutation = () => {
  return useMutation(API.v1.transfer.admin.generateOtp, adminGenerateSms, {
    placeholderData: null,
    select: ({ data }) => data || null,
    onError: (error) => {
      swal({
        title: 'Error',
        icon: 'error',
        text: error.response?.data?.errors[0]?.message,
        button: 'Try Again',
      });
    },
  });
};
const adminTransferSmsVerification = async (transferData) => {
  return await TirnuClient.post(API.v1.transfer.admin.transfer, transferData);
};

const useAdminTransferSmsVerificationMutation = () => {
  return useMutation(
    API.v1.transfer.admin.transfer,
    adminTransferSmsVerification,
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        swal({
          title: 'Error',
          icon: 'error',
          text: error.response?.data?.errors[0]?.message,
          button: 'Try Again',
        });
      },
    }
  );
};

const transferConsentConfirmation2 = async (transferData) => {
  return await axios.post(
    API.v1.transfer.intergiro.consentConfimation2,
    transferData.data,
    {
      headers: {
        'x-access-token': `${transferData.token}`,
      },
    }
  );
};

const useTransferConsentConfirmation2Mutation = () => {
  return useMutation(
    API.v1.transfer.intergiro.consentConfimation2,
    transferConsentConfirmation2,
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

const transferFinalApiForConfirmation = async (transferData) => {
  return await TirnuClient.post(
    API.v1.transfer.intergiro.finalApiForConfirmation,
    transferData
  );
};

const useTransferFinalApiForConfirmationMutation = () => {
  return useMutation(
    API.v1.transfer.intergiro.finalApiForConfirmation,
    transferFinalApiForConfirmation,
    {
      placeholderData: null,
      select: ({ data }) => data || null,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export {
  useTransferByIBANMutation,
  useTransferUploadFileMutation,
  useTransferBeneficiaryMutation,
  useTransferBulkPaymentMutation,
  useSchedulePaymentMutation,
  useAdminGenerateSmsMutation,
  useAdminTransferSmsVerificationMutation,
  useTransferConsentConfirmationMutation,
  useTransferSmsVerficationMutation,
  useTransferConsentConfirmation2Mutation,
  useTransferFinalApiForConfirmationMutation,
};
