import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const InternationalStep1Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const InternationalStep2Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingTop: '4.94rem',
}));

export const InternationalStep1Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1.81rem',
  width: '100%',
  maxWidth: '50rem',
}));
