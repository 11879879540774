export const select = {
  MuiSelect: {
    styleOverrides: {
      root: {
        width: '100%',
        maxWidth: '24.06rem',
        maxHeight: '3.125rem',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.25rem',
        borderRadius: '0.625rem',
        '&.Mui-focused': {
          '& .MuiSelect-select': {
            border: '0.0625rem solid #009193',
          },
        },
        '& .MuiSelect-icon': {
          right: '1.313rem',
          zIndex: 1,
        },
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          padding: '0.5rem 0.938rem',
          border: '0.0625rem solid #788CA5',
          background: '#FFFFFF',
          zIndex: 1,
          '&.MuiInputBase-input': {
            height: '100%',
            borderRadius: '0.625rem',
          },
          '&:focus': {
            borderRadius: '0.625rem',
            border: '0.0625rem solid #009193',
          },
        },
        '& .MuiPopover-root': {
          top: '-0.938rem',
          zIndex: 0,
        },
        '& .MuiPaper-root': {
          background: '#FFFFFF',
          border: '0.0625rem solid rgba(120, 140, 165, 0.5)',
          boxShadow: '0 0.25rem 0.625rem rgba(0, 0, 0, 0.1)',
          borderRadius: '0 0 0.625rem 0.625rem',
          width: '100%',
          maxWidth: '24.06rem',
          paddingTop: '1.25rem',
          zIndex: 0,
          '& .MuiMenuItem-root': {
            background: '#FFFFFF',
            '&.Mui-selected': {
              background: '#FFFFFF',
            },
            '&:hover': {
              background: '#FFFFFF',
            },
          },
        },
      },
    },
  },
};
