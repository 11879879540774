export const link = {
  MuiLink: {
    styleOverrides: {
      root: () => ({
        color: '#009193',
        '&:hover': {
          color: '#009193',
        },
      }),
    },
  },
};
