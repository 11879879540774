import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { palette } from 'theme/foundations';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  FlexContainer,
  PaymentItem,
  PaymentList,
  WhiteContainer,
} from '../styles';
import { CardsManagePaymentMethodModalItems } from './CardsManagePaymentMethodModalItems';
import CardsManagePaymentMethodModalItem from './CardsManagePaymentMethodModalItem';
import { useDispatch } from 'react-redux';
import { ModalCloseAction } from 'store/actions/ModalActions/ModalCloseAction';

const CardsManagePaymentMethodModal = ({ content: ledger }) => {
  const dispatch = useDispatch();

  const onClickClose = () => {
    dispatch(ModalCloseAction());
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '30.38rem',
      }}
    >
      <WhiteContainer>
        <FlexContainer>
          <Typography variant="cardsPayment">Payment methods</Typography>
          <Box onClick={onClickClose}>
            <CloseIcon
              style={{
                color: palette.green400.second,
                fontSize: '1.5rem',
                cursor: 'pointer',
              }}
            />
          </Box>
        </FlexContainer>
        <Divider />
        <PaymentList>
          {CardsManagePaymentMethodModalItems.map((item, index) => {
            return <CardsManagePaymentMethodModalItem {...item} key={index} />;
          })}
        </PaymentList>
      </WhiteContainer>
    </Box>
  );
};

export default CardsManagePaymentMethodModal;
