import React, { Fragment, useContext, useState } from 'react';
import TransfersInfoPopup from '../components/Popup/TransfersInfo';
import styled from 'styled-components';
import { ThemeContext } from '../../context/ThemeContext';
import { Modal } from 'react-bootstrap';
import AccountDropdown from '../components/Forms/Dropdown/AccountDropdown';
import { accountList } from '../components/phoneCountryList/countryList';
import welcomeImage from '../../images/welcome-icon.png';

const Footer = () => {
  return (
    <Fragment>
      {/*<div className="footer">
        <div className="copyright">
          <p>
            Copyright © Designed &amp; Developed by{" "}
            <a href="http://dexignzone.com/" target="_blank" rel="noreferrer">
              DexignZone
            </a>{" "}
            2021
          </p>
        </div>
      </div>*/}
      <AccountPopup />
      <WelcomePopup />
      <PopupModal />
    </Fragment>
  );
};

function PopupModal() {
  const { transferPopup, InfoPopupHandler } = useContext(ThemeContext);
  return (
    <Fragment>
      <PopupWrapper className={`${transferPopup ? 'show' : ''}`}>
        <div className={`transfers-info-popup ${transferPopup ? 'show' : ''}`}>
          <TransfersInfoPopup />
          <CloseIcon onClick={() => InfoPopupHandler()}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 14 14"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z"
                fill="currentColor"
              />
            </svg>
          </CloseIcon>
        </div>
        <Overlay
          className={`${transferPopup ? 'show' : ''}`}
          onClick={() => InfoPopupHandler()}
        />
      </PopupWrapper>
    </Fragment>
  );
}

function WelcomePopup() {
  const { welcomePopup, setWelcomePopup, user } = useContext(ThemeContext);
  const handleClose = () => setWelcomePopup(!welcomePopup);
  return (
    <Modal
      show={welcomePopup}
      onHide={handleClose}
      centered
      size="md"
      style={{
        backgroundColor: 'rgba(0, 145, 147, 0.5)',
      }}
    >
      <Modal.Body
        className=""
        style={{
          backgroundColor: '#FFFFFF',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          padding: '3rem',
        }}
      >
        <div>
          <img width="200px" height="200px" src={welcomeImage} alt="key-icon" />
        </div>
        <h2 style={{ maxWidth: '100%' }}>
          Welcome
          <p
            class="text-truncate"
            title={[user.full_name.first_name, user.full_name.last_name].join(
              ' '
            )}
          >
            {user.full_name.first_name} {user.full_name.last_name}
          </p>
        </h2>
      </Modal.Body>
    </Modal>
  );
}

function AccountPopup() {
  const { accountPopup, setAccountPopup, accountData, setAccountData } =
    useContext(ThemeContext);
  const handleClose = () => setAccountPopup(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const handleClick = () => {
    if (selectedAccount || selectedAccount === 0) {
      setAccountData(selectedAccount);
      handleClose();
    } else {
      alert('Please select an account.');
    }
  };

  return (
    <Modal
      show={accountPopup}
      onHide={handleClose}
      centered
      size="lg"
      style={{
        backgroundColor: 'rgba(0, 145, 147, 0.5)',
      }}
    >
      <Modal.Body
        className=""
        style={{
          backgroundColor: '#FFFFFF',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          padding: '3rem',
        }}
      >
        <p className="open-account">Open a currency account</p>
        <AccountDropdown
          selected={selectedAccount}
          lists={accountList}
          handleSelect={(item) => setSelectedAccount(item)}
        />
        <p className="account-text">You can open accounts in 50+ currencies.</p>
        <button className="account-button" onClick={() => handleClick()}>
          Confirm
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default Footer;
const PopupWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: grid;
  z-index: -999;
  opacity: 0;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;

  &.show {
    z-index: 999;
    opacity: 1;
  }

  & .transfers-info-popup {
    position: relative;
    z-index: 9999;
    width: 640px;
    transition: all ease-in-out 300ms;
    transform: translateY(50px);
    opacity: 0;

    &.show {
      transform: translateY(0);
      opacity: 1;
    }

    @media (max-width: 589px) {
      min-width: 100%;
      width: 100%;
    }
  }
`;
const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: rgb(0 0 0 / 26%);
  opacity: 0;
  visibility: hidden;
  display: none;

  &.show {
    opacity: 1;
    display: block;
    visibility: visible;
  }
`;
const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  padding: 10px;
  display: flex;

  & svg {
    color: var(--gray);

    &:hover {
      color: var(--danger);
    }
  }
`;
