import React from 'react';
import { iconStyle } from './IconWrapper';

export const ReviewIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={iconStyle.main}
    >
      <g clip-path="url(#clip0_2137_12299)">
        <path
          d="M19.9997 3.33337C29.1997 3.33337 36.6663 10.8 36.6663 20C36.6663 29.2 29.1997 36.6667 19.9997 36.6667C10.7997 36.6667 3.33301 29.2 3.33301 20C3.33301 10.8 10.7997 3.33337 19.9997 3.33337ZM19.9997 10C16.4913 10 13.4897 12.6 12.2613 16.2834L12.1397 16.6667H9.99967L14.1663 21.6667L18.333 16.6667H15.668C16.533 14.675 18.1497 13.3334 19.9997 13.3334C22.7613 13.3334 24.9997 16.3167 24.9997 20C24.9997 23.6834 22.7613 26.6667 19.9997 26.6667C18.253 26.6667 16.7147 25.4717 15.8197 23.6617L15.6697 23.3334H12.1413C13.283 27.2167 16.3713 30 19.9997 30C24.6013 30 28.333 25.5234 28.333 20C28.333 14.4767 24.6013 10 19.9997 10Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2137_12299">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
