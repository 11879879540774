import { Typography } from '@mui/material';
import { formatCurrency } from 'assets/utils';
import { transactionStatus } from '../Constant/transactionStatus';

export const TransactionAmount = ({
  isLargeFont,
  isCenter,
  amount,
  fee,
  transaction_type,
  isPositive,
  transaction_status,
}) => {
  if (transaction_status !== transactionStatus.COMPLETED) {
    return (
      <Typography
        fontSize={isLargeFont ? 36 : 18}
        fontWeight={600}
        textAlign={isCenter ? 'center' : 'right'}
      >
        {formatCurrency(amount + fee)}
      </Typography>
    );
  }

  return isPositive ? (
    <Typography
      fontSize={isLargeFont ? 36 : 18}
      fontWeight={600}
      textAlign={isCenter ? 'center' : 'right'}
      className="text-primary"
    >
      + {formatCurrency(amount - (transaction_type === 'LOAD_MONEY' ? fee : 0))}
    </Typography>
  ) : (
    <Typography
      fontSize={isLargeFont ? 36 : 18}
      fontWeight={600}
      textAlign={isCenter ? 'center' : 'right'}
      className="text-danger"
    >
      - {formatCurrency(amount + fee)}
    </Typography>
  );
};
