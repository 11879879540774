import { PaymentItem, PaymentItemIconContainer } from '../styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CustomSwitch } from './styles';
import { palette } from 'theme/foundations';

const CardsManagePaymentMethodModalItem = ({
  Icon,
  text,
  onSwitch,
  isChecked,
}) => {
  const handleChange = (event) => {
    onSwitch(event.target.checked);
  };
  return (
    <PaymentItem>
      <Box display="flex" alignItems="center">
        <PaymentItemIconContainer>
          <Icon
            style={{
              color: palette.black400.main,
            }}
          />
        </PaymentItemIconContainer>
        <Typography variant="cardsPaymentItem">{text}</Typography>
      </Box>
      <CustomSwitch onChange={handleChange} />
    </PaymentItem>
  );
};

export default CardsManagePaymentMethodModalItem;
