import { formatCurrency } from 'assets/utils';
import { useAccountLoadSocket } from 'jsx/hooks/useAccountLoadSocketUpdate';
import React, { Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFetchUserLedgerQuery } from 'services/Ledger';

import styled from 'styled-components';
import getLedgerSort from 'utils/getLedgerSort';

import accountList from '../Shared/account-list';
import SwitchLedger, { useSwitchLedger } from './SwitchAccount';

const AssociationText = styled.span`
  opacity: 0.6;
`;

const ActiveIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--primary);
`;

const LedgerItem = styled.a`
  &:hover,
  &:focus {
    ${ActiveIcon} {
      background-color: var(--white);
    }
  }
`;

function AccountListSidebar({ onAccountClick }) {
  const history = useHistory();
  const { isLoading } = useFetchUserLedgerQuery();
  const { userLedgerType } = getLedgerSort();
  useAccountLoadSocket();

  const {
    isModalOpen,
    switchLedgerName,
    isSwitchingLedger,
    handleModalClose,
    openSwitchLedgerModal,
    onLedgerSwitch,
  } = useSwitchLedger({ onAccountClick });

  const redirectUser = (e) => {
    e.preventDefault();
    onAccountClick();
    history.push('/');
  };

  return isLoading ? null : (
    <Fragment>
      {userLedgerType
        .filter((l) => l)
        .map((ledger) => {
          const accountType =
            accountList.find(
              (acc) => acc.asset_type === ledger.asset_type.toLowerCase()
            ) || {};

          return (
            <li key={ledger} className="mb-1 ml-3 hide-collapse">
              <LedgerItem
                className="d-flex align-items-center cursor-pointer sidebar-account"
                href="#"
                title={ledger.ledger_meta?.association}
                onClick={
                  ledger.active ? redirectUser : openSwitchLedgerModal(ledger)
                }
              >
                <div
                  className="flag-img mr-2"
                  style={{
                    backgroundImage: `url(${accountType.flag})`,
                  }}
                />

                <div className="d-flex flex-column flex-grow-1 w-50">
                  <span className="fs-14">
                    {formatCurrency(ledger.amount, ledger.asset_type)}
                  </span>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {ledger.ledger_meta?.association || accountType.symbol}
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <AssociationText
                        ref={ref}
                        className="fs-12 text-truncate nav-text"
                        {...triggerHandler}
                      >
                        {ledger.ledger_meta?.association || accountType.symbol}
                      </AssociationText>
                    )}
                  </OverlayTrigger>
                </div>

                {ledger.active && (
                  <ActiveIcon className="active-icon" title="Active Account" />
                )}
              </LedgerItem>
            </li>
          );
        })}

      <SwitchLedger
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        accountName={switchLedgerName}
        isSwitchingLedger={isSwitchingLedger}
        switchLedger={onLedgerSwitch}
      />
    </Fragment>
  );
}

export default AccountListSidebar;
