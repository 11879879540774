import { Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TransferPaymentSentModalName,
  TransferPinResetModalName,
} from 'store/actions/ModalActions/ModalActionTypes';
import { ModalCloseAction } from 'store/actions/ModalActions/ModalCloseAction';
import { ModalOpenAction } from 'store/actions/ModalActions/ModalOpenAction';

import {
  Actions,
  AdminVerificationOtpContainer,
  MinWidth,
  ReceiveCode,
  RecendCode,
  TransferVerificationContainer,
  TransferVerificationContent,
  VerificationContent,
} from './styles';
import { useAdminTransferSmsVerificationMutation } from 'services/Transfer';
import swal from 'sweetalert';
import { VerifyBlock } from '../../pages/EmailVerify/styles';
import AdminOtpVerification from '../CodeVerification/AdminOtpVerification';

const AdminTransferOtpVerificationModal = ({ content }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [passcode, setPasscode] = useState('');
  const [isButtonClick, setIsButtonClick] = useState(false);

  const { mutateAsync: adminTransferSmsVerification } =
    useAdminTransferSmsVerificationMutation();

  const resendOtp = async () => {
    // setIsLoading(true);
    // try {
    //   const transferConsentConfirmationData = await transferConsentConfirmation(
    //     {
    //       ephemeral_token: content?.token,
    //       factor: 'sms',
    //       passcode: passcode,
    //     }
    //   );
    //   setResponseDetails(transferConsentConfirmationData?.data);
    // } catch (error) {
    //   swal({
    //     title: 'Error',
    //     icon: 'error',
    //     text: error.response?.data?.errors[0]?.message,
    //     button: 'Try Again',
    //   });
    // } finally {
    //   setIsLoading(false);
    // }
  };
  const handleTransferVerificationSubmit = async (values) => {
    if (!passcode || passcode.length < 6) return;
    setIsLoading(true);
    try {
      const apiResponse = await adminTransferSmsVerification({
        bicSwift: content?.bic,
        ledgerFromId: content?.transferFrom,
        amount: content?.transferAmount,
        receiverName: content?.recopientName,
        receiverIban: content?.iban,
        description: content?.description,
        otp: passcode,
        currency: content?.currency,
      });
      if (apiResponse.status === 200) {
        dispatch(
          ModalOpenAction({
            modalName: TransferPaymentSentModalName,
            content: {
              amount: content?.transferAmount * 100,
              currency: content?.currency,
            },
          })
        );
      }
    } catch (error) {
      swal({
        title: 'Error',
        icon: 'error',
        text: error.response?.data?.errors[0]?.message,
        button: 'Try Again',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const isValueTrue =
    passcode.length >= 0 && passcode.length < 6 && isButtonClick;
  const handleCancel = () => {
    dispatch(ModalCloseAction());
  };
  function handleResetPasscode() {
    dispatch(
      ModalOpenAction({
        modalName: TransferPinResetModalName,
        content: { ...content },
      })
    );
  }
  return (
    <Formik
      initialValues={{
        sms: '',
        passcode: '',
      }}
      onSubmit={handleTransferVerificationSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <TransferVerificationContainer>
              <VerificationContent>
                <Typography variant="selectOptionText">
                  Enter your verification code
                </Typography>
                <TransferVerificationContent />
                <Typography variant="phoneVerifyTitle">
                  We've sent an SMS with a verification code to your phone{' '}
                  {content?.mobileNumber}
                </Typography>
                <MinWidth />
                <Typography
                  variant="verifyTimeLeft"
                  mb="0.625rem"
                  mt="0.44rem"
                ></Typography>
                <AdminVerificationOtpContainer>
                  <VerifyBlock>
                    <AdminOtpVerification
                      value={passcode}
                      onChange={(value) => setPasscode(value)}
                      hasErrored={isValueTrue}
                    />
                  </VerifyBlock>
                </AdminVerificationOtpContainer>

                {/*<Typography variant="verifyTimeLeft">*/}
                {/*  Your code is: {content?.otp} (SMS are sent on production*/}
                {/*  environments only)*/}
                {/*</Typography>*/}
                <ReceiveCode>
                  <Typography variant="didntReceiveSms">
                    Did not receive SMS?
                  </Typography>
                  <Typography>&nbsp;</Typography>
                  <RecendCode>
                    <a onClick={resendOtp}>Resend a code</a>
                  </RecendCode>
                </ReceiveCode>
              </VerificationContent>
              <Actions>
                <LoadingButton
                  variant="contained"
                  sx={{
                    maxWidth: '9.375rem',
                  }}
                  type="submit"
                  onClick={() => setIsButtonClick(true)}
                  loading={isLoading}
                >
                  Confirm
                </LoadingButton>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    maxWidth: '9.375rem',
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Actions>
            </TransferVerificationContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminTransferOtpVerificationModal;
