import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {
  EEACountries,
  ISOCountry,
  CountriesNationality,
} from 'assets/ISO_country';
import { useFormikContext } from 'formik';
import PhoneInputFormik from 'jsx/components/PhoneInput/PhoneInputFormik';
import { InputLabel } from 'jsx/components/TextFieldFormik/styles';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import SelectMenu from 'jsx/components/UI/Select';

function ProfileUpdateForm({ isBusinessProfile, isSavedOnce }) {
  const formik = useFormikContext();
  const { palette } = useTheme();

  const ISOCountryCodes = ISOCountry.filter((country) =>
    EEACountries.includes(country.alpha_2)
  ).map((code) => ({
    label: code.name,
    value: code.alpha_2,
  }));

  return (
    <Grid container spacing={2}>
      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <TextFieldFormik
            label="EMAIL"
            type="text"
            name="email"
            placeholder="Email"
            fullWidth
            disabled
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6}>
        <Stack>
          <PhoneInputFormik name="phone_number" label="PHONE NUMBER" disabled />
        </Stack>
      </Grid>

      {isBusinessProfile && (
        <>
          <Grid item mobile={12} tabletS={6} width="100%">
            <Stack>
              <TextFieldFormik
                label="COMPANY NAME"
                type="text"
                name="company_name"
                placeholder="Company name"
                fullWidth
              />
            </Stack>
          </Grid>

          <Grid item mobile={12} tabletS={6} width="100%">
            <Stack>
              <TextFieldFormik
                label="REGISTRATION NUMBER"
                type="text"
                name="company_registration_number"
                placeholder="Registration Number"
                fullWidth
              />
            </Stack>
          </Grid>
        </>
      )}

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <TextFieldFormik
            label="FIRST NAME"
            type="text"
            name="first_name"
            placeholder="First name"
            fullWidth
            disabled={isSavedOnce}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <TextFieldFormik
            label="LAST NAME"
            type="text"
            name="last_name"
            placeholder="Last name"
            fullWidth
            disabled={isSavedOnce}
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <TextFieldFormik
            label="BIRTHDATE"
            type="date"
            name="birthday"
            placeholder="Birthdate"
            max={new Date().toISOString().split('T')[0]}
            fullWidth
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <InputLabel>COUNTRY</InputLabel>
          <SelectMenu
            options={ISOCountryCodes}
            value={ISOCountryCodes.find(
              (country) => country.value === formik.values.address_country
            )}
            onChange={(option) =>
              formik.setFieldValue('address_country', option?.value)
            }
            isRounded
          />
          {!!formik.errors.address_country &&
            !!formik.touched.address_country && (
              <FormHelperText sx={{ color: palette.error.main }}>
                {formik.errors.address_country}
              </FormHelperText>
            )}
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <TextFieldFormik
            label="CITY"
            type="text"
            name="address_city"
            placeholder="City"
            fullWidth
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <TextFieldFormik
            label="POSTAL/ZIP CODE"
            type="text"
            name="address_postal_code"
            placeholder="Postal/Zip Code"
            fullWidth
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <TextFieldFormik
            label="STREET"
            type="text"
            name="address_street"
            placeholder="Street"
            fullWidth
          />
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={6} width="100%">
        <Stack>
          <InputLabel>NATIONALITY</InputLabel>
          <SelectMenu
            options={CountriesNationality}
            value={CountriesNationality.find(
              (language) => language.value === formik.values.nationality
            )}
            onChange={(option) =>
              formik.setFieldValue('nationality', option?.value)
            }
            isRounded
          />
          {!!formik.errors.nationality && !!formik.touched.nationality && (
            <FormHelperText sx={{ color: palette.error.main }}>
              {formik.errors.nationality}
            </FormHelperText>
          )}
        </Stack>
      </Grid>

      <Grid item mobile={12} tabletS={12}>
        <FormGroup
          error={
            !!formik.errors.accept_terms_condition &&
            !!formik.touched.accept_terms_condition
          }
        >
          <FormControlLabel
            sx={{ alignItems: 'flex-start', m: 0 }}
            control={
              <Checkbox
                disabled={isSavedOnce}
                onChange={(e) =>
                  formik.setFieldValue(
                    'accept_terms_condition',
                    e.target.checked
                  )
                }
                checked={!!formik.values.accept_terms_condition}
              />
            }
            label={
              <Stack direction="row" ml={1}>
                <Typography>
                  I have read, understood and agree to Tirnu's{' '}
                  <Link
                    href="https://tirnu.com/terms-conditions/"
                    target="_blank"
                  >
                    terms and conditions
                  </Link>{' '}
                  and{' '}
                  <Link href="https://tirnu.com/privacy/" target="_blank">
                    privacy policy
                  </Link>
                  .
                </Typography>
              </Stack>
            }
          />
          <FormHelperText sx={{ color: palette.error.main }}>
            {formik.errors.accept_terms_condition}
          </FormHelperText>
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default ProfileUpdateForm;
