import { ThemeContext } from 'context/ThemeContext';
import React, { useContext, useEffect, useRef } from 'react';
import { useFetchUserLedgerQuery, useLedgerDetailQuery } from 'services/Ledger';
import { io } from 'socket.io-client';

export const useAccountLoadSocket = () => {
  const socket = useRef();
  const { state, Ledger } = useContext(ThemeContext);
  const { refetch } = useLedgerDetailQuery(Ledger?.ledger_id);
  const { refetch: refetchLedgers } = useFetchUserLedgerQuery();

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BACKEND_API, {
      // transports: ['websocket'],
      // reconnectionAttempts: 2,
      // upgrade: false,
      auth: {
        token: state.auth.accessToken,
      },
    });

    socket.current.on('topup', () => {
      refetch();
      refetchLedgers();
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);
};
