import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from './Api';
import { TirnuClient } from './Axios';

const fetchLedgerByIBAN = (iban) => async () => {
  return await TirnuClient.post(API.v1.ledger.fetchByIBAN, { iban });
};

const fetchUserLedgers = () => {
  return TirnuClient.get(API.v1.ledger.fetch);
};

const switchLedger = ({ ledgerId }) => {
  return TirnuClient.patch(API.v1.ledger.switch, { ledgerId });
};

const createLedger = ({ ledgerAssetType, association }) => {
  return TirnuClient.post(API.v1.ledger.create, {
    ledgerAssetType,
    association,
  });
};

const fetchLedgerDetail = async (ledger_id) => {
  const { data } = await TirnuClient.post(API.v1.ledger.fetchDetail, {
    ledger_id,
  });

  return data;
};

const checkLedgerIBAN = ({ ledgerId }) => {
  return TirnuClient.get(API.v1.ledger.checkIBAN, { params: { ledgerId } });
};

const generateAccountChargeToken = ({ amount, ledgerId }) => {
  return TirnuClient.post(API.v1.ledger.generateAccountChargeToken, {
    amount,
    ledgerId,
  });
};

const getSavedCardList = () => {
  return TirnuClient.get(API.v1.ledger.getSavedCards);
};

/**
 * Create End User Ledger
 */
const useCreateLedgerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(API.v1.ledger.create, createLedger, {
    placeholderData: null,
    select: ({ data }) => data || null,
    onSuccess: () => {
      // Refetch ledger list
      queryClient.refetchQueries(API.v1.ledger.fetch);

      swal({
        title: 'Success',
        icon: 'success',
        text: `Ledger was successfully created!`,
        buttons: 'Close',
      });
    },
    onError: (error) => {
      swal({
        title: 'Failed!',
        text: error.response?.data?.message?.error || `Ledger creation failed!`,
        buttons: 'Close',
      });
      console.log(error);
    },
  });
};

/**
 * Fetch End User Ledgers
 */
const useFetchUserLedgerQuery = (enabled) => {
  return useQuery(API.v1.ledger.fetch, fetchUserLedgers, {
    // Done to prevent render crash
    enabled: enabled !== false,
    select: ({ data }) => [...(data?.data?.slice().reverse() || [])],
    onError: (error) => {
      console.log(error);
    },
  });
};

/**
 * Fetch Ledger Detail
 */
const useLedgerDetailMutation = (disableRefetchLedgers) => {
  const queryClient = useQueryClient();

  return useMutation(API.v1.ledger.fetchDetail, fetchLedgerDetail, {
    onSuccess: (data) => {
      // Refetch all ledgers
      if (!disableRefetchLedgers) {
        queryClient.refetchQueries(API.v1.ledger.fetch);
      }

      // Set Ledger detail as a cache
      queryClient.setQueryData(
        [API.v1.ledger.fetchDetail, data.ledger_id],
        data
      );
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

/**
 * Get Ledger Detail
 */
const useLedgerDetailQuery = (ledger_id) => {
  return useQuery(
    [API.v1.ledger.fetchDetail, ledger_id || ''],
    async () => await fetchLedgerDetail(ledger_id),
    {
      enabled: !!ledger_id,
      keepPreviousData: true,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

/**
 * Switch Active End User Ledger
 */
const useSwitchLedgerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(API.v1.ledger.switch, switchLedger, {
    onSuccess: () => {
      // Refetch ledger list
      queryClient.refetchQueries(API.v1.ledger.fetch);

      swal({
        title: 'Success',
        icon: 'success',
        text: `Account switch successful!`,
        buttons: 'Close',
      });
    },
    onError: (error) => {
      console.log(error);
      swal({
        title: 'Failure!',
        text: error.response?.data?.message?.error || `Account switch failed!`,
        buttons: 'Close',
      });
    },
  });
};

/**
 * Get Ledger Detail By IBAN
 */
const useLedgerByIBANQuery = (iban) => {
  return useQuery([API.v1.ledger.fetchByIBAN, iban], fetchLedgerByIBAN(iban), {
    enabled: !!iban,
    placeholderData: {},
    select: ({ data }) => data || {},
    onError: (error) => {
      console.log(error);
    },
  });
};

/**
 * Update Ledger Detail IBAN, BIC/Swift, UK Sort Code, UK Account Number
 */
const useCheckLedgerIBANQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(API.v1.ledger.checkIBAN, checkLedgerIBAN, {
    onSuccess: () => {
      // Refetch ledger list
      queryClient.refetchQueries(API.v1.ledger.fetch);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const transactionCurrencyMapper = {
  EUR: '€',
};

const useGetLedgersOptions = (enabled) => {
  const { data: userLedgers = [] } = useFetchUserLedgerQuery(enabled);

  const ledgersOptions = userLedgers.map((ledger) => {
    return {
      value: ledger?.ledger_id,
      label: ledger?.ledger_meta.association,
      amount: ledger?.amount,
      asset_type: ledger?.asset_type,
      iban: ledger?.iban,
      bic_swift: ledger?.bic_swift,
    };
  });

  return { ledgersOptions };
};

const useGetLedgersOptionsById = (ledger_id) => {
  const { data: ledgerDataById, isFetching } = useLedgerDetailQuery(ledger_id);
  const ledgersOptionsById = {
    value: ledgerDataById?.ledger_id,
    label: ledgerDataById?.ledger_meta.association,
    amount: ledgerDataById?.amount,
    asset_type: ledgerDataById?.asset_type,
    iban: ledgerDataById?.iban,
    bic_swift: ledgerDataById?.bic_swift,
  };

  return { ledgersOptionsById, isFetching };
};

const useGetCurrencyOptions = () => {
  const currencyOptions = [
    {
      value: 'EUR',
      label: 'EUR (Euro)',
    },
  ];
  return { currencyOptions };
};

/**
 * Generate account token for topup
 */
const useGenAccountChargeToken = () => {
  return useMutation(generateAccountChargeToken, {
    onError: (error) => {
      swal({
        title: 'Failure!',
        text:
          error.response?.data?.message?.error || `Failed to topup account!`,
        buttons: 'Close',
      });
    },
  });
};

/**
 * Get saved card list
 */
const useGetSavedCards = () => {
  return useQuery(API.v1.ledger.getSavedCards, getSavedCardList, {
    onError: (error) => {
      swal({
        title: 'Failure!',
        text:
          error.response?.data?.message?.error ||
          `Failed to fetch saved cards!`,
        buttons: 'Close',
      });
    },
  });
};

export {
  useCreateLedgerMutation,
  useLedgerByIBANQuery,
  useFetchUserLedgerQuery,
  useCheckLedgerIBANQuery,
  useSwitchLedgerMutation,
  useLedgerDetailMutation,
  useLedgerDetailQuery,
  useGetLedgersOptions,
  useGetCurrencyOptions,
  useGetLedgersOptionsById,
  useGenAccountChargeToken,
  useGetSavedCards,
};
