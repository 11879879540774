import {
  SET_CARDS_DATA_ACTION,
  SET_SELECT_CARD_DATA_ACTION,
  CHANGE_CARD_STATUS,
} from './CardsActionsTypes';

export function SetCardsDataAction(payload) {
  return {
    type: SET_CARDS_DATA_ACTION,
    payload,
  };
}

export function SetSelectCardDataAction(payload) {
  return {
    type: SET_SELECT_CARD_DATA_ACTION,
    payload,
  };
}

export function ChangeCardStatusAction(payload) {
  return {
    type: CHANGE_CARD_STATUS,
    payload,
  };
}
