import DotsLoader from 'jsx/components/Loader/DotsLoader';
import TransferDetailsCard from 'jsx/components/TransferDetailsCard/TransferDetailsCard';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetBanks } from 'services/SendMoney';
import { internationalCountries } from '.';
import { InternationalStep2Container } from './styles';

const InternationalStep2 = ({ store, prevStep, dispatchs }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (store?.transferFrom?.value && !store?.isFetching) {
      setIsLoading(false);
    }
  }, [store?.transferFrom?.value, store?.isFetching]);

  const [sendInternationalData, setSendInternationalData] = useState(
    store?.sendInternationalData
  );
  const transferFrom = store?.transferFrom;
  const { data: bankList = [] } = useGetBanks(
    store?.sendInternationalData?.transferToCountry
  );

  const handleSubmit = async () => {
    dispatchs.handleInternationalTransfer();
  };

  const handlePrevStep = () => {
    prevStep();
  };

  const handleCancel = () => {
    history.go(0);
  };

  useEffect(() => {
    if (
      store?.sendInternationalData?.transferToBank &&
      store?.sendInternationalData?.transferToCountry
    ) {
      const bankName = bankList.find(
        (b) => b._id === store?.sendInternationalData?.transferToBank
      )?.name;
      setSendInternationalData((prev) => ({
        ...prev,
        transferToCountry: internationalCountries.find(
          (c) => c.value === store?.sendInternationalData?.transferToCountry
        )?.label,
        transferToBank: bankName,
      }));
    }
  }, [bankList]);

  return (
    <InternationalStep2Container>
      {isLoading || (store?.isFetching && <DotsLoader />)}

      {!(isLoading || store?.isFetching) && (
        <TransferDetailsCard
          transferInternational
          transferInternationalRecipient={sendInternationalData}
          transferAmount={sendInternationalData?.transferAmount}
          transferFee={sendInternationalData?.transferFee}
          currency={sendInternationalData?.currency}
          description={sendInternationalData?.description}
          transferFrom={transferFrom}
          onCancel={handleCancel}
          onPrevStep={handlePrevStep}
          onSubmit={handleSubmit}
          isLoading={store?.isLoadingTransfer}
          errors={store?.API_Errors}
        />
      )}
    </InternationalStep2Container>
  );
};

export default InternationalStep2;
