import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Nepal from 'images/sendMoney/nepal.png';
import India from 'images/sendMoney/india.png';

export const TransferCountry = ({ option }) => {
  const { label, value } = option;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {value === 'np' && (
        <img src={Nepal} style={{ width: 20, height: 20 }} alt="nepal_flag" />
      )}
      {value === 'in' && (
        <img src={India} style={{ width: 20, height: 20 }} alt="india_flag" />
      )}
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
