import axios from 'axios';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikProvider, Form } from 'formik';
import * as yup from 'yup';

import { ThemeContext } from '../../../context/ThemeContext';
import {
  useFetchKYCStatus,
  useInitiateKYC,
  useSetKYCVerified,
} from 'services/User';
import Nav from '../../layouts/nav';
import ScrollToTop from '../../layouts/ScrollToTop';
import ProfileUpdateForm from './ProfileUpdateForm';
import StatusModal from './StatusModal';
import '../../step.css';
import { grey } from '@mui/material/colors';
import { IconWrapper } from './Icon/IconWrapper';
import { VerifiedIcon } from './Icon/Verified';
import { VerifyIcon } from './Icon/Verify';
import { ReviewIcon } from './Icon/Review';

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .max(40, 'First name should be less than 40 characters')
    .required('First Name is required'),
  last_name: yup
    .string()
    .max(40, 'Last name should be less than 40 characters')
    .required('Last Name is required'),
  email: yup.string().required('Email is required'),
  phone_number: yup.string().required('Phone Number is required'),
  phone_number_code: yup.string().required('Phone Number Code is required'),

  birthday: yup
    .string()
    .required('Birthday is required')
    .test({
      name: 'noFutureDate',
      test: (value) => new Date().getTime() > new Date(value).getTime(),
      message: 'Please select a past date',
    })
    .test({
      name: 'validAge',
      test: (value) => getAge(value) > 18,
      message: 'Users below 18 years old cannot open a bank account',
    }),
  address_street: yup
    .string()
    .required('Street is required')
    .min(3, 'Please enter a street name with more than 3 characters'),
  address_city: yup
    .string()
    .required('City is required')
    .matches(/^[A-Za-z ]+$/, 'City can be only alphabets')
    .min(3, 'Please enter city name with more than 3 characters'),
  address_postal_code: yup
    .string()
    .required('Postal/zip code is required')
    .matches(/^[0-9]+$/, 'Postal/Zip code should be only numbers'),
  address_country: yup.string().required('Country is required'),
  nationality: yup.string().required('Nationality is required'),
  accept_terms_condition: yup
    .bool()
    .oneOf([true], 'Please accept the terms and conditions.'),
});

const ProfileUpdate = () => {
  const { path, activeEvent, setActiveEvent, state, user, initUserDetails } =
    useContext(ThemeContext);
  const location = useLocation();
  const socket = useRef();

  const { mutateAsync: setKYCVerified, isLoading } = useSetKYCVerified();
  const { mutateAsync: initiateKYC, data: kycInfo } = useInitiateKYC();
  const {
    data: kycStatus,
    refetch: fetchKYCStatus,
    isSuccess: isKycStatusFetched,
  } = useFetchKYCStatus();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tabletS'));

  const [initKYC, setInitKYC] = useState(false);

  const [loading, setLoading] = useState(false);

  const [initKYCVerifyModal, setInitKYCVerifyModal] = useState(false);
  const [initKYCReviewModal, setInitKYCReviewModal] = useState(false);
  const [initKYCVerifiedModal, setInitKYCVerifiedModal] = useState(false);

  let initialProfilePage = location.pathname.includes('/profile-update');
  const isBusinessProfile = user?.accountType === 'business';

  const KYCStatus = useMemo(() => {
    let status = 'pending';
    switch (kycStatus) {
      /** User details not filled */
      case 'pending': {
        status = 'pending';
        break;
      }
      /** User details filled and able to initiate KYC */
      case 'initiable': {
        status = 'initiable';
        break;
      }
      /** KYC is on hold */
      case 'review': {
        status = 'review';
        break;
      }
      /** KYC is on hold */
      case 'hold': {
        status = 'hold';
        break;
      }
      /** KYC completed */
      case 'approved': {
        status = 'approved';
        break;
      }
      /** KYC rejected */
      case 'rejected': {
        status = 'rejected';
        break;
      }
    }

    return status;
  }, [kycStatus]);

  const onSubmit = async (data) => {
    try {
      await submitPersonalInfo({
        first_name: data.first_name,
        last_name: data.last_name,
        birthday: data.birthday,
        address_street: data.address_street,
        address_city: data.address_city,
        address_postal_code: data.address_postal_code,
        address_country: data.address_country,
        nationality: data.nationality,
        accept_terms_condition: data.accept_terms_condition,
        ...(isBusinessProfile
          ? {
              company_name: data.company_name,
              company_registration_number: data.company_registration_number,
            }
          : {}),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fromikBag = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (user) {
      fromikBag.resetForm({
        values: {
          first_name: user.full_name?.first_name || '',
          last_name: user.full_name?.last_name || '',
          company_name: user.company_name || '',
          company_registration_number: user.company_registration_number || '',
          email: user.email,
          phone_number:
            user.phone_number_code.replace('+', '') + user.phone_number,
          phone_number_code: user.phone_number_code,
          birthday: user.date_of_birth,
          address_street: user.address?.address_street || '',
          address_city: user.address?.address_city || '',
          address_postal_code: user.address?.address_postal_code || '',
          address_country: user.address?.address_iso_country || '',
          nationality: user.nationality?.[0] || '',
          accept_terms_condition: user.accept_terms_condition ?? false,
        },
      });
    }
  }, [user]);

  const submitPersonalInfo = async (data) => {
    setLoading(true);

    axios
      .put(`${process.env.REACT_APP_BACKEND_API}/api/v1/user/self`, data, {
        headers: {
          Authorization: `Bearer ${state.auth.accessToken}`,
          'x-refresh': `${state.auth.refreshToken}`,
        },
      })
      .then(async () => {
        await fetchKYCStatus();
        initUserDetails();
        swal({
          title: `Profile Save Success`,
          icon: `success`,
          text: `Your profile has been saved successfully`,
          button: true,
        });
      })
      .catch((error) => {
        swal({
          title: `Profile Update Failed`,
          icon: `error`,
          text: `${error.response.data.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initKYCProcess = async () => {
    try {
      if (!initKYC) {
        await initiateKYC();
        setInitKYC(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initKYCVerifiedState = async () => {
    try {
      await setKYCVerified();
      initUserDetails();
    } catch (error) {}
  };

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BACKEND_API, {
      // transports: ['websocket'],
      // reconnectionAttempts: 2,
      // upgrade: false,
      auth: {
        token: state.auth.accessToken,
      },
    });

    socket.current.on('onboarding', (onboardingStatus) => {
      if (['review', 'approved', 'rejected'].includes(onboardingStatus)) {
        fetchKYCStatus();
        if (onboardingStatus === 'approved') {
          setInitKYCVerifyModal(false);
          setInitKYCReviewModal(false);
          setInitKYCVerifiedModal(true);
        }
      }
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isKycStatusFetched && KYCStatus === 'initiable') {
      initKYCProcess();
    }
  }, [isKycStatusFetched, KYCStatus]);

  useEffect(() => {
    if (KYCStatus === 'review') {
      setInitKYCVerifyModal(false);
      setInitKYCReviewModal(true);
    }
  }, [KYCStatus]);

  useEffect(() => {
    if (kycInfo?.data?.redirect_url) {
      setInitKYCVerifyModal(true);
    }
  }, [kycInfo]);

  useEffect(() => {
    if (user?.kyc.showVerified) {
      setInitKYCVerifyModal(false);
      setInitKYCReviewModal(false);
      setInitKYCVerifiedModal(true);
    }
  }, [user]);

  return (
    <div>
      <div
        id={`${initialProfilePage ? 'main-wrapper' : ''}`}
        className={`${initialProfilePage ? 'show' : 'mh100vh'}`}
      >
        {initialProfilePage && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(!activeEvent)}
            onClick3={() => setActiveEvent(true)}
          />
        )}

        <div
          className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
            initialProfilePage ? 'content-body' : ''
          }`}
        >
          <div
            className={initialProfilePage ? 'container-fluid' : ''}
            style={{ minHeight: window.screen.height - 160 }}
          >
            <Card
              sx={{
                mx: 4,
                py: 5,
                boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
                borderRadius: 4,
              }}
            >
              <FormikProvider value={fromikBag}>
                <Form onSubmit={fromikBag.handleSubmit}>
                  <CardContent
                    sx={{
                      maxWidth: '80%',
                      margin: 'auto',
                    }}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      align="center"
                      fontWeight="medium"
                      mb={6}
                    >
                      Your Basic Information
                    </Typography>

                    {isKycStatusFetched &&
                      ['pending', 'initiable'].includes(KYCStatus) && (
                        <Typography
                          variant="h5"
                          gutterBottom
                          align="center"
                          fontWeight="medium"
                          mx="auto"
                          mb={6}
                          color="var(--primary)"
                          sx={{ maxWidth: '60%' }}
                        >
                          To verify your personal identity please fill out the
                          information below
                        </Typography>
                      )}

                    <Box
                      sx={{
                        maxWidth: isMobile ? '80%' : '60%',
                        margin: 'auto',
                      }}
                    >
                      <ProfileUpdateForm
                        isBusinessProfile={isBusinessProfile}
                        isSavedOnce={KYCStatus === 'initiable'}
                      />
                    </Box>
                  </CardContent>

                  <CardActions
                    sx={{
                      maxWidth: '60%',
                      margin: 'auto',
                      pr: 2,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      component={LoadingButton}
                      loading={loading}
                      variant="contained"
                      type="submit"
                      size="small"
                      sx={{ maxWidth: 150 }}
                    >
                      {!loading && 'Save'}
                    </Button>
                  </CardActions>
                </Form>
              </FormikProvider>
            </Card>

            <StatusModal
              open={initKYCVerifyModal}
              handleClose={() => setInitKYCVerifyModal(false)}
              illustration={
                <Stack alignItems="center">
                  <IconWrapper>
                    <VerifyIcon />
                  </IconWrapper>
                </Stack>
              }
              title="Verify your identity"
              children={
                <Stack alignItems="center" gap={3}>
                  <Typography color={grey[700]} textAlign="center">
                    We need to collect some personal information to verify your
                    identity before we can open your account
                  </Typography>

                  <Button
                    variant="contained"
                    onClick={() =>
                      window.open(kycInfo?.data?.redirect_url, '_self')
                    }
                    sx={{ maxWidth: 250, textTransform: 'none' }}
                  >
                    Start Identity Verification
                  </Button>
                </Stack>
              }
            />

            <StatusModal
              open={initKYCReviewModal}
              handleClose={() => setInitKYCReviewModal(false)}
              illustration={
                <Stack alignItems="center">
                  <IconWrapper>
                    <ReviewIcon />
                  </IconWrapper>
                </Stack>
              }
              title="Your application is under review"
              children={
                <Stack alignItems="center" gap={3}>
                  <Typography color={grey[700]} textAlign="center">
                    We’ll get back to you within 1 business day. You can log in
                    to check your application status at any time
                  </Typography>
                </Stack>
              }
            />

            <StatusModal
              open={initKYCVerifiedModal}
              handleClose={() => setInitKYCVerifiedModal(false)}
              illustration={
                <Stack alignItems="center">
                  <IconWrapper>
                    <VerifiedIcon />
                  </IconWrapper>
                </Stack>
              }
              title="Verified"
              children={
                <Stack alignItems="center" gap={3}>
                  <Typography color={grey[700]} textAlign="center">
                    Your identity has been verified
                  </Typography>

                  <Button
                    component={LoadingButton}
                    loading={isLoading}
                    variant="contained"
                    onClick={initKYCVerifiedState}
                    sx={{ maxWidth: 200, textTransform: 'none' }}
                  >
                    Go to Dashboard
                  </Button>
                </Stack>
              }
            />
          </div>
        </div>
      </div>

      <ScrollToTop />
    </div>
  );
};

export default ProfileUpdate;
