import { useFetchNotificationList } from '../../../services/notification';
import { formatLocaleDateTime, getInitials } from '../../../assets/utils';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import axios from 'axios';
import { API } from '../../../services/Api';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotificationList = () => {
  const { state } = useContext(ThemeContext);
  const { isLoading, data, refetch } = useFetchNotificationList();

  if (isLoading) {
    return <h5>Loading...</h5>;
  }

  if (data?.length === 0) {
    return (
      <EmptyNotification>
        <h6>No Data Available</h6>
      </EmptyNotification>
    );
  }

  const updateNotificationStatus = (notificationId, isSeen) => () => {
    if (!isSeen) {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_API}${API.v1.notification.updateStatus}/${notificationId}/notification`,
          '',
          {
            headers: {
              Authorization: `Bearer ${state.auth.accessToken}`,
              'x-refresh': `${state.auth.refreshToken}`,
            },
          }
        )
        .then(refetch)
        .catch(console.log);
    }
  };

  return (
    <>
      <div className="widget-media dz-scroll p-3 ps overflow-auto max-height380">
        <ul className="timeline">
          {data?.map((value) => (
            <li
              key={value._id}
              style={{ cursor: 'pointer' }}
              onClick={updateNotificationStatus(value._id, value.is_seen)}
            >
              <div className="timeline-panel">
                <div className="d-flex align-items-center">
                  <ImagePlaceholder>
                    {getInitials(value.topic)}
                  </ImagePlaceholder>
                </div>
                <div className="media-body">
                  <h6
                    className={
                      value.is_seen
                        ? 'text-muted mb-1 text-capitalize'
                        : 'mb-1 text-capitalize'
                    }
                  >
                    {value.message}
                  </h6>
                  <small className="d-block">
                    {formatLocaleDateTime(value.createdAt)}
                  </small>
                </div>
                {!value.is_seen && (
                  <div
                    className="sc-bBrHrO bUPoHS active-icon"
                    title="Unseen Notification"
                  ></div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Link className="all-notification" to={'/notifications'}>
        See all notifications <i className="ti-arrow-right" />
      </Link>
    </>
  );
};

const EmptyNotification = styled.div`
  position: absolute;
  top: 50%;
  padding: 10px;
  background: #fff;
  width: 100%;
  text-align: center;
`;
const ImagePlaceholder = styled.div`
  min-width: 45px;
  height: 45px;
  border-radius: 100%;
  background: var(--primary);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: var(--white);
  margin-right: 15px;

  @media (max-width: 550px) {
    width: 30px;
    height: 30px;
  }
`;

export default NotificationList;
