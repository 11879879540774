import React from 'react';

function useTokenDecoder() {
  const decode = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    try {
      return JSON.parse(jsonPayload);
    } catch (error) {
      return null;
    }
  };

  return { decode };
}

export default useTokenDecoder;
