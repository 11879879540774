import React from 'react';
import { Box } from '@mui/material';

const common = {
  position: 'absolute',
  top: '50%',
  left: '50%',
};
export const iconStyle = {
  default: {
    ...common,
    transform: 'translate(-50%,-50%)',
  },
  main: {
    ...common,
    transform: 'translate(-50%,-90%)',
  },
};

export const IconWrapper = ({ children }) => {
  return (
    <Box width={140} height={140} position="relative" mt={5}>
      <svg
        width="219"
        height="219"
        viewBox="0 0 219 219"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={iconStyle.default}
      >
        <g filter="url(#filter0_d_2108_11579)">
          <circle
            cx="109.5"
            cy="91.5"
            r="69.5"
            fill="#009193"
            fill-opacity="0.2"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2108_11579"
            x="0"
            y="0"
            width="219"
            height="219"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="18" />
            <feGaussianBlur stdDeviation="20" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2108_11579"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2108_11579"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <svg
        width="184"
        height="184"
        viewBox="0 0 184 184"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={iconStyle.default}
      >
        <g filter="url(#filter0_d_2108_11410)">
          <circle cx="92" cy="74" r="52" fill="#009193" fill-opacity="0.5" />
        </g>
        <defs>
          <filter
            id="filter0_d_2108_11410"
            x="0"
            y="0"
            width="184"
            height="184"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="18" />
            <feGaussianBlur stdDeviation="20" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2108_11410"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2108_11410"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <svg
        width="146"
        height="146"
        viewBox="0 0 146 146"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={iconStyle.default}
      >
        <g filter="url(#filter0_d_2108_11411)">
          <circle cx="73" cy="55" r="33" fill="#009193" />
        </g>
        <defs>
          <filter
            id="filter0_d_2108_11411"
            x="0"
            y="0"
            width="146"
            height="146"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="18" />
            <feGaussianBlur stdDeviation="20" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2108_11411"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2108_11411"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      {children}
    </Box>
  );
};
