import {
  DATE_SELECT_ACTION,
  STATUS_AMOUNT_ACTION,
  TYPE_SELECT_ACTION,
} from 'store/actions/InvestmentAtions/InvestmentActions';

const initialState = {
  typeValue: 'All',
  dateValue: '30_days',
  statusValue: 'All',
};

const selectInvestmentValuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE_SELECT_ACTION:
      return { ...state, typeValue: action.payload };
    case DATE_SELECT_ACTION:
      return { ...state, dateValue: action.payload };
    case STATUS_AMOUNT_ACTION:
      return { ...state, statusValue: action.payload };
    default:
      return state;
  }
};

export default selectInvestmentValuesReducer;
