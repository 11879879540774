import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ThemeContext } from 'context/ThemeContext';
import { Form, FormikProvider, useFormik } from 'formik';
import { AccountType } from 'jsx';
import { SelectFormik } from 'jsx/components/SelectCustom/SelectFormik';
import { TextFieldFormik } from 'jsx/components/TextFieldFormik/TextFieldFormik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  transactionCurrencyMapper,
  useFetchUserLedgerQuery,
  useGenAccountChargeToken,
  useGetLedgersOptions,
  useLedgerDetailQuery,
} from 'services/Ledger';
import * as yup from 'yup';

import logo from 'images/logo/layoutLogo.png';
import { MasterCard, Visa } from './Icons';
import { Redirect, useHistory } from 'react-router-dom';
import CircularLoader from 'jsx/components/Loader/CircularLoader';

export const topupSchema = yup.object().shape({
  ledgerId: yup.string().required('Please select a ledger'),
  amount: yup
    .number()
    .required('Amount is required')
    .min(5, 'Please enter amount greater than 5')
    .max(1000, 'Please enter amount less than 1000'),
});
export const tirnuModalBg = 'rgba(0, 145, 147, 0.6)';
const themeStyle = {
  '&': {
    '.sc-intergiro-card-holder-input-h': {
      paddingTop: '2.25rem',
    },
    '.sc-payfunc-card-input-h': {
      height: '105px',
    },
    '.has-value.sc-smoothly-input-h>label.sc-smoothly-input': {
      top: '-1.6em',
      fontSize: '80%',
      left: 0,
      textTransform: 'uppercase',
    },
    'input.sc-smoothly-input': {
      padding: '0.5em 0.8em 0.2em 0.8em',
    },
    '.sc-intergiro-card-holder-input-h smoothly-input.sc-intergiro-card-holder-input:nth-child(1), .sc-intergiro-card-holder-input-h smoothly-input.sc-intergiro-card-holder-input:nth-child(2):first-of-type':
      {
        marginBottom: '2rem',
      },
  },
};
const theme = {
  layout: 'standard',
  fontImport: 'Poppins:wght@400',
  dangerColor: '#ff2e2e',
  style: {
    gap: '1rem',
    fontFamily: 'Poppins, Menlo',
    fontWeight: '400',
    text: {
      color: '#070808',
      height: '45px',
    },
    border: {
      color: '#a0aec0',
      width: '1px',
      radius: '8px',
    },
  },
};

function loadScript(src) {
  return new Promise(function (resolve, reject) {
    var s;
    s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

const cardCDN = process.env.REACT_APP_INTERGIRO_TOPUP_CARD_SDK;
const publicKey = process.env.REACT_APP_FUND_KEY;

function AccountTopup({
  open,
  handleClose,
  defaultLedgerId,
  isNavigated,
  navigatedToken,
  navigatedAmount,
  navigatedFees,
  navigatedAccount,
  navigatedName,
  navigatedEmail,
}) {
  const MUItheme = useTheme();
  const isMobile = useMediaQuery(MUItheme.breakpoints.down('tabletS'));
  const isMobileTopup = isMobile && isNavigated;

  const history = useHistory();
  const { user } = useContext(ThemeContext);
  const enableAPIs = !isNavigated;
  const {
    mutateAsync: getChargeToken,
    isSuccess: isTokenGenerated,
    isLoading,
  } = useGenAccountChargeToken();

  const [isLoadingCardInput, setIsLoadingCardInput] = useState(
    isNavigated ? true : false
  );
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [chargedLedger, setChargedLedger] = useState('');

  // const { data: cardList } = useGetSavedCards();
  const { isSuccess: isLedgerDetailFetched } =
    useLedgerDetailQuery(chargedLedger);
  const { refetch: refetchLedgers } = useFetchUserLedgerQuery(enableAPIs);
  const { ledgersOptions } = useGetLedgersOptions(enableAPIs);

  const intergiroRef = useRef();
  const cardRef = useRef();
  const chargeToken = useRef();

  const initCardUIMobile = () => {
    intergiroRef.current = Intergiro3D(publicKey);
    const components = intergiroRef.current.components(); // initialize components library
    const card = components.get('card-input', {
      ...theme,
      name: navigatedName,
      email: navigatedEmail,
    });
    // create card input component
    const container = document.getElementById('card-input');

    card.mount(container);
    setTimeout(() => {
      setIsLoadingCardInput(false);
    }, 2000);

    formikBag.setFieldValue('amount', navigatedAmount || 0);
    formikBag.setFieldValue('fees', navigatedFees || 0);
    formikBag.setFieldValue('ledgerId', ' ');

    chargeToken.current = navigatedToken;
    cardRef.current = card;
  };

  const initCardUI = () => {
    if (isNavigated) {
      return initCardUIMobile();
    }

    intergiroRef.current = Intergiro3D(publicKey);
    const components = intergiroRef.current.components(); // initialize components library
    const container = document.getElementById('card-input-container');
    const card = components.get(
      'card-input',
      {
        ...theme,
        name:
          user?.accountType === AccountType.B
            ? user?.company_name
            : user?.full_name?.first_name + ' ' + user?.full_name?.last_name,
        email: user?.email,
      },
      container
    ); // create card input component
    cardRef.current = card;
  };

  const initCardPayment = async (ledgerId) => {
    if (!cardRef.current) return;
    setIsConfirmingPayment(true);
    await intergiroRef.current
      .confirmPaymentMethod(chargeToken.current, {
        payment_method: {
          card: cardRef.current,
        },
      })
      .then(async (result) => {
        if (result.error) {
          console.error(result.error);
          swal({
            title: 'Failed!',
            icon: 'error',
            text: `Failed to load amount! Please try again!`,
            buttons: 'Close',
          });
          setIsConfirmingPayment(false);
        } else {
          swal({
            title: 'Success',
            icon: 'success',
            text: `Account loaded successfully!`,
            buttons: 'Close',
          });
          setChargedLedger(ledgerId);
          if (isNavigated) {
            history.push('/');
          }
        }
      });
  };

  const handleCardPayment = async (values) => {
    if (isTokenGenerated || isNavigated) {
      if (!acceptTerms) return;
      initCardPayment(values.ledgerId);
    } else {
      try {
        await initCardCharge(values.amount, values.ledgerId);
      } catch (error) {}
    }
  };

  const formikBag = useFormik({
    initialValues: { amount: '', ledgerId: defaultLedgerId || '', fees: 0 },
    validationSchema: topupSchema,
    onSubmit: handleCardPayment,
  });

  const initCardCharge = async (chargeAmount, ledgerId) => {
    try {
      const { data: tokenInfo } = await getChargeToken({
        amount: chargeAmount,
        ledgerId,
      });
      const { token, fees, amount } = tokenInfo.data;

      formikBag.setFieldValue('amount', amount);
      formikBag.setFieldValue('fees', fees);

      setIsLoadingCardInput(true);
      cardRef.current.mount(); // mount card component onto the page
      setTimeout(() => {
        setIsLoadingCardInput(false);
      }, 2000);
      chargeToken.current = token;
      return token;
    } catch (error) {
      return Promise.reject('');
    }
  };

  useEffect(() => {
    let i3D = window.Intergiro3D;
    if (!i3D) {
      loadScript(cardCDN)
        .then(() => initCardUI())
        .catch(console.log);
    } else {
      setTimeout(() => {
        initCardUI();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const fetchLedgerList = async () => {
      try {
        await refetchLedgers();
      } catch (error) {
        console.error(error);
      }
      setIsConfirmingPayment(false);
      setChargedLedger('');
      handleClose();
    };

    if (isLedgerDetailFetched) {
      fetchLedgerList();
    }
  }, [isLedgerDetailFetched]);

  const assetType =
    transactionCurrencyMapper[
      ledgersOptions.find(
        (ledger) => ledger.value === formikBag.values.ledgerId
      )?.asset_type
    ];

  const isCardStep = isTokenGenerated || isNavigated;

  if (isNavigated && !navigatedToken) {
    return <Redirect to="/" />;
  }

  return (
    <FormikProvider value={formikBag}>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            height: isLoadingCardInput ? 715 : 'auto',
            borderRadius: 2.5,
            boxShadow: 'none',
            margin: isMobileTopup ? 0 : 32,
            width: isMobileTopup ? '100%' : 'calc(100% - 64px)',
            maxHeight: isMobileTopup ? '100%' : 'calc(100% - 64px)',
          },
        }}
        sx={{
          background: isNavigated ? 'transparent' : tirnuModalBg,
          '& > .MuiDialog-container': {
            background: isNavigated ? 'white' : 'inherit',
          },
        }}
        fullWidth
        maxWidth="mobile"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoadingCardInput && (
          <Stack height="inherit" justifyContent="center" alignSelf="center">
            <CircularLoader />
          </Stack>
        )}
        <Form
          onSubmit={formikBag.handleSubmit}
          style={{
            display: isLoadingCardInput ? 'none' : 'block',
          }}
        >
          {isNavigated && (
            <Box textAlign="center">
              <img src={logo} alt="logo-image" />
            </Box>
          )}

          <DialogTitle id="alert-dialog-title" sx={{ px: 4, pt: 3.5, pb: 0 }}>
            {isCardStep
              ? 'Please enter your card details'
              : 'Enter the amount to recharge'}
          </DialogTitle>

          <DialogContent sx={{ px: 3.75, py: 3, pr: 5.5, pb: 2 }}>
            <Stack display={isCardStep ? 'block' : 'none'}>
              <Box position="relative">
                {isCardStep && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ position: 'absolute', top: 175, right: 50 }}
                  >
                    <Visa />
                    <MasterCard />
                  </Stack>
                )}
                <Box id="card-input-container" sx={themeStyle} />
                <Box id="card-input" sx={themeStyle} />
              </Box>

              {isCardStep && (
                <Stack gap={2} pt={2}>
                  <Box
                    sx={{
                      p: 2,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: 'var(--primary)',
                      borderRadius: '0.75rem',
                    }}
                  >
                    {[
                      {
                        label: 'Account:',
                        value:
                          navigatedAccount ||
                          ledgersOptions.find(
                            (ledger) =>
                              ledger.value === formikBag.values.ledgerId
                          )?.label,
                      },
                      {
                        label: 'Payable:',
                        value: [assetType, formikBag.values.amount].join(' '),
                      },
                      {
                        label: 'Fees:',
                        value: [assetType, formikBag.values.fees].join(' '),
                      },
                      {
                        label: 'Receivable:',
                        value: [
                          assetType,
                          formikBag.values.amount - formikBag.values.fees,
                        ].join(' '),
                      },
                    ].map((info, index) => (
                      <Stack direction="row" key={index}>
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            minWidth: '120px',
                          }}
                          gutterBottom
                        >
                          {info.label}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 18, fontWeight: 'bold' }}
                          gutterBottom
                        >
                          {info.value}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Stack>
              )}
            </Stack>

            {!isCardStep && (
              <Stack gap={3} pt={2}>
                <Box zIndex={1}>
                  <SelectFormik
                    placeholder="Choose an account"
                    label="Transfer to"
                    name="ledgerId"
                    options={ledgersOptions}
                  />
                </Box>

                <TextFieldFormik
                  label="AMOUNT"
                  type="number"
                  name="amount"
                  placeholder="Topup amount"
                  dashboard
                  fullWidth
                />
              </Stack>
            )}
            {isCardStep && (
              <FormControlLabel
                sx={{ alignItems: 'flex-start', m: 0, mt: 1.5 }}
                control={
                  <Checkbox
                    onChange={(_, checked) => setAcceptTerms(checked)}
                  />
                }
                label={
                  <Stack direction="row" ml={1}>
                    <Typography>
                      I agree to
                      <Link
                        href="https://intergiro.com/baas-terms-and-conditions"
                        target="_blank"
                        mx={0.5}
                      >
                        Intergiro's BaaS Terms and Conditions
                      </Link>
                      and
                      <Link
                        href="https://intergiro.com/baas-privacy-policy"
                        target="_blank"
                        ml={0.5}
                      >
                        Intergiro's Privacy Policy
                      </Link>
                    </Typography>
                  </Stack>
                }
              />
            )}
          </DialogContent>

          <DialogActions sx={{ px: 4, pb: 5 }}>
            <Stack direction="row" width="100%" gap={2}>
              <Button
                component={LoadingButton}
                disabled={isCardStep && !acceptTerms}
                loading={isLoading || isConfirmingPayment}
                variant="contained"
                type="submit"
              >
                {isCardStep ? 'Make Payment' : 'Pay'}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Stack>
          </DialogActions>
        </Form>
      </Dialog>
    </FormikProvider>
  );
}

export default AccountTopup;
