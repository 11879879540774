const formatCurrency = (currencyValue, currency) => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency?.toUpperCase() || 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(currencyValue);
  } catch (error) {
    return (
      currency +
      ' ' +
      new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(currencyValue)
    );
  }
};

const getInitials = (fullName) => {
  if (!fullName) return fullName;
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};

const formatLocaleDate = (date) => {
  if (!date) return date;
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const formatLocaleDateTime = (date) => {
  if (!date) return date;
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export { formatLocaleDate, formatLocaleDateTime, formatCurrency, getInitials };
