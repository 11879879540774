import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InputLabel } from '../TextFieldFormik/styles';
import Typography from '@mui/material/Typography';
import { useField, useFormikContext } from 'formik';
import { ErrorBox, PhoneInputContainer } from './styles';
import { EEACountries } from 'assets/ISO_country';

const PhoneInputFormik = ({
  label = 'Phone number',
  name,
  disabled,
  dashboard,
  enableAll,
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);
  return (
    <PhoneInputContainer>
      {label && <InputLabel dashboard={dashboard}>{label}</InputLabel>}
      <ErrorBox haserror={hasError ? 'error' : ''}>
        <PhoneInput
          autoFormat={false}
          disabled={disabled}
          disableDropdown={disabled}
          country={'de'}
          // regions={'europe'}
          onlyCountries={
            enableAll ? undefined : EEACountries.map((c) => c.toLowerCase())
          }
          value={field.value}
          placeholder={'Mobile number'}
          onChange={(phone, country) => {
            setFieldValue(name, phone);
            setFieldValue('code', country.dialCode);
          }}
        />
      </ErrorBox>
      {hasError && <Typography variant="errorMessage">{meta.error}</Typography>}
    </PhoneInputContainer>
  );
};

export default PhoneInputFormik;
