export const INSIDE_EUROPE = 'inside-europe';
export const OUTSIDE_EUROPE = 'outside-europe';

const initialState = {
  recipientType: null,
};

const sendMoneyDestinationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INSIDE_EUROPE:
      return { recipientType: action.payload };
    case OUTSIDE_EUROPE:
      return { recipientType: action.payload };
    default:
      return state;
  }
};

export default sendMoneyDestinationReducer;
