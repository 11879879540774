import { Box, Typography } from '@mui/material';
import { ThemeContext } from 'context/ThemeContext';
import SendMoneySuccsess from 'images/sendMoney/SendMoneySuccsess';
import { useContext } from 'react';

const TransferPaymentSentModal = ({ content }) => {
  const { chargeAmount } = useContext(ThemeContext);

  const totalAmount = content.amount / 100;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box mt="1.25rem" mb="1.56rem">
        <SendMoneySuccsess />
      </Box>
      <Typography variant="paymentSent" mb="0.69rem">
        Payment sent!
      </Typography>
      <Typography variant="sentDetails">
        {/* Removed fees from the sent amount */}
        {/* {`You sent ${totalAmount + Number(content?.fee || chargeAmount)} ${ */}
        {`You sent ${totalAmount} ${content.currency} successfully. It may take up to 15 min.`}
      </Typography>
    </Box>
  );
};

export default TransferPaymentSentModal;
