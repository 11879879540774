import axios from 'axios';
import useTokenDecoder from 'jsx/hooks/token';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTokenRegenMutation } from 'services/AuthService';
import { useFetchFeatureFlags } from 'services/FeatureFlag';
import { useFetchUserLedgerQuery } from 'services/Ledger';
import { useActiveLedgerDetail } from '../jsx/hooks/ledger';
import { store } from '../store/store';
export const ThemeContext = createContext();
const ThemeContextProvider = (props) => {
  const state = useSelector((state) => state.auth);
  const body = document.querySelector('body');
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [transferPopupData, setTransferPopuData] = useState([]);
  const [transactionListData, setTransactionListData] = useState([]);
  const [welcomePopup, setWelcomePopup] = useState(false);
  const [transferPopup, setTransferPopup] = useState(false);
  const [transferPopupForFees, setTransferPopupForFees] = useState(false);
  const [accountPopup, setAccountPopup] = useState(false);
  const [accountData, addAccountData] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const setAccountData = (item) => {
    let temp;
    temp = accountData;
    temp.push(item);
    addAccountData(temp);
  };
  const transferData = [
    {
      _id: '34556567678',
      amount: '-€15.99',
      note: 'Entertainment',
      name: 'NextFlix',
      Img: `https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/nextflix_vukfqi.png`,
      date: '27 March 2021, 12:30',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
    {
      _id: '2345345464',
      amount: '+€43.00',
      note: 'Personal',
      name: 'Anita Smith',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/anita_cznsza.png',
      date: '27 March 2021, 12:30',
      status: {
        isCompleted: true,
        isCancelled: false,
        isPending: false,
      },
    },
    {
      _id: '3253464565',
      amount: '00',
      note: 'Entertainment',
      name: 'Western Union',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/webtern_qdcbgy.png',
      date: '26 March 2021, 15:09',
      status: {
        isCompleted: false,
        isCancelled: false,
        isPending: true,
      },
    },
    {
      _id: '4565676834',
      amount: '-€105.75',
      note: 'Shopping',
      name: 'ASOS',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/asos_zumj9a.png',
      date: '25 March 2021, 15:30',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
    {
      _id: '546567678456',
      amount: '+€800.00',
      note: 'Personal',
      name: 'Fiona Daniels',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/fiona_sc8eio.png',
      date: '25 March 2021, 13:45',
      status: {
        isCompleted: true,
        isCancelled: false,
        isPending: false,
      },
    },
    {
      _id: '7686564324',
      amount: '-€97.00',
      note: 'Shopping',
      name: 'iHerb',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/iherb_pb8rng.png',
      date: '25 March 2021, 11:07',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
    {
      _id: '34645657423',
      amount: '-€4.99',
      note: 'Subscriptions',
      name: 'Apple',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/apple_djtnvf.png',
      date: '25 March 2021, 10:42',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
    {
      _id: '567574563453',
      amount: '+€1200.00',
      note: 'Weekly payment',
      name: 'Upwork',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/upwork_vlscw6.png',
      date: '25 March 2021, 13:45',
      status: {
        isCompleted: true,
        isCancelled: false,
        isPending: false,
      },
    },
    {
      _id: '787895674534',
      amount: '-€3.95',
      note: 'Cafes & Restaurants',
      name: 'Starbucks',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/starbucks_kkiqwr.png',
      date: '24 March 2021, 18:50',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
    {
      _id: '98795673453',
      amount: '-€63.85',
      note: 'Shopping',
      name: 'H&M',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/h-m_fxuwww.png',
      date: '24 March 2021, 18:37',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
        isPersonal: true,
      },
    },
    {
      _id: '34645346345',
      amount: '00',
      note: 'Entertainment',
      name: 'Western Union',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/webtern_qdcbgy.png',
      date: '26 March 2021, 15:09',
      status: {
        isCompleted: false,
        isCancelled: false,
        isPending: true,
      },
    },
    {
      _id: '67856734534',
      amount: '-€185.75',
      note: 'Shopping',
      name: 'ASOS',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/asos_zumj9a.png',
      date: '25 March 2021, 15:30',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
    {
      _id: '90875463423',
      amount: '+€100.00',
      note: 'Personal',
      name: 'Fiona Daniels',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/fiona_sc8eio.png',
      date: '25 March 2021, 13:45',
      status: {
        isCompleted: true,
        isCancelled: false,
        isPending: false,
      },
    },
    {
      _id: '434768797856',
      amount: '-€67.00',
      note: 'Shopping',
      name: 'iHerb',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/iherb_pb8rng.png',
      date: '25 March 2021, 11:07',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
    {
      _id: '980785645',
      amount: '-€24.99',
      note: 'Subscriptions',
      name: 'Apple',
      Img: 'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641537821/tirun/apple_djtnvf.png',
      date: '25 March 2021, 10:42',
      status: {
        isCompleted: false,
        isCancelled: true,
        isPending: false,
      },
    },
  ];

  const transferAccount = [];
  const logInMenu = [
    {
      name: 'Earn Money',
      link: 'https://tirnu.com/earn-money/',
    },
    {
      name: 'Save Money',
      link: 'https://tirnu.com/save-money/',
    },
    {
      name: 'Receive Money',
      link: 'https://tirnu.com/receive-money/',
    },
    {
      name: 'Account',
      link: 'https://tirnu.com/account/',
    },
    {
      name: 'Support',
      link: 'https://tirnu.com/support/',
    },
  ];

  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];
  const [activeEvent, setActiveEvent] = useState(!path);

  // Login_User_Data
  const [user, setUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  // End user information
  const [Enduser, setEnduser] = useState(null);
  // console.log("enduser Data: ", Enduser);

  // user beneficiary information data fetch
  const [beneficiaries, setBeneficiaries] = useState(null);
  // console.log("baneficiares Data: ", beneficiaries);

  //  Transactions information data fetch
  const [transactions, setTransactions] = useState([]);
  // console.log("transactions Data: ", transactions);

  // dummy user info
  const userData = {
    fullName: {
      firstName: 'James',
      lastName: 'Walkers',
    },
    email: 'info@gmail.com',
    phoneNumber: '1717111111',
    balance: '1837.00',
    card: {
      expirationMonth: '5',
      expirationYear: '2025',
      number: '1234 5678 9012 3456',
      securityCode: '818',
    },
    kyc: {
      key: '4bb1de1e-0974-4d99-9093-bc250868c546',
      status: 'pending',
    },
    avatar:
      'https://res.cloudinary.com/dyor9qtzh/image/upload/v1641635558/tirun/user-img_dln8k6.png',
    _id: '61d6c476a254d0c9614e38e5',
    createdAt: '2022-01-06T10:29:10.739Z',
    updatedAt: '2022-01-06T10:29:10.739Z',
    __v: 0,
  };

  // KYC popup
  const [kycActive, setKycActive] = useState(false);

  // Features
  const features = {
    agent_transfer: 'agent_transfer',
    cards: 'cards',
    physical_card: 'physical_card',
  };
  const { data: featureFlags = [] } = useFetchFeatureFlags(!!user);
  // User Ledger Information
  const { fetchUserLedgerDetail, Ledger } = useActiveLedgerDetail(true);
  const { data: userLedgers = [] } = useFetchUserLedgerQuery(!!Enduser);
  const { mutateAsync: regenToken } = useTokenRegenMutation();
  const { decode } = useTokenDecoder();

  const isFeatureEnabled = (featureName) =>
    featureFlags.find((f) => f.feature === featureName)?.active;

  const initUserDetails = () => {
    setIsUserLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/v1/user/self`, {
        headers: {
          Authorization: `Bearer ${state.auth.accessToken}`,
          'x-refresh': `${state.auth.refreshToken}`,
        },
      })
      .then(async (res) => {
        if (
          res.data.enduser_id &&
          !decode(state.auth.accessToken)?.enduser_id
        ) {
          await regenToken(state.auth.refreshToken);
        } else {
          setUser(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsUserLoading(false);
      });
  };

  // user data information fetch api call
  useEffect(() => {
    if (state.auth.accessToken && user === null) {
      initUserDetails();
    }
  }, [state.auth.accessToken, user]);

  useEffect(() => {
    if (welcomePopup) {
      setTimeout(() => {
        setWelcomePopup(false);
      }, 5000);
    }
  }, [welcomePopup]);

  useEffect(() => {
    if (userLedgers.length) {
      const activeLedger =
        userLedgers.find((ledger) => ledger.active)?.ledger_id ||
        userLedgers[0].ledger_id;
      fetchUserLedgerDetail(activeLedger);
    }
  }, [userLedgers]);

  // Benefiiary list
  useEffect(() => {
    if (user !== null && user.status === 'approved' && user.enduser_id) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/api/v1/user/beneficiary`,
          {},
          {
            headers: {
              Authorization: store.getState().auth.auth.accessToken,
            },
          }
        )
        .then((res) => {
          setBeneficiaries(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  // All transactions
  const getLedger = () => {
    if (user !== null && ledgerId) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/api/v1/user/transactions`,
          {
            ledgerId: ledgerId,
          },
          {
            headers: {
              Authorization: store.getState().auth.auth.accessToken,
            },
          }
        )
        .then((res) => {
          setTransactions(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const ledgerId = Ledger?.ledger_id;
  useEffect(() => {
    getLedger();
  }, [user, ledgerId]);

  useEffect(() => {
    const body = document.querySelector('body');
    let resizeWindow = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      window.innerWidth >= 768 && window.innerWidth <= 1366
        ? body.setAttribute('data-sidebar-style', 'full')
        : window.innerWidth <= 768
        ? body.setAttribute('data-sidebar-style', 'overlay')
        : body.setAttribute('data-sidebar-style', 'full');
    };
    // body.setAttribute("data-typography", "poppins");
    body.setAttribute('data-theme-version', 'light');
    // body.setAttribute("data-theme-version", "dark");
    body.setAttribute('data-layout', 'vertical');
    body.setAttribute('data-nav-headerbg', 'color_1');
    body.setAttribute('data-headerbg', 'color_1');
    body.setAttribute('data-sidebar-style', 'overlay');
    body.setAttribute('data-sibebarbg', 'color_1');
    body.setAttribute('data-primary', 'color_1');
    body.setAttribute('data-sidebar-position', 'fixed');
    body.setAttribute('data-header-position', 'fixed');
    body.setAttribute('data-container', 'wide');
    body.setAttribute('direction', 'ltr');
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    if (!transferPopup) {
      setTransferPopuData([]);
    }
  }, [transferPopup]);

  const InfoPopupHandler = (id, isFees) => {
    setTransferPopup(!transferPopup);
    setTransferPopupForFees(!!isFees);
    const filterData = transactions.filter((item) => item._id === id);
    setTransferPopuData(filterData);
  };

  const InfoPopupHandlerForTransactionList = (id) => {
    setTransferPopup(!transferPopup);
    const filterData = transactionListData.filter((item) => item._id === id);
    setTransferPopuData(filterData);
  };
  // console.log("transferPopup Data: ", transferPopupData);

  return (
    <ThemeContext.Provider
      value={{
        body,
        windowWidth,
        windowHeight,
        transferData,
        userData,
        transferPopup,
        accountPopup,
        cardDetails,
        setCardDetails,
        accountData,
        isMobileMenuOpen,
        welcomePopup,
        setWelcomePopup,
        setIsMobileMenuOpen,
        setTransferPopup,
        setAccountPopup,
        setAccountData,
        InfoPopupHandler,
        InfoPopupHandlerForTransactionList,
        transferPopupForFees,
        transferPopupData,
        setTransferPopuData,
        logInMenu,
        path,
        activeEvent,
        setActiveEvent,
        transferAccount,
        kycActive,
        setKycActive,
        token,
        user,
        setUser,
        initUserDetails,
        isUserLoading,
        Enduser,
        setEnduser,
        Ledger,
        setTransactionListData,
        beneficiaries,
        setBeneficiaries,
        transactions,
        setTransactions,
        state,
        getLedger,
        // getEnduser,
        chargeAmount: 0.1,
        features,
        isFeatureEnabled,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;

export const useTirnuAppContext = () => useContext(ThemeContext);
