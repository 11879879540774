import { CHANGE_MODAL_STATE } from 'store/reducers/modalsReducer';

export function ModalOpenAction(payload) {
  return {
    type: CHANGE_MODAL_STATE,
    payload: {
      ...payload,
      isOpen: true,
      modalName: payload?.modalName,
    },
  };
}
