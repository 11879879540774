import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as MESSAGES from 'constants/constants';
import { OTPInput } from './styles';

const AdminOtpVerification = ({ value, onChange, hasErrored }) => {
  const { palette } = useTheme();

  return (
    <Box>
      <OTPInput
        value={value}
        onChange={onChange}
        numInputs={6}
        hasErrored={hasErrored}
        errorStyle={{
          border: `0.0625rem solid ${palette.error.main}`,
          color: palette.error.main,
        }}
        inputStyle={{
          maxWidth: '2.2rem',
          width: '100%',
          height: '2.2rem',
          borderRadius: '0.5rem',
          borderWidth: '0.0625rem',
          borderColor: palette.grey500.main,
          fontSize: '1.5rem',
          fontWeight: 400,
          color: palette.grey500.main,
        }}
      />
      {hasErrored && (
        <Typography variant="errorMessage">
          Please Enter valid number !
        </Typography>
      )}
    </Box>
  );
};

export default AdminOtpVerification;
