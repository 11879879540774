import React from 'react';
import { useLedgerDetailMutation, useLedgerDetailQuery } from 'services/Ledger';

function useActiveLedgerDetail(disableRefetchLedgers) {
  const { mutate: fetchUserLedgerDetail, data: ledgerDetail } =
    useLedgerDetailMutation(disableRefetchLedgers);
  const { data: Ledger = null } = useLedgerDetailQuery(ledgerDetail?.ledger_id);

  return { fetchUserLedgerDetail, Ledger };
}

export { useActiveLedgerDetail };
