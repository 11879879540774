import { Stepper } from 'jsx/components/Stepper/Stepper';
import { useSendMoneyToInternationalState } from 'jsx/hooks/useSendMoneyInternationalState';
import { useState } from 'react';
import InternationalStep1 from './Step1';
import InternationalStep2 from './Step2';

export const internationalCountries = [
  { label: 'Nepal', value: 'np', currency: 'NPR ' },
  { label: 'India', value: 'in', currency: 'INR ' },
];

function SendToInternational({ onBack }) {
  const { state } = useSendMoneyToInternationalState();

  const sendToSteps = [InternationalStep1, InternationalStep2];
  const [sendInternationalStep, setSendInternationalStep] = useState(0);

  return (
    <Stepper
      state={state}
      components={sendToSteps}
      step={sendInternationalStep}
      setStep={setSendInternationalStep}
      onBack={
        sendInternationalStep === 0 ? onBack : () => setSendInternationalStep(0)
      }
    />
  );
}

export default SendToInternational;
