import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EurIcon from 'images/sendMoney/EurIcon';

export const TransferFromItem = ({ option }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {option.asset_type === 'EUR' && <EurIcon />}
      <Typography
        ml={option.amount >= 0 ? '1rem' : 0}
        className="text-truncate"
        sx={{ maxWidth: 'calc(100% - 60px)' }}
      >
        {option.asset_type === 'EUR' && '€'}
        {option.amount >= 0 && option.amount}
        {option.amount >= 0 && ' | '}
        {option.amount >= 0 ? option.label.toLowerCase() : option.label}
      </Typography>
    </Box>
  );
};
