import {
  SEND_INTERNATIONAL_ACTION,
  SEND_INTERNATIONAL_LOADING_ACTION,
} from 'store/actions/SendMoneyInternationalActions/sendInternationalAction';

const initialState = {
  sendInternationalData: null,
  isLoading: false,
};

const sendMoneyInternationalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_INTERNATIONAL_ACTION:
      return { ...state, sendInternationalData: action.payload };
    case SEND_INTERNATIONAL_LOADING_ACTION:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default sendMoneyInternationalReducer;
