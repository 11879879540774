import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import {
  Actions,
  Change,
  Divider,
  InnerContainer,
  MainContainer,
  ResultRowBlock,
  SchdeulePaymentDatePicker,
  TransferInfo,
  TransferRowBlock,
  ErrorText,
} from './styles';
import EurIcon from 'images/sendMoney/EurIcon';
import Button from '@mui/material/Button';
import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormControlLabel, Switch } from '@mui/material';
import { getErrorMessageFields } from 'utils/getErrorMessageFields';
import { ThemeContext } from 'context/ThemeContext';
import { transactionCurrencyMapper } from 'services/Ledger';

const TransferDetailsCard = ({
  transferName,
  transferEmail,
  transferIBAN,
  transferSWIFT,
  transferBIC,
  transferFee = 0,
  transferAmount,
  currency,
  description,
  transferFrom,
  scheduleChecked,
  scheduleDate,
  setScheduleDate,
  setScheduleChecked,
  transferTo,
  onSubmit,
  onCancel,
  onPrevStep,
  someoneElse = false,
  isLoading = false,
  myOwnAccTransfer = false,
  transferInternational = false,
  transferInternationalRecipient,
  errors,
}) => {
  let { chargeAmount } = useContext(ThemeContext);

  if (myOwnAccTransfer) chargeAmount = 0;

  let content = null;
  if (transferInternational) {
    content = (
      <>
        <ResultRowBlock>
          <Typography variant="transferRoute">Transfer Country</Typography>
          <Box>{transferInternationalRecipient.transferToCountry}</Box>
        </ResultRowBlock>
        <ResultRowBlock>
          <Typography variant="transferRoute">Bank Name</Typography>
          <Box>{transferInternationalRecipient.transferToBank}</Box>
        </ResultRowBlock>
        <ResultRowBlock>
          <Typography variant="transferRoute">Account Name</Typography>
          <Box>{transferInternationalRecipient.transferToAccountName}</Box>
        </ResultRowBlock>
        <ResultRowBlock>
          <Typography variant="transferRoute">Account Number</Typography>
          <Box>{transferInternationalRecipient.transferTo}</Box>
        </ResultRowBlock>
        <ResultRowBlock>
          <Typography variant="transferRoute">Receiver Phone</Typography>
          <Box>{transferInternationalRecipient.transferToNumber}</Box>
        </ResultRowBlock>

        <ResultRowBlock>
          <Typography variant="transferRoute">Transfer Amount</Typography>
          <Box>
            {transactionCurrencyMapper[currency]} {transferAmount}
          </Box>
        </ResultRowBlock>

        <ResultRowBlock>
          <Typography variant="transferRoute">Exchange Rate</Typography>
          <Box>{transferInternationalRecipient.transferConversionRate}</Box>
        </ResultRowBlock>

        <ResultRowBlock>
          <Typography variant="transferRoute">Receivable Amount</Typography>
          <Box>{transferInternationalRecipient.transferConversionAmount}</Box>
        </ResultRowBlock>

        <ResultRowBlock>
          <Typography variant="transferRoute">Transfer fee</Typography>
          <Box>
            {transactionCurrencyMapper[currency]} {transferFee}
          </Box>
        </ResultRowBlock>

        <ResultRowBlock>
          <Typography variant="transferTotal">Total</Typography>
          <Typography variant="transferAmount">
            {transactionCurrencyMapper[currency]}{' '}
            {(Number(transferAmount) + Number(transferFee)).toFixed(2)}
          </Typography>
        </ResultRowBlock>
      </>
    );
  } else {
    content = (
      <>
        {!someoneElse && (
          <>
            <ResultRowBlock>
              <Typography variant="transferRoute">Recipient Iban</Typography>
              <Box sx={{ fontWeight: 'medium' }}>
                {transferTo.label?.toLowerCase()}
              </Box>
            </ResultRowBlock>
            <ResultRowBlock>
              <Typography variant="transferRoute">Recipient Name</Typography>
              <Box sx={{ fontWeight: 'medium', textTransform: 'capitalize' }}>
                {transferTo.name}
              </Box>
            </ResultRowBlock>
          </>
        )}

        {someoneElse && (
          <>
            <Divider />
            <TransferRowBlock>
              <Typography variant="transferRoute">Recipient details</Typography>
              <Change onClick={onPrevStep}>Change</Change>
            </TransferRowBlock>
            <TransferRowBlock>
              <Typography variant="transferRoute">Name</Typography>
              <Box>{transferName}</Box>
            </TransferRowBlock>
            {transferEmail && (
              <TransferRowBlock>
                <Typography variant="transferRoute">Email</Typography>
                <Box>{transferEmail}</Box>
              </TransferRowBlock>
            )}
            <TransferRowBlock>
              <Typography variant="transferRoute">IBAN</Typography>
              <Box>{transferIBAN}</Box>
            </TransferRowBlock>
            {transferBIC && transferSWIFT && (
              <TransferRowBlock>
                <Typography variant="transferRoute">
                  Bank code (BIC/SWIFT)
                </Typography>
                <Box>{`${transferBIC}/${transferSWIFT}`}</Box>
              </TransferRowBlock>
            )}
            <TransferRowBlock>
              <Typography variant="transferRoute">Description</Typography>
              <Box>{description}</Box>
            </TransferRowBlock>
          </>
        )}
        <Divider />

        {!myOwnAccTransfer && (
          <ResultRowBlock>
            <Typography variant="transferRoute">Transfer fee</Typography>
            <Box>{transferFee || chargeAmount}</Box>
          </ResultRowBlock>
        )}

        <ResultRowBlock>
          <Typography variant="transferTotal">Total</Typography>
          <Typography variant="transferAmount">
            {(+transferAmount + (+transferFee || chargeAmount)).toFixed(2)}
            {` ${currency}`}
          </Typography>
        </ResultRowBlock>

        <Divider />

        <ResultRowBlock>
          <SchdeulePaymentDatePicker>
            <FormControlLabel
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: 'var(--primary)',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: 'var(--primary)',
                    },
                  }}
                  checked={scheduleChecked}
                  onChange={(e) => setScheduleChecked?.(e.target.checked)}
                />
              }
              label="Schedule Payment"
            />

            {scheduleChecked && (
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => setScheduleDate?.(date)}
                minDate={new Date()}
                isClearable
                placeholderText="Select a date for the payment "
              />
            )}
          </SchdeulePaymentDatePicker>
        </ResultRowBlock>
      </>
    );
  }

  return (
    <MainContainer>
      <Typography variant="sendMoneyTitle" mb="2.06rem">
        Review and confirm
      </Typography>
      <InnerContainer isError={Boolean(errors)}>
        <Typography variant="transferDetails">Transfer details</Typography>
        <TransferRowBlock>
          <Typography variant="transferRoute">Transfer From</Typography>
          <TransferInfo>
            {transferFrom.asset_type === 'EUR' && (
              <EurIcon width={20} height={20} />
            )}
            <Box ml="0.75rem" flex={1}>
              {transferFrom.asset_type === 'EUR' && '€'}
              {transferFrom.amount}
              {' | '}
              {transferFrom.label.toLowerCase()}
            </Box>
          </TransferInfo>
        </TransferRowBlock>

        {content}
      </InnerContainer>
      {errors && <ErrorText>{getErrorMessageFields(errors)}</ErrorText>}

      <Actions>
        <Button
          component={LoadingButton}
          variant="contained"
          sx={{
            maxWidth: '11.38rem',
          }}
          onClick={onSubmit}
          loading={isLoading}
        >
          Send funds
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            maxWidth: '11.38rem',
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Actions>
    </MainContainer>
  );
};

export default TransferDetailsCard;
