import { useQuery } from 'react-query';
import { TirnuClient } from './Axios';

const { API } = require('./Api');

const fetchFeatureFlags = async () => TirnuClient.get(API.v1.featureFlag.all);
/**
 * Fetch all currency exchange rate
 * @returns
 */
const useFetchFeatureFlags = (enabled) => {
  return useQuery([API.v1.featureFlag.all], fetchFeatureFlags, {
    enabled,
    retry: 1,
    select: ({ data }) => data?.data,
    onError: (error) => {
      console.log(error);
    },
  });
};

export { useFetchFeatureFlags };
