import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from './Api';
import { TirnuClient } from './Axios';

const fetchExchangeRates = async () =>
  TirnuClient.get(API.v1.transfer.allExchangeRates);

/**
 * Fetch all currency exchange rate
 * @returns
 */
const useFetchExchangeRates = () => {
  return useQuery([API.v1.transfer.allExchangeRates], fetchExchangeRates, {
    retry: 1,
    select: ({ data }) => data?.data,
    onError: (error) => {
      console.log(error);
    },
  });
};

const updateExchangeRates = async ({ amount, transferToCountry }) =>
  TirnuClient.put(API.v1.transfer.exchangeRate, {
    amount,
    transferToCountry,
  });

/**
 * Update currency exchange rate
 * @returns
 */
const useUpdateExchangeRates = () => {
  const queryClient = useQueryClient();

  return useMutation([API.v1.transfer.exchangeRate], updateExchangeRates, {
    retry: 1,
    select: ({ data }) => data?.data,
    onSuccess: () => {
      swal({
        title: 'Success',
        icon: 'success',
        text: `Exchange rate updated successfully!`,
        buttons: 'Close',
      });
      queryClient.invalidateQueries(API.v1.transfer.allExchangeRates);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

const fetchExchangeRate = async ({ amount, transferToCountry }) =>
  TirnuClient.get(API.v1.transfer.exchangeRate, {
    params: {
      amount,
      transferToCountry,
    },
  });

/**
 * Fetch currency exchange rate
 * @returns
 */
const useFetchExchangeRate = () => {
  return useMutation([API.v1.transfer.exchangeRate], fetchExchangeRate, {
    retry: 1,
    select: ({ data }) => data?.data,
    onError: (error) => {
      console.log(error);
    },
  });
};

const sendMoneyInternational = async (transferBody) =>
  TirnuClient.post(API.v1.transfer.transferInternational, transferBody);

/**
 * Fetch currency exchange rate
 * @returns
 */
const useSendMoneyInternational = () => {
  return useMutation(sendMoneyInternational, {
    retry: 1,
    onError: (error) => {
      console.log(error);
    },
  });
};

const fulfillsendMoneyInternational = async (fulfillTransfer) =>
  TirnuClient.put(
    API.v1.transfer.fulfillInternationalTransfer,
    fulfillTransfer
  );

/**
 * Fulfill International send money
 * @returns
 */
const useFulfillSendMoneyInternational = () => {
  const queryClient = useQueryClient();

  return useMutation(fulfillsendMoneyInternational, {
    retry: 1,
    onSuccess: () => {
      queryClient.invalidateQueries(API.v1.transaction.fetch);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

const approvesendMoneyInternational = async (txnId) =>
  TirnuClient.put(API.v1.transfer.approveInternationalTransfer, { txnId });

/**
 * Approve International send money
 * @returns
 */
const useApproveSendMoneyInternational = () => {
  return useMutation(approvesendMoneyInternational, {
    retry: 1,
    onError: (error) => {
      console.log(error);
    },
  });
};

const getBanks = (country) => async () => {
  return TirnuClient.get(API.v1.transfer.getBanks, {
    params: { country },
  });
};

/**
 * Fetch currency exchange rate
 * @returns
 */
const useGetBanks = (country) => {
  return useQuery([API.v1.transfer.getBanks, country], getBanks(country), {
    retry: 1,
    staleTime: 0,
    cacheTime: 0,
    enabled: !!country,
    select: ({ data }) => data?.data,
    onError: (error) => {
      console.log(error);
    },
  });
};

export {
  useFetchExchangeRates,
  useUpdateExchangeRates,
  useFetchExchangeRate,
  useSendMoneyInternational,
  useFulfillSendMoneyInternational,
  useApproveSendMoneyInternational,
  useGetBanks,
};
