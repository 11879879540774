import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const ErrorBox = styled(Box)(({ haserror, theme }) => ({
  '& .form-control': {
    border:
      haserror === 'error'
        ? `0.0625rem solid ${theme.palette.error.main} !important`
        : `1px solid ${theme.palette.border.main} !important`,
    color: haserror === 'error' ? theme.palette.error.main : '',
  },
}));

export const PhoneInputContainer = styled(Box)(({ theme }) => ({
  '& .form-control': {
    backgroundColor: '#ffffff !important',
    // color: 'inherit',
    height: '3rem !important',
    width: '100% !important',
    maxWidth: '20.44rem !important',
    border: `0.0625rem solid ${theme.palette.border.main} !important`,
    borderRadius: '0.5rem !important',
    paddingLeft: '1.25rem !important',
    marginLeft: '6.19rem !important',
    [theme.breakpoints.down('laptop')]: {
      maxWidth: '18.125rem !important',
    },
  },
  '& .selected-flag': {
    padding: '0 !important',
    margin: '0 auto !important',
    '&:hover': {
      borderRadius: '0.5rem !important',
    },
  },
  '& .flag': {
    transform: 'scale(1.5, 2) !important',
    borderRadius: '50% !important',
  },
  '& .arrow': {
    marginLeft: '0.313rem !important',
  },
  '& .flag-dropdown': {
    backgroundColor: `${theme.palette.text.secondary} !important`,
    border: `0.0625rem solid ${theme.palette.border.main} !important`,
    borderRadius: '0.5rem !important',
    width: '100% !important',
    maxWidth: '5.44rem !important',
  },
  '& .country-list': {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  '& .react-tel-input': {
    display: 'flex !important',
  },
}));
