export const typography = {
  fontFamily: 'Roboto',
  display: 'inline-block',
  width: '100%',
  mainTitile: {
    fontSize: '2.0625rem',
    fontWeight: 600,
    color: '#000000',
  },
  secondaryTitle: {
    fontSize: '1.125rem',
    fontWeight: 400,
    color: '#a0aec0',
  },
  errorMessage: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: '#E53E3E',
  },
  titleTypeCard: {
    color: '#343A40',
    fontWeight: 600,
    fontSize: '1.375rem',
    lineHeight: '2rem',
    '@media (max-width: 28.75rem)': {
      fontSize: '1rem',
    },
  },
  subTitleTypeCard: {
    color: '#788CA5',
    fontWeight: 400,
    fontSize: '0.9375rem',
    lineHeight: '1.875rem',
    '@media (max-width: 28.75rem)': {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  },
  rightImgTitle: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '1.875rem',
    lineHeight: '2.5rem',
    '@media (min-width: 1921px)': {
      fontSize: '2.812rem',
      lineHeight: '3.75rem',
    },
    '@media (min-width: 2561px)': {
      fontSize: '3.75rem',
      lineHeight: '5rem',
    },
  },
  emailTitle: {
    fontSize: '2.0625rem',
    fontWeight: 600,
    lineHeight: '2.375rem',
    color: '#000000',
  },
  secondaryEmailTitle: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '1.562rem',
    color: '#a0aec0',
  },
  sendMoneyTitle: {
    fontWeight: 500,
    fontSize: '1.375rem',
    lineHeight: '1.5rem',
    color: '#000000',
  },
  recipientTitle: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '150%',
    textTransform: 'uppercase',
    color: '#788CA5',
  },
  recipientCard: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
  },
  selectLabel: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '150%',
    textTransform: 'uppercase',
    color: '#788CA5',
  },
  selectPlaceholder: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: '#788CA5',
  },
  selectOptionText: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.75rem',
    color: '#343A40',
  },
  transferDetails: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '150%',
    color: '#343A40',
  },
  transferRoute: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '150%',
    color: '#788CA5',
  },
  transferTotal: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '150%',
    color: '#343A40',
  },
  transferAmount: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '150%',
    color: '#343A40',
  },
  phoneVerifyTitle: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    color: '#4F4F4F',
  },
  verifyTimeLeft: {
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '1.125rem',
    color: '#788CA5',
  },
  didntReceiveSms: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    color: '#788CA5',
  },
  paymentSent: {
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '2rem',
    color: '#363740',
  },
  sentDetails: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    textAlign: 'center',
    color: '#A0AEC0',
  },
  recipientBankDetails: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.375rem',
    color: '#333333',
  },
  swiftWarning: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#788CA5;',
  },
  dashboardCardNumber: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    color: '#FFFFFF;',
  },
  dashboardCardBusinessNumber: {
    fontWeight: 400,
    fontSize: '0.88rem',
    lineHeight: '1.5rem',
    color: '#FFFFFF;',
  },
  dashboardCardName: {
    fontWeight: 400,
    fontSize: '0.94rem',
    lineHeight: '1rem',
    color: '#FFFFFF;',
  },
  dashboardBusinessCardName: {
    fontWeight: 400,
    fontSize: '0.81rem',
    lineHeight: '1rem',
    color: '#FFFFFF;',
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '2.625rem',
    color: '#363740',
  },
  yourBalance: {
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '150%',
    color: '#FFFFFF',
  },
  balanceAmount: {
    fontWeight: 500,
    fontSize: '1.88rem',
    lineHeight: '1',
    color: '#FFFFFF',
  },
  cardOptionTitle: {
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '150%',
    color: '#FFFFFF',
  },
  cardCountText: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#FFFFFF',
  },
  cardOptionvalue: {
    fontWeight: 500,
    fontSize: '0.625rem',
    lineHeight: '150%',
    color: '#FFFFFF',
  },
  accountTitle: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '140%',
    color: '#FFFFFF',
  },
  chartTimeTitle: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '0.88rem',
    color: '#788CA5',
  },
  noCardsAvailable: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '140%',
    textTransform: 'uppercase',
    color: '#788CA5',
  },
  cardsPayment: {
    fontWeight: 500,
    fontSize: '1rem',
    color: '#000000',
  },
  cardsPaymentItem: {
    fontWeight: 500,
    fontSize: '1rem',
    marginLeft: '0.5rem',
  },
  accountVerificationConsent: {
    fontWeight: 500,
    fontSize: '1rem',
    color: '#04494a',
  },
  investmentSelectTitle: {
    fontWeight: 400,
    fontSize: '0.75rem',
    color: '#788ca5',
    textTransform: 'uppercase',
  },
  investmentDateTitle: {
    fontWeight: 600,
    fontSize: '0.81rem',
    color: 'rgba(0, 0, 0, 0.8)',
    lineHeight: '1.75rem',
  },
  investmentDateSubtitle: {
    fontWeight: 400,
    fontSize: '0.81rem',
    color: '#788CA5',
    lineHeight: '1.75rem',
  },
};
