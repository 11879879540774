import axios from 'axios';
import { useMutation } from 'react-query';
import { store } from 'store/store';
import swal from 'sweetalert';
import { loginConfirmedAction, logout } from '../store/actions/AuthActions';
import { API } from './Api';
import { TirnuClient } from './Axios';

export function signUp(...params) {
  const parameters_final = params[0];

  //axios call
  let postData = {
    email: parameters_final[4],
    password: parameters_final[5],
    accountType: parameters_final[0],
  };
  if (parameters_final[0] === 'personal') {
    postData = {
      email: parameters_final[4],
      password: parameters_final[5],
      accountType: parameters_final[0],
      // returnSecureToken: true,
    };
  } else if (parameters_final[0] === 'business') {
    postData = {
      company_name: parameters_final[4],
      company_registration_number: parameters_final[5],
      email: parameters_final[6],
      password: parameters_final[7],
      accountType: parameters_final[0],
    };
  }

  return axios.post(
    `${process.env.REACT_APP_BACKEND_API}/api/v1/user/signup`,
    postData,
    {
      withCredentials: true,
    }
  );
}

export function adminLogin(email, password) {
  const postData = {
    email,
    password,
    // returnSecureToken: true,
  };

  return axios.post(
    `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/login`,
    postData
  );
}
export function login(email, password) {
  const postData = {
    email,
    password,
    // returnSecureToken: true,
  };

  return axios.post(
    `${process.env.REACT_APP_BACKEND_API}/api/v1/user/session`,
    postData,
    {
      withCredentials: true,
      credentials: 'include', // Don't forget to specify this if you need cookies
    }
  );
}
export function verifyLoginOtp(otp, email) {
  const postData = {
    email,
    otp,
  };

  return axios.post(
    `${process.env.REACT_APP_BACKEND_API}/api/v1/admin/verify/otp`,
    postData,
    {
      withCredentials: true,
      credentials: 'include', // Don't forget to specify this if you need cookies
    }
  );
}
export function formatError(errorResponse) {
  switch (errorResponse) {
    case 'EMAIL_EXISTS':
      //return 'Email already exists';
      swal('Oops', 'Email already exists', 'error');
      break;
    case 'EMAIL_NOT_FOUND':
      //return 'Email not found';
      swal('Oops', 'Email not found', 'error', { button: 'Try Again!' });
      break;
    case 'INVALID_PASSWORD':
      //return 'Invalid Password';
      swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  // tokenDetails.expireDate = new Date(
  //   new Date().getTime() + tokenDetails.expiresIn * 1000
  // )
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

function getTokenDetails(token) {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem('userDetails');
  let tokenDetails = '';
  if (!tokenDetailsString) {
    return { logout: true, isExpired: false };
  }

  try {
    tokenDetails = JSON.parse(tokenDetailsString);
    const tokenData = getTokenDetails(tokenDetails.accessToken);

    let expireDate = new Date(tokenData?.exp * 1000);
    let todaysDate = new Date();

    if (todaysDate.getTime() > expireDate.getTime()) {
      return { logout: false, isExpired: true };
    }
    dispatch(loginConfirmedAction(tokenDetails));
    return { logout: false, isExpired: false };
  } catch (error) {
    return { logout: true, isExpired: false };
  }
}

export const refreshUserToken = (refreshToken) => {
  return TirnuClient.post(API.v1.token.regen, {
    refreshToken: refreshToken,
  });
};
const useTokenRegenMutation = () => {
  return useMutation(API.v1.token.regen, refreshUserToken, {
    onSuccess: (response) => {
      saveTokenInLocalStorage(response.data);
      store.dispatch(loginConfirmedAction(response.data));
    },
  });
};

export { useTokenRegenMutation };
