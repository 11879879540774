import {
  ACCOUNT_CHANGE_ACTION,
  IS_LOADING_ACTION,
  SET_TABLE_DATA_ACTION,
  SET_FILE_DATA_ACTION,
  SET_API_ERRORS_ACTION,
} from 'store/actions/SendMoneyInBulkActions/SendMoneyInBulkActionsTypes';

const initialState = {
  accountData: null,
  isLoading: false,
  tableData: [],
  file: null,
  API_Errors: null,
};

const sendMoneyInBulkReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_CHANGE_ACTION:
      return { ...state, accountData: action.payload };

    case IS_LOADING_ACTION:
      return { ...state, isLoading: action.payload };

    case SET_TABLE_DATA_ACTION:
      return { ...state, tableData: action.payload };

    case SET_FILE_DATA_ACTION:
      return { ...state, file: action.payload };

    case SET_API_ERRORS_ACTION:
      return { ...state, API_Errors: action.payload };

    default:
      return state;
  }
};

export default sendMoneyInBulkReducer;
