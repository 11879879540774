export const textField = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: '0.5rem !important',
        background: '#ffffff',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        maxWidth: '27.625rem',
        zIndex: 0,
        '&:not(.MuiInputBase-multiline)': {
          height: '3rem',
        },
        '&.MuiInputBase-multiline': {
          padding: 0,
          '&>textarea:first-of-type': {
            padding: '16.5px 14px',
          },
        },
        '& [placeholder]::placeholder': {
          color: '#a0aec0',
          fontSize: '0.875rem',
          fontWeight: 400,
          opacity: 1,
        },
        '& .MuiInputBase-input': {
          height: '3rem',
          boxSizing: 'border-box',
          borderRadius: '0.5rem',
          maxWidth: '27.625rem',
          zIndex: 0,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: 'none',
        },
        notchedOutline: {
          border: 'none',
        },
        fieldset: {
          border: 'none',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        position: 'relative',
        '& .MuiFormHelperText-root.Mui-error': {
          margin: '0',
          color: '#E53E3E',
          fontSize: '0.875rem',
        },
      },
    },
  },
};
