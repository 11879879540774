import Button from 'jsx/components/UI/Button';
import { useActiveLedgerDetail } from 'jsx/hooks/ledger';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSwitchLedgerMutation } from 'services/Ledger';
import styled from 'styled-components';

const modalBackDropColor = 'rgba(0, 145, 147, 0.5)';

const SwitchModalBody = styled(ModalBody)`
  text-align: center;
`;

const SwitchModalTitle = styled(ModalTitle)`
  margin-bottom: 1.5rem;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function SwitchLedger({
  isModalOpen,
  handleModalClose,
  switchLedger,
  accountName,
  isSwitchingLedger,
}) {
  return (
    <Modal
      show={isModalOpen}
      centered
      size="lg"
      style={{
        backgroundColor: modalBackDropColor,
      }}
    >
      <SwitchModalBody>
        <SwitchModalTitle as="h2">
          Do you want to switch to {accountName || 'this'} account?
        </SwitchModalTitle>
        <SwitchContainer>
          <Button
            className="py-2 mr-4 d-flex align-items-center"
            size="lg"
            arial-label="Confirm"
            onClick={switchLedger}
            isLoading={isSwitchingLedger}
            loadingText="Switching Account ..."
          >
            Confirm
          </Button>
          <Button
            className="py-2"
            size="lg"
            variant="outline-primary"
            arial-label="Cancel"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </SwitchContainer>
      </SwitchModalBody>
    </Modal>
  );
}

export default SwitchLedger;

export const useSwitchLedger = ({ onAccountClick } = {}) => {
  const history = useHistory();
  const { mutateAsync: switchUserLedger, isLoading: isSwitchingLedger } =
    useSwitchLedgerMutation();
  const { fetchUserLedgerDetail } = useActiveLedgerDetail();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchLedgerName, setSwitchLedgerName] = useState('');
  const [switchLedgerId, setSwitchLedgerId] = useState(null);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const openSwitchLedgerModal = (ledger) => () => {
    if (!ledger.is_active) {
      setSwitchLedgerId(ledger.ledger_id);
      setSwitchLedgerName(ledger.ledger_meta?.association ?? '');
      setIsModalOpen(true);
    }
  };

  const onLedgerSwitch = async () => {
    if (switchLedgerId) {
      try {
        const { data: switchedLedger } = await switchUserLedger({
          ledgerId: switchLedgerId,
        });
        fetchUserLedgerDetail(switchedLedger?.data);
        setIsModalOpen(false);
        onAccountClick?.();
        history.push('/');
      } catch (error) {
        console.log(error);
      }
    }
  };

  return {
    isModalOpen,
    switchLedgerName,
    isSwitchingLedger,
    handleModalClose,
    openSwitchLedgerModal,
    onLedgerSwitch,
  };
};
