import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

function StatusModal({ open, handleClose, illustration, title, children }) {
  return (
    <Dialog
      // onClose={handleClose}
      open={open}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: 560,
          borderRadius: 4,
          p: 2,
          boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
        },
      }}
      componentsProps={{
        backdrop: {
          style: {
            background:
              'linear-gradient(95.89deg, rgba(71, 172, 152, 0.4) -13.16%, rgba(136, 218, 204, 0.4) 115.4%)',
          },
        },
      }}
    >
      {illustration}
      <DialogTitle
        fontSize={30}
        fontWeight="bold"
        textAlign="center"
        sx={{ p: 1 }}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default StatusModal;
