import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import emailVerifBg from 'images/register/emailVerifBg.png';

export const RightContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '40%',
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    maxWidth: '54rem',
    marginLeft: 'auto',
  },
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
  },
}));

export const ResendCode = styled(Box)(({ theme }) => ({
  color: theme.palette.green500.main,
  marginLeft: '0.313rem',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
}));

export const VerifyBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  [theme.breakpoints.down('tabletS')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const LeftContainerWrapper = styled(Box)(({ theme }) => ({
  width: '60%',
  backgroundImage: `url(${emailVerifBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  backgroundSize: 'contain',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
  },
}));

export const TopSvgContainer = styled(Box)(({ theme }) => {
  const configs = {
    position: 'absolute',
    top: 0,
    left: '20%',
    transform: 'translateX(-20%)',
  };

  return {
    [theme.breakpoints.up('xxl')]: {
      ...configs,
      left: '30%',
      transform: configs.transform + ' scale(1.75)',
    },
    [theme.breakpoints.between('xl', 'xxl')]: {
      ...configs,
      transform: configs.transform + ' scale(1.20)',
    },
    [theme.breakpoints.between('l', 'xl')]: {
      ...configs,
      top: '-1%',
      transform: configs.transform + ' scale(0.95)',
    },
    [theme.breakpoints.between('laptop', 'l')]: {
      ...configs,
      top: '-5%',
      left: '10%',
      transform: configs.transform + ' scale(0.7)',
    },
    [theme.breakpoints.down('laptop')]: {
      ...configs,
      transform: configs.transform + ' scale(0.6)',
    },
  };
});

export const CenterCircleSvgContainer = styled(Box)(({ theme }) => {
  const configs = {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -60%)',
    verticalAlign: 'bottom',
    '& span': {
      fontSize: '2rem',
      lineHeight: 1.5,
    },
  };

  return {
    [theme.breakpoints.up('xxl')]: {
      ...configs,
      transform: configs.transform + ' scale(1.75)',
    },
    [theme.breakpoints.between('xl', 'xxl')]: {
      ...configs,
      transform: configs.transform + ' scale(1.35)',
    },
    [theme.breakpoints.between('l', 'xl')]: {
      ...configs,
    },
    [theme.breakpoints.between('laptop', 'l')]: {
      ...configs,
      transform: configs.transform + ' scale(0.6)',
    },
    [theme.breakpoints.down('laptop')]: {
      ...configs,
      transform: configs.transform + ' scale(0.6)',
    },
  };
});

export const CenterSvgContainer = styled(Box)(({ theme }) => {
  const configs = {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -60%)',
    verticalAlign: 'bottom',
    zIndex: 1,
    '& span': {
      fontSize: '2rem',
      lineHeight: 1.5,
    },
  };

  return {
    [theme.breakpoints.up('xxl')]: {
      ...configs,
      transform: configs.transform + ' scale(1.75)',
    },
    [theme.breakpoints.between('xl', 'xxl')]: {
      ...configs,
      transform: configs.transform + ' scale(1.35)',
    },
    [theme.breakpoints.between('l', 'xl')]: {
      ...configs,
    },
    [theme.breakpoints.between('laptop', 'l')]: {
      ...configs,
      transform: configs.transform + ' scale(0.6)',
    },
    [theme.breakpoints.down('laptop')]: {
      ...configs,
      transform: configs.transform + ' scale(0.6)',
    },
  };
});

export const BottomSvgContainer = styled(Box)(({ theme }) => {
  const configs = {
    position: 'absolute',
    bottom: 0,
    right: '15%',
    transform: 'translateX(20%)',
    verticalAlign: 'bottom',
  };

  return {
    [theme.breakpoints.up('xxl')]: {
      ...configs,
      bottom: '5.75rem',
      right: 0,
      transform: 'scale(1.75)',
    },
    [theme.breakpoints.between('xl', 'xxl')]: {
      ...configs,
      bottom: '1.5rem',
      right: 0,
      transform: configs.transform + ' scale(1.20)',
    },
    [theme.breakpoints.between('l', 'xl')]: {
      ...configs,
      right: 0,
      bottom: '-0.25rem',
      transform: configs.transform + ' scale(0.95)',
    },
    [theme.breakpoints.between('laptop', 'l')]: {
      ...configs,
      right: 0,
      bottom: '-2.25rem',
      transform: configs.transform + ' scale(0.7)',
    },
    [theme.breakpoints.down('laptop')]: {
      ...configs,
      bottom: '-4%',
      transform: configs.transform + ' scale(0.6)',
    },
  };
});
