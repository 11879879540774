import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
//import rootReducers from './reducers/Index';
import todoReducers from './reducers/Reducers';
import accountTypeReducer from './reducers/accountTypeReducer';
import sendMoneyTypeReducer from './reducers/sendMoneyTypeReducer';
import sendMoneyInBulkReducer from './reducers/sendMoneyInBulkReducer';
import sendMoneyRecipientReducer from './reducers/sendMoneyRecipientReducer';
import sendMoneyMyContactReducer from './reducers/sendMoneyMyContactReducer';
import sendBetweenAccReducer from './reducers/sendBetweenAccReducer';
import selectInvestmentValuesReducer from './reducers/selectInvestmentValuesReducer';
import modalsReducer from './reducers/modalsReducer';
import cardsReducer from './reducers/cardsReducer';
import { reducer as reduxFormReducer } from 'redux-form';
import sendMoneyInternationalReducer from './reducers/sendMoneyInternationalReducer';
import sendMoneyDestinationReducer from './reducers/sendMoneyDestinationReducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  accountTypeReducer,
  sendMoneyTypeReducer,
  sendMoneyDestinationReducer,
  form: reduxFormReducer,
  sendMoneyInBulkReducer,
  sendMoneyRecipientReducer,
  sendBetweenAccReducer,
  sendMoneyMyContactReducer,
  sendMoneyInternationalReducer,
  modalsReducer,
  cardsReducer,
  selectInvestmentValuesReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
