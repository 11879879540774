import {
  TransferFromItem,
  RecipientsNameItem,
  RECIPIENTS_NAME_TYPE,
  TRANSFER_FROM_ITEM_TYPE,
  CATEGORY_NAME_TYPE,
  TRANSFER_COUNTRY_TYPE,
} from './index';

import MenuItem from '@mui/material/MenuItem';
import { CategoryItem } from './Items/CategoryItem';
import { TransferCountry } from './Items/TransferCountry';

export const renderItem = ({ options, itemType = TRANSFER_FROM_ITEM_TYPE }) => {
  const handleRenderItem = (option, itemType, index) => {
    switch (itemType) {
      case TRANSFER_FROM_ITEM_TYPE:
        return (
          <MenuItem key={index} value={option.value}>
            <TransferFromItem option={option} />
          </MenuItem>
        );

      case RECIPIENTS_NAME_TYPE:
        return (
          <MenuItem key={option.iban} value={option.iban}>
            <RecipientsNameItem option={option} />
          </MenuItem>
        );

      case CATEGORY_NAME_TYPE:
        return (
          <MenuItem key={index} value={option.value}>
            <CategoryItem option={option} />
          </MenuItem>
        );

      case TRANSFER_COUNTRY_TYPE:
        return (
          <MenuItem key={index} value={option.value}>
            <TransferCountry option={option} />
          </MenuItem>
        );

      default:
        break;
    }
  };

  return options?.map((option, index) =>
    handleRenderItem(option, itemType, index)
  );
};
